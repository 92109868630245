import {createRef, RefObject, useContext, useEffect} from 'react';
import {Grid} from 'semantic-ui-react';
import {CcpWrapperContext} from '../contexts/ccpWrapperContext';
import {ContactContext} from '../contexts/contactContext';
import {BuildLabel} from './BuildLabel';

const ccpRef: RefObject<HTMLDivElement> = createRef();

interface ICcpProps {
  collapsed?: boolean;
}

export const Ccp = (props: ICcpProps) => {
  const {bannerError} = useContext(CcpWrapperContext);
  const {initialize, terminate} = useContext(ContactContext);
  useEffect(() => {
    
    initialize(ccpRef.current);
    // Return statement is executed when component un-mounts
    return () => {
      console.debug('TERMINATING');
      terminate();
    };
  }, []);

  const alert = () => {
    if (bannerError) {
      return <div className="error">{bannerError}</div>;
    } else {
      return null;
    }
  };
  const ccpDivClasses = props.collapsed ? 'ccp-frame collapsed' : 'ccp-frame';

  return (
    <Grid.Row>
      <div title='CCP Wrapper IFrame' className={ccpDivClasses} ref={ccpRef} />
      <BuildLabel />
      {alert()}
    </Grid.Row>
  );
};
