import React, {useState} from 'react';
import {Comment} from 'semantic-ui-react';
import {TranslationHelper} from '../utils/TranslationHelper';

/**
 * Comment that displays
 * @param props
 * @returns
 */
export const TranslationPaneComment = (props: any) => {
  const [showTranslated, setShowTranslated] = useState(true);

  return (
    <div
      style={{
        margin:
          props.item.ParticipantRole === 'CUSTOMER'
            ? props.size === 'large'
              ? '20px 50% 20px auto'
              : '20px 30% 10px auto'
            : '20px auto 20px 50%',
        backgroundColor: props.item.ParticipantRole === 'CUSTOMER' ? '#cbe0ff' : '#0161a9',
        borderRadius: 20,
        padding: 10
      }}
    >
      <Comment>
        <Comment.Content>
          <Comment.Author style={{color: props.item.ParticipantRole === 'CUSTOMER' ? 'black' : 'white'}} as="a">
            {props.item.DisplayName}
          </Comment.Author>
          <Comment.Metadata style={{}}>
            <div>
              {' '}
              {new Date(props.item.AbsoluteTime).toLocaleTimeString([], {
                timeStyle: 'short'
              })}
            </div>
            <div>
              {props.item.SourceLanguage &&
                `Translated from ${TranslationHelper.getAWSLanguageName(props.item.SourceLanguage)}`}
            </div>
          </Comment.Metadata>
          <Comment.Text style={{color: props.item.ParticipantRole === 'CUSTOMER' ? 'black' : 'white'}}>
            {props.item.TranslatedMessage && showTranslated ? props.item.TranslatedMessage : props.item.Content}
          </Comment.Text>
          <Comment.Actions>
            {props.item.ParticipantRole === 'CUSTOMER' && props.item.TranslatedMessage && (
              <Comment.Action onClick={() => setShowTranslated(!showTranslated)}>
                {showTranslated ? 'Show Original Text' : 'Show Translation'}
              </Comment.Action>
            )}
          </Comment.Actions>
        </Comment.Content>
      </Comment>
    </div>
  );
};
