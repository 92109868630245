import {useState, useEffect} from 'react';

const SESSION_STORAGE_KEY = 'contactMessages';

interface ContactSession {
  messages: Message[];
  customerLanguage?: string;
  agentLanguage?: string;
}

interface ContactSessionStore {
  [contactId: string]: ContactSession;
}

interface Message {
  [key: string]: any;
}

let globalContactSessionStore: ContactSessionStore =
  JSON.parse(window.sessionStorage.getItem(SESSION_STORAGE_KEY)) || {};
let listeners: any[] = [];

const useContactMessages: () => [
  ContactSessionStore,
  (contactId: string, agentLang?: string) => void,
  (messageObject: Message, contactId:string) => void,
  (contactId: string, customerLanguage: string) => void,
  (contactId: string) => void,
  (contactId:string,agentLanguage:string)=>void
] = () => {
  const setGlobalConfig = useState({})[1];


  /**
   * Creates a session in the Contact Session Storage
   * @param contactId
   * @param agentLanguage
   */
  const createSession = (contactId: string, agentLanguage?: string) => {
    globalContactSessionStore[contactId] = {
      agentLanguage: agentLanguage,
      messages: []
    };
    for (const listener of listeners) {
      listener(globalContactSessionStore);
    }
  };


  /**
   * Remove the contact from the Contact Session Store
   * @param contactId
   */
  const deleteSession = (contactId: string) => {
    let newContact = {...globalContactSessionStore};
    delete newContact[contactId];
    globalContactSessionStore = newContact;
    window.sessionStorage.setItem(SESSION_STORAGE_KEY, JSON.stringify(globalContactSessionStore));
    for (const listener of listeners) {
      listener(globalContactSessionStore);
    }
  };

  /**
   * Push message into Contact Session Storage
   * @param messageObject
   */
  const pushMessage = (messageObject: Message, contactId:string) => {
    if (!globalContactSessionStore[contactId].messages) {
      globalContactSessionStore[contactId].messages = [];
    }
    let newContact: ContactSessionStore = {
      ...globalContactSessionStore,
      ...{
        [contactId]: {
          ...globalContactSessionStore[contactId],
          messages: [...globalContactSessionStore[contactId].messages, messageObject]
        }
      }
    };
    globalContactSessionStore = newContact;
    window.sessionStorage.setItem(SESSION_STORAGE_KEY, JSON.stringify(globalContactSessionStore));
    for (const listener of listeners) {
      listener(globalContactSessionStore);
    }
  };

  const setCustomerLanguage = (contactId: string, customerLanguage: string) => {
    let newContact: ContactSessionStore = {
      ...globalContactSessionStore,
      ...{
        [contactId]: {
          ...globalContactSessionStore[contactId],
          customerLanguage: customerLanguage
        }
      }
    };
    globalContactSessionStore = newContact;
    console.log(globalContactSessionStore)
    window.sessionStorage.setItem(SESSION_STORAGE_KEY, JSON.stringify(globalContactSessionStore));
    for (const listener of listeners) {
      listener(globalContactSessionStore);
    }
  };

  const setAgentLanguage = (contactId: string, agentLanguage: string) => {
    let newContact: ContactSessionStore = {
      ...globalContactSessionStore,
      ...{
        [contactId]: {
          ...globalContactSessionStore[contactId],
          agentLanguage: agentLanguage
        }
      }
    };
    globalContactSessionStore = newContact;
    window.sessionStorage.setItem(SESSION_STORAGE_KEY, JSON.stringify(globalContactSessionStore));
    for (const listener of listeners) {
      listener(globalContactSessionStore);
    }
  };

  useEffect(() => {
    listeners.push(setGlobalConfig);
    return () => {
      listeners = listeners.filter((li) => li !== setGlobalConfig);
    };
  }, [setGlobalConfig]);

  return [globalContactSessionStore, createSession, pushMessage, setCustomerLanguage, deleteSession, setAgentLanguage];
};

export default useContactMessages;
