import React, {useContext, useEffect, useState} from 'react';
import {Button, ButtonProps, Input, List, ListItem, Tab} from 'semantic-ui-react';
import {ContactContext} from '../../contexts/contactContext';
import { RestApiContext } from '../../contexts/restApiContext';

export const SelfAssignTask = () => {
  
  const {submitSelfAssignTask} = useContext(RestApiContext);
  const [taskName, setTaskName] = useState('');
  const [taskDesc, setTaskDesc] = useState('');
  const [submitting, setSubmitting] = useState(false);
  const {agentUserName, selectedContact} = useContext(ContactContext);

  useEffect(() => {
    // console.log('agentUserName: ', agentUserName);
  });

  const handleNameInputChange = (event: React.ChangeEvent<{}>, newValue: any) => {
    setTaskName(newValue.value);
  };

  const handleDescInputChange = (event: React.ChangeEvent<{}>, newValue: any) => {
    setTaskDesc(newValue.value);
  };

  const submitTask = async () => {
    setSubmitting(true);
    try {
      await submitSelfAssignTask(agentUserName, taskName, taskDesc)
    }
    catch (e: any) {
      console.log(e.message);
    }
    setTaskName('');
    setTaskDesc('');
    setSubmitting(false)
  }

  return (
    <Tab.Pane attached={false}>
      <List>
        <ListItem>
          <List.Header>
            <strong>Self Assign Task</strong>
          </List.Header>
          <List.Description>
            <p>Assign yourself a Task contact to self report your work</p>
          </List.Description>
        </ListItem>
        <ListItem>
          <List.Header>
            <strong>Task Name</strong>
          </List.Header>
          <List.Description>
            <Input
              placeholder="Enter the Task name"
              style={{width: '100%'}}
              value={taskName}
              name="selfTaskName"
              onChange={handleNameInputChange}
            />
          </List.Description>
        </ListItem>
        <ListItem>
          <List.Header>
            <strong>Task Description</strong>
          </List.Header>
          <List.Description>
            <Input
              fluid
              placeholder="Enter the Task description"
              style={{width: '100%'}}
              value={taskDesc}
              name="selfTaskDesc"
              onChange={handleDescInputChange}
            />
          </List.Description>
        </ListItem>
        <ListItem>
          <Button disabled={!!selectedContact || submitting} content={'Submit Task'} onClick={() => submitTask()} />
        </ListItem>
      </List>
    </Tab.Pane>
  );
};
