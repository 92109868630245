import {useContext} from 'react';
import {Card, CardContent, Grid, Tab} from 'semantic-ui-react';
import {AppContext} from '../contexts/appContext';
import {CcpWrapperContext} from '../contexts/ccpWrapperContext';
import {ContactContext} from '../contexts/contactContext';
import {SideMenuPane} from './SideMenuPane';

export const SideMenu = () => {
  const {config} = useContext(AppContext);
  const {agentLang} = useContext(ContactContext);
  const {tabTitle, setTabTitle, sideMenuActiveIndex, setSideMenuActiveIndex} = useContext(CcpWrapperContext);

  const menuConfig = {
    color: 'blue',
    inverted: true,
    attached: false,
    tabular: false
  };

  const handleIndexChange = (event: React.ChangeEvent<{}>, newValue: any) => {
    setTabTitle(config.sideMenuItems[newValue.activeIndex].name);
    setSideMenuActiveIndex(newValue.activeIndex);
  };

  const sideMenuPanes = config.sideMenuItems.map((item) => SideMenuPane(item, config, agentLang));

  return (
    <Grid.Row only="computer tablet" className={config.isV2() ? 'ccp-wrapper v2' : 'ccp-wrapper'}>
      <Card id="tabsCardGeneric" className={config.isV2() ? 'ccp-wrapper v2' : 'ccp-wrapper'}>
        <CardContent>
          <Grid.Column className="tabsTitle" width="6">
            {config.translate(tabTitle, agentLang) || tabTitle}
          </Grid.Column>
          <Grid.Column width="6">
            <Tab
              id="headerTabsGeneric"
              activeIndex={sideMenuActiveIndex}
              menu={menuConfig}
              panes={sideMenuPanes}
              onTabChange={handleIndexChange}
            ></Tab>
          </Grid.Column>
        </CardContent>
      </Card>
    </Grid.Row>
  );
};
