import React, {useContext, useEffect, useState} from 'react';
import {Grid} from 'semantic-ui-react';
import {AppContext} from '../contexts/appContext';
import {ContactContext} from '../contexts/contactContext';
import {Ccp} from './Ccp';
import {SideMenu} from './SideMenu';
import {ContactInfo} from './ContactInfo/ContactInfo';
import {CcpWrapperContext} from '../contexts/ccpWrapperContext';
import {Logger, LoggingService} from '../services/LoggingService';
import {ErrorContactInfo} from './ContactInfo/ErrorContactInfo';
import {ErrorBoundary} from 'react-error-boundary';
import {SafeDisclaimerPanel} from './DisclaimerPanel/SafeDisclaimerPanel';
import {SafeCallLoggerPanel} from './CallLoggerPanel/SafeCallLoggerPanel';
import {SafeDispositionPanel} from './DispositionPanel/SafeDispositionPanel';
import {TranslationPane} from './TranslationPane/TranslationPane';
import {TranslationPaneError} from './TranslationPane/TranslationPaneError';

export function CcpWrapper() {
  const {config} = useContext(AppContext);
  const {selectedContact, initialized, agent} = useContext(ContactContext);
  const [displayTranslationPane, setDisplayTranslationPane] = useState(false);
  const {dispositionPaneVisible} = useContext(CcpWrapperContext);

  const logger: Logger = new LoggingService().getLogger('CCPWrapper');

  const safeGetAttributes = (contact: connect.Contact) => {
    try {
      return contact.getAttributes();
    } catch (error) {
      logger.debug('Attempting to read attributes after contact end');
      return {};
    }
  };

  if (selectedContact) {
    logger.debug('Contact attributes', safeGetAttributes(selectedContact));
  }

  const channels = agent?.getChannelConcurrency();
  useEffect(() => {
    const hasChat = channels && channels[connect.ChannelType.CHAT] > 0;
    setDisplayTranslationPane(hasChat);
  }, [channels]);

  const contactHasValidService = (): boolean => {
    if (selectedContact) {
      const attributes = safeGetAttributes(selectedContact);
      if (attributes?.service?.value) {
        const service = attributes.service.value;
        const serviceConfig = config.getServiceConfig(service);
        if (serviceConfig && serviceConfig.contactInfoDisplaySet) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  const contactHasBrandCode = () => {
    if (selectedContact) {
      const attributes = safeGetAttributes(selectedContact);
      if (attributes?.brandCode?.value) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  const displayInfoAndDisposition = () => {
    return initialized && selectedContact && contactHasValidService() && contactHasBrandCode();
  };

  const renderDisposition = () => {
    return dispositionPaneVisible /*&& contactInProgress()*/;
  };

  const contactInfoErrorHandler = (error: Error, info: {componentStack: string}) => {
    connect
      .getLog()
      .error('Contact Info pane error caught by error boundary', selectedContact?.contactId)
      .withException(error);
    logger.error('Contact Info pane error caught by error boundary', {error});
  };

  return (
    <>
      <Grid>
        <Grid.Row>
          <Grid.Column id="ccpFrame" className={config?.isV2() ? 'ccp-wrapper v2' : 'ccp-wrapper'}>
            <Grid style={{paddingLeft: '10px'}}>
              <Ccp />
              <SideMenu />
            </Grid>
          </Grid.Column>

          {displayTranslationPane && (
            <Grid.Column
              style={{resize: 'both', overflow: 'hidden', height: '90vh', width: '50vw', maxHeight: '100vh'}}
              stretched
            >
              <ErrorBoundary FallbackComponent={TranslationPaneError}>
                <TranslationPane contact={selectedContact} />
              </ErrorBoundary>
            </Grid.Column>
          )}
          {displayInfoAndDisposition() && (
            <Grid.Column className={config?.isV2() ? 'infoColumn v2' : 'infoColumn'}>
              <Grid.Row>
                <SafeDisclaimerPanel />
                <ErrorBoundary FallbackComponent={ErrorContactInfo} onError={contactInfoErrorHandler}>
                  <ContactInfo />
                </ErrorBoundary>
                <SafeCallLoggerPanel />
                {renderDisposition() && (
                  <Grid.Row className="dispositionRow">
                    <SafeDispositionPanel></SafeDispositionPanel>
                  </Grid.Row>
                )}
                <br />
              </Grid.Row>
            </Grid.Column>
          )}
        </Grid.Row>
      </Grid>
    </>
  );
}
