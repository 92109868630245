import React, {Fragment} from 'react';
import {Icon, List, ListItem, Popup} from 'semantic-ui-react';
import {Logger, LoggingService} from '../../services/LoggingService';
import {ILinkAttributeConfig} from '../../models/contactInfoDisplaySets';
import {ConfigurationService} from '../../services/ConfigurationService';

export const LinkAttribute = (
  attributeConfig: ILinkAttributeConfig,
  attributeValue: string,
  config: ConfigurationService,
  agentLang: string
) => {
  const logger: Logger = new LoggingService().getLogger('LinkAttribute');
  if (!attributeValue && attributeConfig.hideIfFalsy === true) {
    logger.debug('No attributeValue property on attributeConfig, hiding attribute', {...attributeConfig});
    return null;
  }

  if (!attributeConfig.label) {
    logger.error('No label property on attributeConfig, skipping attribute', {...attributeConfig});
    return null;
  }

  const clickToCall = (phoneNumber: string) => {
    const endpoint = connect.Endpoint.byPhoneNumber(phoneNumber);
    connect.agent((agent: connect.Agent) => {
      agent.connect(endpoint, {
        success: () => {},
        failure: () => {}
      });
    });
  };

  const copyToClipboard = (content: string): void => {
    navigator.clipboard.writeText(content).then(
      function () {
        /* clipboard successfully set */
      },
      function () {
        /* clipboard write failed */
      }
    );
  };

  const getAttributeValue = (): React.LinkHTMLAttributes<HTMLAnchorElement> | string => {
    if (!attributeValue && attributeConfig.fallbackValue) {
      return attributeConfig.fallbackValue;
    } else if (!attributeValue) {
      return config.translate('Not Provided', agentLang) || 'Not Provided';
    }

    if (attributeConfig.displayType === 'phoneNumber') {
      return (
        <a href="#" onClick={() => clickToCall(attributeValue)}>
          {attributeValue}
        </a>
      );
    } else if (attributeConfig.displayType === 'email') {
      const endpoint = `mailto:${attributeValue}`;
      const copyIconButton = <Icon link name="copy outline" onClick={() => copyToClipboard(attributeValue)}></Icon>;

      return (
        <Fragment>
          <a href={endpoint}>{attributeValue}</a>{' '}
          <Popup
            size="tiny"
            content={config.translate('Copy Address', agentLang) || 'Copy Address'}
            trigger={copyIconButton}
          />
        </Fragment>
      );
    } else if (attributeConfig.displayType === 'web') {
      return (
        <a href={attributeValue} target="_blank" rel="noopener noreferrer">
          {config.translate('Link', agentLang) || 'Link'}
        </a>
      );
    }
  };

  return (
    <ListItem key={attributeConfig.attributeKey}>
      <List.Header>
        <strong>{config.translate(attributeConfig.label, agentLang) || attributeConfig.label}</strong>
      </List.Header>
      <List.Description>{getAttributeValue()}</List.Description>
    </ListItem>
  );
};
