import React, {Fragment} from 'react';
import {Card, Icon, List, ListItem, Header} from 'semantic-ui-react';

export const ErrorContactInfo = () => {
  return (
    <Fragment>
      <Card className="title" style={{marginBottom: '0px'}}>
        <Card.Content>
          <Header className="ean" as="h2">
            <Header.Content style={{width: '100%'}}>
              <img
                src="ean.png"
                style={{
                  maxHeight: '60px',
                  display: 'block',
                  margin: 'auto'
                }}
                alt="ean logo"
              />
            </Header.Content>
          </Header>
        </Card.Content>
      </Card>
      <Card className="card-jmb" style={{marginTop: '0px'}}>
        <Card.Content className="header ean">
          <Card.Header textAlign="left">
            <Icon name="info" />
            Contact Info (Error)
          </Card.Header>
        </Card.Content>
        <Card.Content>
          <List divided relaxed>
            <ListItem>
              <List.Header>
                <strong>Error</strong>
              </List.Header>
              <List.Description>Error displaying contact attributes</List.Description>
            </ListItem>
          </List>
        </Card.Content>
      </Card>
    </Fragment>
  );
};
