import React from 'react';
import {Accordion, AccordionTitleProps, Form, Menu} from 'semantic-ui-react';
import { ConfigurationService } from '../../services/ConfigurationService';

export const DispositionMultiSelectMenuItem = (
  index: number,
  label: string,
  option: string[],
  brandCode: string,
  selectedMultiDisposition: string[],
  setSelectedMultiDisposition: (value: string[]) => void,
  activeIndex: number,
  setActiveIndex: (value: number) => void,
  config: ConfigurationService,
  agentLang: string
) => {
  const multiSelectMenuItem = () => {
    const handleMultiSelectChange = (item: string) => {
      let disposition = [...selectedMultiDisposition];
      if (disposition.includes(item)) {
        disposition = disposition.filter((dis) => {
          return dis !== item;
        });
      } else {
        disposition.push(item);
      }
      setSelectedMultiDisposition([...disposition]);
    };

    const content = option.map((item) => {
      const key = `${label}.${item}`;
      return (
        <Form.Checkbox
          key={key}
          label={config.translate(item, agentLang) || item}
          type="checkbox"
          checked={selectedMultiDisposition.includes(item)}
          // checked={currentDisposition.includes(item)}
          onChange={() => handleMultiSelectChange(item)}
        />
      );
    });

    const updateIndex = (event: React.MouseEvent, data: AccordionTitleProps): void => {
      const newIndex = activeIndex === data.index ? -1 : (data.index as number);
      setActiveIndex(newIndex);
    };

    return (
      <Menu.Item key={label}>
        <Accordion.Title
          active={activeIndex === index}
          content={config.translate(label, agentLang) || label}
          index={index}
          onClick={updateIndex}
          className={brandCode}
        />
        <Accordion.Content active={activeIndex === index} content={content} />
      </Menu.Item>
    );
  };

  return multiSelectMenuItem();
};
