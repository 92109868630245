import React from 'react';
import {Button, Feed, Icon, Tab} from 'semantic-ui-react';

export const ErrorRecentContacts = () => {
  return (
    <Tab.Pane attached={false}>
      <Feed>
        <Feed.Event>
          <Feed.Label className="callIconBox">
            <Icon name="call" className="call-icon" />
          </Feed.Label>
          <Feed.Content>
            <Feed.Summary>Call History Error</Feed.Summary>
            <Feed.Meta>
              <Feed.Like>Error retrieving Call History</Feed.Like>
            </Feed.Meta>
          </Feed.Content>
          <Feed.Label>
            <Button circular style={{width: '30px', paddingLeft: '5px', paddingRight: '5px'}} className="ean">
              T
            </Button>
          </Feed.Label>
        </Feed.Event>
      </Feed>
    </Tab.Pane>
  );
};
