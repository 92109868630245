import { Header, Icon, Dropdown, Modal, Button } from 'semantic-ui-react';
import useLocalAgentSettings from '../hooks/useLocalAgentSettings';
import { TranslationHelper } from '../utils/TranslationHelper';

export const TranslationPaneSettings = (props: any) => {
  const [localAgentSettings, setAgentDefaultLanguage, setAgentLanguages] = useLocalAgentSettings();

  return (
    <Modal
      onClose={() => props.onClose()}
      onOpen={() => props.onOpen()}
      open={props.isOpen}
    >
      <Modal.Header>
        <Icon name="setting" /> Agent Settings
      </Modal.Header>
      <Modal.Content>
        <Modal.Description>
          <Header>Default Language</Header>
          <span>Select your preferred language.</span>
          <br />
          <Dropdown
            error={!localAgentSettings.languages.includes(localAgentSettings.defaultLanguage)}
            search
            onChange={(e, data) => {
              setAgentDefaultLanguage(data.value.toString());
            }}
            value={localAgentSettings.defaultLanguage}
            options={localAgentSettings.languages.map((item) => {
              return { key: item, value: item, text: TranslationHelper.getAWSLanguageName(item) };
            })}
            selection
            placeholder="Default Language"
          />
        </Modal.Description>
        <Header>Your Languages</Header>
        <span>
          Select all of your desired languages. If a customer's language matches yours the contact will not be
          translated.
        </span>
        <br />
        <Dropdown
          onAddItem={(e, data) => {
            setAgentLanguages([...localAgentSettings.languages, data.value.toString()]);
          }}
          onChange={(e, data) => {
            setAgentLanguages(data.value as string[]);
          }}
          value={localAgentSettings.languages}
          options={TranslationHelper.getDropdownLanguages()}
          search
          fluid
          multiple
          selection
          placeholder="Select Your Languages"
        />
      </Modal.Content>
      <Modal.Actions>
        <Button
          content="Done"
          labelPosition="right"
          icon="arrow right"
          onClick={() => props.onClose()}
          positive
        />
      </Modal.Actions>
    </Modal>
  )
}
