import React, { Fragment } from 'react';
import {Icon, List, ListItem, Popup} from 'semantic-ui-react';
import {Logger, LoggingService} from '../../services/LoggingService';
import {IStandardAttributeConfig} from '../../models/contactInfoDisplaySets';
import {ConfigurationService} from '../../services/ConfigurationService';

export const StandardAttribute = (
  attributeConfig: IStandardAttributeConfig,
  attributeValue: string,
  config: ConfigurationService,
  agentLang: string,
  contactAttributes: connect.AttributeDictionary,
  copyable?: boolean
) => {
  const logger: Logger = new LoggingService().getLogger('StandardAttribute');

  if (!attributeValue && attributeConfig.hideIfFalsy === true) {
    logger.debug('No attributeValue property on attributeConfig, hiding attribute', {...attributeConfig});
    return null;
  }

  if (!attributeConfig.label) {
    logger.error('No label property on attributeConfig, skipping attribute', {...attributeConfig});
    return null;
  }

  const append = (baseString: string, valueToAppend: string, separator: string): string => {
    return baseString + separator + valueToAppend;
  };

  const prepend = (baseString: string, valueToPrepend: string, separator: string): string => {
    return valueToPrepend + separator + baseString;
  };

  const appendPrependString = (baseString: string): string => {
    for (let condition of attributeConfig.appendPrepend) {
      if (condition.conditionType === 'or') {
        for (let value of condition.valuesToCheck) {
          if (contactAttributes[value.attribute] && contactAttributes[value.attribute].value === value.value) {
            if (condition.action === 'append') {
              baseString = append(baseString, condition.addString, condition.separator);
            } else if (condition.action === 'prepend') {
              baseString = prepend(baseString, condition.addString, condition.separator);
            }
            break;
          }
          continue;
        }
      } else if (condition.conditionType === 'and') {
        let andCheckFulfilled = true;
        for (let value of condition.valuesToCheck) {
          if (!contactAttributes[value.attribute]) {
            andCheckFulfilled = false;
            break;
          } else if (contactAttributes[value.attribute] && contactAttributes[value.attribute].value !== value.value) {
            andCheckFulfilled = false;
            break;
          }
          continue;
        }
        if (andCheckFulfilled === true) {
          if (condition.action === 'append') {
            baseString = append(baseString, condition.addString, condition.separator);
          } else if (condition.action === 'prepend') {
            baseString = prepend(baseString, condition.addString, condition.separator);
          }
        }
      }
    }

    return baseString;
  };

  const copyToClipboard = (content: string): void => {
    navigator.clipboard.writeText(content).then(
      function () {
        /* clipboard successfully set */
      },
      function () {
        /* clipboard write failed */
      }
    );
  };

  const getAttributeValue = (): any => {
    if (!attributeValue && attributeConfig.fallbackValue) {
      return attributeConfig.fallbackValue;
    } else if (!attributeValue) {
      return config.translate('Not Provided', agentLang) || 'Not Provided';
    }

    let attributeReturnValue: string;

    if (Object.keys(attributeConfig).includes('lookupValues') && attributeConfig.displayType === 'standardWithLookup') {
      // Attribute value needs to be looked up in lookupValues
      if (attributeConfig.lookupValues[attributeValue]) {
        // Lookup value exists, save value to variable
        attributeReturnValue = attributeConfig.lookupValues[attributeValue];
      } else if (attributeConfig.fallbackValue) {
        // Lookup value not found, return fallback value
        logger.debug('fallback value', attributeConfig.fallbackValue);
        return attributeConfig.fallbackValue;
      } else {
        // Lookup value not found, no fallback value, return 'Not Provided'
        logger.error('attributeValue parameter is not available in lookupValues', {...attributeConfig});
        return config.translate('Not Provided', agentLang) || 'Not Provided';
      }
    } else {
      // We just want to display the attributeValue value directly
      attributeReturnValue = attributeValue;
    }

    if (attributeConfig.appendPrepend && attributeConfig.appendPrepend.length) {
      attributeReturnValue = appendPrependString(attributeReturnValue);
    }

    if (copyable) {
      const copyIconButton = <Icon link name="copy" onClick={() => copyToClipboard(attributeValue)}></Icon>;
      return (
        <Fragment>
          {attributeValue}
          <Popup
            size="tiny"
            content={config.translate('Copy', agentLang) || 'Copy'}
            trigger={copyIconButton}
          />
        </Fragment>
      );
    }

    return attributeReturnValue;
  };

  return (
    <ListItem key={attributeConfig.attributeKey}>
      <List.Header>
        <strong>{config.translate(attributeConfig.label, agentLang) || attributeConfig.label}</strong>
      </List.Header>
      {/* <List.Description>{config.translate(getAttributeValue(), agentLang) || getAttributeValue()}</List.Description> */}
      <List.Description>{getAttributeValue()}</List.Description>
    </ListItem>
  );
};
