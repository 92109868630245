import React from 'react';
import { Container, Divider, Label, List, ListItem } from 'semantic-ui-react';
import { Logger, LoggingService } from '../../services/LoggingService';
import { IPrefixedAttributes } from '../../models/contactInfoDisplaySets';
import { ConfigurationService } from '../../services/ConfigurationService';
import { Case } from 'change-case-all';
import * as toNoCase from 'to-no-case';

type PrefixedAttributeMeta = {
  labels?: Record<string, string>;
  order?: string[];
  hidden?: string[];
}

export const PrefixedAttributes = (
  attributeConfig: IPrefixedAttributes,
  contactAttributes: connect.AttributeDictionary,
  config: ConfigurationService,
  agentLang: string
) => {
  const logger: Logger = new LoggingService().getLogger('PrefixedAttributes');
  const meta: PrefixedAttributeMeta = JSON.parse(contactAttributes["form_meta"].value || "{}") as PrefixedAttributeMeta as PrefixedAttributeMeta;

  const filtered_items = Object.values(contactAttributes)
    // Filter out attributes that don't start with the prefix
    .filter((attribute) => attribute.name.startsWith(attributeConfig.prefix))
    // Strip the prefix from the name
    .map((attribute) => {
      const name = attribute.name.replace(attributeConfig.prefix, ''); // Strip prefix;
      // Create a new object with the name property set to the stripped name
      return {
        ...attribute,
        name
      }
    })
    // Filter out hidden attributes
    .filter((attribute) => {
      if (attribute.name === 'meta') {
        return false;
      }
      // Check if the attribute is hidden
      if (meta.hidden?.includes(attribute.name)) {
        return false;
      }
      return true;
    });

  // Sort in-place
  filtered_items.sort((a, b) => {
    const aIndex = meta.order?.indexOf(a.name) ?? -1;
    const bIndex = meta.order?.indexOf(b.name) ?? -1;

    if (aIndex > -1 && bIndex > -1) {
      // Both are in the order array, sort by their index
      return aIndex - bIndex;
    } else if (aIndex > -1) {
      // Only a is in the order array, a comes first
      return -1;
    } else if (bIndex > -1) {
      // Only b is in the order array, b comes first
      return 1;
    }

    // Neither are in the order array, sort by name
    return a.name.localeCompare(b.name);
  });

  const items = filtered_items
    .map((attribute) => {
      if (attribute.name.endsWith("_URL")) {
        return null;
      }

      let key = Case.title(toNoCase(attribute.name.replace(/^_/, ''))); // Strip leading _ if present, then convert to title case
      if (meta.labels?.hasOwnProperty(attribute.name)) {
        // Use the label set in the meta
        key = meta.labels[attribute.name];
      }

      const value = attribute.value;

      const url = filtered_items.find((item) => item.name === `${attribute.name}_URL`);

      return <>
        <ListItem key={`${attributeConfig.attributeKey}-${key}`}>
          <List.Header>
            <strong>{config.translate(key, agentLang) || key}</strong>
          </List.Header>
          <List.Description>
            {url ? <a href={url.value} target="_blank">{value}</a> : value}
          </List.Description>
        </ListItem>
        <Divider fitted />
      </>
    })
    .filter((item) => item !== null);

  return (
    <ListItem key={`${attributeConfig.attributeKey}`}>
      <List.Header>
        <strong>{config.translate(attributeConfig.label, agentLang) || attributeConfig.label}</strong>
      </List.Header>
      <List.Description>
        {items.length > 0 ? <List relaxed>{items}</List> : <p>No Attributes Found</p>}
      </List.Description>
    </ListItem>);
};
