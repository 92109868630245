import React from 'react';
import {Header, Icon} from 'semantic-ui-react';

export const LogoWithVehicleIconHeader = (brandCode: string) => {
  const getBrandMaxHeight = () => {
    switch (brandCode) {
      case 'ean':
      case 'ford':
      case 'gus':
      case 'lincoln':
        return '60px';
      default:
        // all other brandCodes
        return '30px';
    }
  };

  return (
    <Header className={brandCode} as="h2">
      <Icon name="car" />
      <Header.Content style={{width: '100%'}}>
        <img src={`${brandCode}.png`} style={{maxHeight: getBrandMaxHeight()}} alt={`${brandCode} logo`} />
        <div style={{float: 'right'}}></div>
      </Header.Content>
    </Header>
  );
};
