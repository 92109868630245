import {createContext, useContext} from 'react';
import {APIGatewayProxyResult} from 'aws-lambda';
import {AppContext} from './appContext';
import {Logger, LoggingService} from '../services/LoggingService';
import {ApplicationError} from '../services/ApplicationErrorService';
import {IAttributes} from '../models/attributes';
import {IContactSnapshot} from '../models/contactSnapshot';

export interface IValidateGpbrResult {
  gpbr?: string;
  errorMessage?: string;
  isValid: boolean;
}

export interface ICallRecordResult {
  contactId: string;
  callLoggerEntries?: ICallLoggerEntries;
  dispositionAttributes?: IDispositionAttributes;
}

export interface ICallLogItem {
  gpbrId: string;
  reservationId?: string;

  /**
   * Metadata fields
   */
  _gpbrId_isValid?: boolean; // Whether or not the field meets the Regex requirements
  _gpbrId_hasMatch?: boolean; // Whether or not we were able to match the input with a valid GPBR
  _gpbrId_isValidating?: boolean; // set to true when waiting for a validation response
  _gpbrId_errorMessage?: string; // The error response to be displayed in a popup/tooltip
}

export interface ICallLoggerEntries {
  logItems: ICallLogItem[];
}

export interface IDispositionAttributes {
  [name: string]: string;
}

export interface IRestApiContext {
  updateContactAttributes: (contact: connect.Contact, attributes: IAttributes) => Promise<string>;
  updateContactAttributesFromSnapshot: (contact: IContactSnapshot, attributes: IAttributes) => Promise<string>;
  sendText: (contact: connect.Contact, toNumber: string) => Promise<string>;
  checkForLocation: (contact: connect.Contact) => Promise<any | void>;
  submitSelfAssignTask: (agentUsername: string, taskName: string, taskDesc: string) => Promise<string>;
  validateGpbr: (gpbr: string) => Promise<IValidateGpbrResult>;
  translateText: (text: string, targetLanguage: string, sourceLanguage?: string) => Promise<any>;
  getCallRecord: (contactId: string) => Promise<ICallRecordResult>;
  updateCallRecord: (
    contactId: string,
    callRecordEntries: ICallLoggerEntries,
    dispositionAttributes: IDispositionAttributes
  ) => Promise<any | void>;
}

export const RestApiContext = createContext<IRestApiContext>({} as IRestApiContext);

export interface QueryParameter {
  Name: string;
  Value: string;
}

export function RestApiContextProvider(props: {children: any}) {
  const {
    config: {api}
  } = useContext(AppContext);
  // const {agentUserName} = useContext(ContactContext);

  const logger: Logger = new LoggingService().getLogger('RestApiContextProvider');

  const get = async (url: string, queryParams?: QueryParameter[], token?: string): Promise<Response> => {
    if (!token) {
      throw new ApplicationError(401, 'CCP Token is missing');
    }

    const request: any = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': api.apiKey
      }
    };

    if (token) {
      request.headers.Authorization = `Bearer ${token}`;
    }

    let reqUrl = `${api.baseURL}/${url}`;

    if (queryParams && queryParams.length > 0) {
      reqUrl = `${reqUrl}?${queryParams[0].Name}=${queryParams[0].Value}`;
      for (let i = 1; i < queryParams.length; i++) {
        reqUrl = `${reqUrl}&${queryParams[i].Name}=${queryParams[i].Value}`;
      }
    }
    try {
      return await fetch(reqUrl, request);
    } catch (error) {
      if (error instanceof Error) {
        throw new ApplicationError(0, error.message);
      } else {
        throw new ApplicationError(0, 'error with fetch operation');
      }
    }
  };

  const post = async (url: string, body: object, token?: string): Promise<Response> => {
    if (!token) {
      throw new ApplicationError(401, 'CCP Token is missing');
    }

    const request: any = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': api.apiKey
      },
      body: JSON.stringify(body)
    };

    if (token) {
      request.headers.Authorization = `Bearer ${token}`;
    }

    try {
      return await fetch(`${api.baseURL}/${url}`, request);
    } catch (error) {
      if (error instanceof Error) {
        throw new ApplicationError(0, error.message);
      } else {
        throw new ApplicationError(0, 'error with fetch operation');
      }
    }
  };

  const updateContactAttributes = async (contact: connect.Contact, attributes: IAttributes): Promise<string> => {
    const token = contact.getAttributes().CCP_TOKEN.value;

    if (!token) {
      logger.error('CCP_TOKEN attribute is required to use API');
      return 'CCP_TOKEN attribute is required to use API';
    }

    const initialContactId = contact?.getOriginalContactId() || contact?.getContactId();

    const phoneNumber = contact.getInitialConnection().getEndpoint().phoneNumber
      ? contact.getInitialConnection().getEndpoint().phoneNumber
      : '';

    const contactType = contact.getType() ? contact.getType() : '';

    const body = {
      initialContactId: initialContactId,
      attributes,
      contactType,
      phoneNumber
    };

    try {
      const response = await post(api.resources.updateAttributes, body, token);

      if (response.status !== 200) {
        const error = await response.json();
        logger.error('updateContactAttributes error:', error);
        return error as string;
      } else {
        logger.debug(`updateContactAttributes success: ${response.status} ${response.statusText}`);
        return 'Success';
      }
    } catch (error: any) {
      logger.error('updateContactAttributes error:', error);
      return error as string;
    }
  };

  const updateContactAttributesFromSnapshot = async (
    contact: IContactSnapshot,
    attributes: IAttributes
  ): Promise<string> => {
    const token = contact.contactData.attributes.CCP_TOKEN.value;

    if (!token) {
      logger.error('CCP_TOKEN attribute is required to use API');
      return 'CCP_TOKEN attribute is required to use API';
    }

    const initialContactId = contact.contactData.initialContactId || contact.contactData.contactId;

    let initialConnection;
    for (const connection of contact.contactData.connections) {
      if (connection.initial) {
        initialConnection = connection;
      }
    }

    const phoneNumber = initialConnection?.endpoint.phoneNumber ? initialConnection?.endpoint.phoneNumber : '';

    const contactType = contact.contactData.type ? contact.contactData.type : '';

    const body = {
      initialContactId: initialContactId,
      attributes,
      contactType,
      phoneNumber
    };

    try {
      const response = await post(api.resources.updateAttributes, body, token);

      if (response.status !== 200) {
        const error = await response.json();
        logger.error('updateContactAttributes error:', error);
        return error as string;
      } else {
        logger.debug(`updateContactAttributes success: ${response.status} ${response.statusText}`);
        return 'Success';
      }
    } catch (error: any) {
      logger.error('updateContactAttributes error:', error);
      return error as string;
    }
  };

  const sendText = async (contact: connect.Contact, toNumber: string, type?: string): Promise<string> => {
    const token = contact.getAttributes().CCP_TOKEN.value;

    if (!token && type !== 'agentMetrics') {
      logger.error('CCP_TOKEN attribute is required to use API');
      return 'CCP_TOKEN attribute is required to use API';
    }

    const body = {
      brandCode: contact?.getAttributes().brandCode.value,
      brandName: contact?.getAttributes().brandName.value,
      toNumber: toNumber,
      callNumber: contact?.getInitialConnection()?.getEndpoint().phoneNumber
    };

    const response = await post(api.resources.sendText, body, token);

    if (response.status !== 200) {
      const error = await response.json();
      logger.error('sendText error:', error);
      return error;
    } else {
      logger.debug(`sendText success: ${response.status} ${response.statusText}`);
      return 'Success';
    }
  };

  const submitSelfAssignTask = async (agentUsername: string, taskName: string, taskDesc: string): Promise<string> => {
    const body = {
      agentUsername,
      taskName,
      taskDesc
    };

    const response = await post(api.resources.selfAssignTask, body, 'selfAssignTask');

    if (response.status !== 200) {
      const error = await response.json();
      logger.error('selfAssignTask error:', error);
      return;
    } else {
      logger.debug(`selfAssignTask success: ${response.status} ${response.statusText}`);
      return await response.json();
    }
  };

  const checkForLocation = async (contact: connect.Contact): Promise<string> => {
    const token = contact.getAttributes().CCP_TOKEN.value;

    if (!token) {
      logger.error('CCP_TOKEN attribute is required to use API');
      return 'CCP_TOKEN attribute is required to use API';
    }

    const body = {
      phoneNumber: contact?.getInitialConnection()?.getEndpoint().phoneNumber
    };

    const response = await post(api.resources.checkForLocation, body, token);

    if (response.status !== 200) {
      const error = await response.json();
      logger.error('sendText error:', error);
      return error;
    } else {
      logger.debug(`sendText success: ${response.status} ${response.statusText}`);
      return await response.json();
    }
  };

  const validateGpbr = async (gpbr: string): Promise<IValidateGpbrResult> => {
    const body = {
      gpbr
    };

    const response = await post(api.resources.validateGpbr, body, 'validateGpbr');

    if (response.status !== 200) {
      const error = await response.json();
      logger.error('validateGpbr error:', error);
      return {isValid: false, errorMessage: 'Failed to validate GPBR input.'};
    } else {
      logger.debug(`validateGpbr success: ${response.status} ${response.statusText}`);
      return await response.json();
    }
  };

  const translateText = async (text: string, targetLanguage: string, sourceLanguage?: string): Promise<any> => {
    //TODO implement
    // return 'TRANSLATED TEXT'
    const body = {
      text,
      targetLanguage,
      sourceLanguage
    };
    const response = await post(api.resources.translateText, body, 'translateText');
    if (response.status !== 200) {
      const error = await response.json();
      logger.error('translateText error:', error);
      return {isValid: false, errorMessage: 'Failed to translate text'};
    } else {
      logger.debug(`Translate text success: ${response.status} ${response.statusText}`);
      return await response.json();
    }
  };

  const getCallRecord = async (contactId: string): Promise<ICallRecordResult> => {
    const queryParams = [{Name: 'contactId', Value: contactId}];

    const response = await get(api.resources.callRecord, queryParams, 'callRecord');
    if (response.status !== 200) {
      const error = await response.json();
      logger.error('getcallRecordEntries error:', error);
      return;
    } else {
      logger.debug(`getcallRecordEntries success: ${response.status} ${response.statusText}`);
      return await response.json();
    }
  };

  const updateCallRecord = async (
    contactId: string,
    callLoggerEntries: ICallLoggerEntries,
    dispositionAttributes: IDispositionAttributes
  ): Promise<any> => {
    const body = {
      contactId,
      callLoggerEntries,
      dispositionAttributes
    };
    const response = await post(api.resources.callRecord, body, 'callRecord');

    if (response.status !== 200) {
      const error = await response.json();
      logger.error('updateCallRecord error:', error);
      return 'Failed';
    } else {
      logger.debug(`updateCallRecord success: ${response.status} ${response.statusText}`);
      return 'Success';
    }
  };

  const context: IRestApiContext = {
    updateContactAttributes,
    updateContactAttributesFromSnapshot,
    sendText,
    checkForLocation,
    submitSelfAssignTask,
    validateGpbr,
    translateText,
    getCallRecord,
    updateCallRecord
  };

  return <RestApiContext.Provider value={context}>{props.children}</RestApiContext.Provider>;
}
