import { ErrorBoundary } from 'react-error-boundary';
import { Logger, LoggingService } from '../../services/LoggingService';
import { IRecentContactsProps, RecentContacts } from './RecentContacts';
import { ErrorRecentContacts } from './ErrorRecentContacts';

export const SafeRecentContacts = (props: IRecentContactsProps) => {

  const logger: Logger = new LoggingService().getLogger('SafeRecentContacts');

  const recentContactsErrorHandler = (error: Error, info: { componentStack: string }) => {
    connect.getLog().error('Call History pane error caught by error boundary').withException(error);
    logger.error('Call History pane error caught by error boundary', { error });
  };

  return <ErrorBoundary FallbackComponent={ErrorRecentContacts} onError={recentContactsErrorHandler}>
    <RecentContacts {...props} />
  </ErrorBoundary>
}