import React from 'react';
import {Card, Icon, List, ListItem} from 'semantic-ui-react';

export const ErrorDispositionPane = () => {
  return (
    <Card className="card-jmb">
      <Card.Content className="header ean">
        <Card.Header textAlign="left">
          <Icon name="wpforms" />
          Disposition Error
        </Card.Header>
      </Card.Content>
      <Card.Content>
        <List divided relaxed>
          <ListItem>
            <List.Header>
              <strong>Error</strong>
            </List.Header>
            <List.Description>Error displaying disposition list</List.Description>
          </ListItem>
        </List>
      </Card.Content>
    </Card>
  );
};
