import React, {Fragment, useContext, useEffect, useState} from 'react';
import {Button, Header, Icon, Label, Message, Popup} from 'semantic-ui-react';
import {AppContext} from '../../contexts/appContext';
import {ContactContext} from '../../contexts/contactContext';

interface IProps {
  brand: string;
}

export const FordLincolnHeader = (props: IProps) => {
  const {config} = useContext(AppContext);
  const {selectedContact, agentLang} = useContext(ContactContext);
  const [attributes, setAttributes] = useState(selectedContact.getAttributes());

  useEffect(() => {
    setAttributes(selectedContact.getAttributes());
  }, [selectedContact]);
  // const attributesRef = useRef(attributes);

  // useEffect(() => {
  //   attributesRef.current = attributes;
  // }, [attributes]);
  let brandCode = props.brand;

  if (!brandCode || brandCode === '') {
    brandCode = 'ean';
  }

  const getBrandMaxHeight = () => {
    switch (brandCode) {
      case 'ean':
      case 'ford':
      case 'lincoln':
        return '60px';
      default:
        // all other brandCodes
        return '30px';
    }
  };

  const updateLocationClipboard = (latitude: string, longitude: string) => {
    navigator.clipboard.writeText(latitude + ':' + longitude).then(
      function () {
        /* clipboard successfully set */
      },
      function () {
        /* clipboard write failed */
      }
    );
  };

  const getLocationPopupTrigger = () => {
    return (
      <div>
        <p></p>
        <Label as="a" circular color="grey" size="large" style={{width: '20px'}}>
          <Icon name="location arrow" />
        </Label>
      </div>
    );
  };

  const getLocationPopupContent = () => {
    return (
      <Fragment>
        {attributes && attributes.userLocationAddressFound && attributes.userLocationAddressFound.value === 'true' && (
          <Message>
            <p>
              <b>{config.translate('User Location Nearest Address', agentLang) || 'User Location Nearest Address'}:</b>
            </p>
            <p>{attributes.userLocationAddressLabel?.value}</p>
            <hr />
            <p>
              <b>{config.translate('Latitude', agentLang) || 'Latitude'}:</b> {attributes.userLocationLatitude?.value}{' '}
              <br />
              <b>{config.translate('Longitude', agentLang) || 'Longitude'}:</b>{' '}
              {attributes.userLocationLongitude?.value}
            </p>
            <Button
              circular
              color="grey"
              icon="copy outline"
              onClick={() => {
                updateLocationClipboard(
                  attributes.userLocationLatitude?.value,
                  attributes.userLocationLongitude?.value
                );
              }}
            />
          </Message>
        )}
        {attributes &&
          attributes.vehicleInfoLocationAddressFound &&
          attributes.vehicleInfoLocationAddressFound?.value === 'true' && (
            <Message>
              <p>
                <b>
                  {config.translate('Vehicle Location Nearest Address', agentLang) ||
                    'Vehicle Location Nearest Address'}
                  :
                </b>
              </p>
              <p>{attributes.vehicleInfoLocationAddressLabel?.value}</p>
              <hr />
              <p>
                <b>{config.translate('Latitude', agentLang) || 'Latitude'}:</b>{' '}
                {attributes.vehicleInfoLocationLatitude?.value} <br />
                <b>{config.translate('Longitude', agentLang) || 'Longitude'}:</b>{' '}
                {attributes.vehicleInfoLocationLongitude?.value}
              </p>
              <Button
                circular
                color="grey"
                icon="copy outline"
                onClick={() => {
                  updateLocationClipboard(
                    attributes.vehicleInfoLocationLatitude?.value,
                    attributes.vehicleInfoLocationLongitude?.value
                  );
                }}
              />
            </Message>
          )}
      </Fragment>
    );
  };

  const getLocationPopup = () => {
    if (
      attributes.userLocationAddressFound?.value === 'true' ||
      attributes.vehicleInfoLocationAddressFound?.value === 'true'
    ) {
      return (
        <div style={{float: 'right'}}>
          <Popup
            trigger={getLocationPopupTrigger()}
            content={getLocationPopupContent()}
            on="hover"
            position="top left"
            hoverable
          ></Popup>
        </div>
      );
    }

    return null;
  };

  return (
    <Header className={brandCode} as="h2">
      <Icon name="car" />
      <Header.Content style={{width: '100%'}}>
        <img src={`${brandCode}.png`} style={{maxHeight: getBrandMaxHeight()}} alt={`${brandCode} logo`} />{' '}
        {getLocationPopup()}
      </Header.Content>
    </Header>
  );
};
