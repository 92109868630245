import dayjs from 'dayjs';
import React, {Fragment, useContext, useState, useEffect, useRef} from 'react';
import {AppContext} from '../../contexts/appContext';
import {RestApiContext} from '../../contexts/restApiContext';
import {CcpWrapperContext} from '../../contexts/ccpWrapperContext';
import {ContactContext} from '../../contexts/contactContext';
import {Logger, LoggingService} from '../../services/LoggingService';
import {Button, Dimmer, Input, InputOnChangeData, Loader, Message, Modal, Segment} from 'semantic-ui-react';

export const GetLocationModal = () => {
  const {config} = useContext(AppContext);
  const {updateContactAttributes, sendText, checkForLocation} = useContext(RestApiContext);
  const {locationModalVisible, setLocationModalVisible} = useContext(CcpWrapperContext);
  const {selectedContact, agentLang, selectedContactRefresh} = useContext(ContactContext);
  const [attributes, setAttributes] = useState<connect.AttributeDictionary | null>(null);
  const [numberToSendLocationText, setNumberToSendLocationText] = useState<string>('');
  const [locationRequestSubmitted, setLocationRequestSubmitted] = useState<boolean>(false);
  const [locationRequestCancelled, setLocationRequestCancelled] = useState<boolean>(false);
  const [locationStartTimestamp, setLocationStartTimestamp] = useState<number>(0);
  const [locationCheckCounter, setLocationCheckCounter] = useState<number>(0);
  const [waitingForLocationResponse, setWaitingForLocationResponse] = useState<boolean>(false);

  const locationStartTimestampRef = useRef(locationStartTimestamp);
  const locationCheckCounterRef = useRef(locationCheckCounter);
  const locationRequestCancelledRef = useRef(locationRequestCancelled);

  const logger: Logger = new LoggingService().getLogger('GetLocationModal');

  useEffect(() => {
    setAttributes(selectedContact.getAttributes());
  }, [selectedContact]);

  useEffect(() => {
    locationStartTimestampRef.current = locationStartTimestamp;
    locationCheckCounterRef.current = locationCheckCounter;
    locationRequestCancelledRef.current = locationRequestCancelled;
  }, [locationStartTimestamp, locationCheckCounter, locationRequestCancelled]);

  useEffect(() => {
    setNumberToSendLocationText(
      selectedContact.getInitialConnection()?.getEndpoint().phoneNumber.replace('+1', '') || ''
    );
  }, [selectedContact]);

  const sendLocationText = async () => {
    setLocationStartTimestamp(() => dayjs().unix());
    setLocationRequestCancelled(false);
    setLocationRequestSubmitted(true);
    setWaitingForLocationResponse(true);
    await sendText(selectedContact, `+1${numberToSendLocationText}`);
    checkLocation();
  };

  const checkLocation = async (): Promise<void> => {
    const currentContactId = selectedContact.getContactId();

    logger.debug('Getting location information');
    let locationResponse = await checkForLocation(selectedContact);
    logger.debug('Location response:', {...locationResponse});
    if (
      locationResponse.locationTimestamp &&
      locationStartTimestampRef.current !== 0 &&
      locationStartTimestampRef.current < parseInt(locationResponse.locationTimestamp)
    ) {
      if (locationResponse.locationSuccess === true) {
        logger.debug('location success', locationResponse);
        await updateContactAttributes(selectedContact, {
          accuracy: locationResponse.accuracy.toString(),
          addressFound: locationResponse.addressFound,
          addressLabel: locationResponse.addressLabel,
          addressState: locationResponse.addressState,
          city: locationResponse.city,
          county: locationResponse.county,
          houseNumber: locationResponse.houseNumber,
          latitude: locationResponse.latitude.toString(),
          locationSuccess: locationResponse.locationSuccess.toString(),
          locationTimestamp: locationResponse.locationTimestamp.toString(),
          longitude: locationResponse.longitude.toString(),
          postalCode: locationResponse.postalCode
        });
      } else {
        logger.debug('location failure');
        await updateContactAttributes(selectedContact, {
          locationSuccess: locationResponse.locationSuccess.toString(),
          locationTimestamp: locationResponse.locationTimestamp.toString(),
          message: locationResponse.message
        });
      }

      setTimeout(() => {
        selectedContactRefresh(currentContactId);
        setWaitingForLocationResponse(false);
      }, 1000);
    } else {
      setLocationCheckCounter((curr) => curr + 1);
      if (locationCheckCounterRef.current <= 100 && locationRequestCancelledRef.current === false) {
        setTimeout(checkLocation, 1000);
      }
    }
  };

  const closeLocationModal = () => {
    setLocationModalVisible(false);
    setLocationRequestCancelled(true);
    setLocationRequestSubmitted(false);
    setLocationStartTimestamp(0);
    setLocationCheckCounter(0);
    setWaitingForLocationResponse(false);
    setNumberToSendLocationText(
      selectedContact.getInitialConnection()?.getEndpoint().phoneNumber.replace('+1', '') || ''
    );
  };

  const updateLocationClipboard = (latitude: string, longitude: string) => {
    navigator.clipboard.writeText(latitude + ':' + longitude).then(
      function () {
        /* clipboard successfully set */
      },
      function () {
        /* clipboard write failed */
      }
    );
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => {
    setNumberToSendLocationText(data.value);
  };

  const modalContent = () => {
    if (!waitingForLocationResponse && !locationRequestSubmitted) {
      return (
        <Modal.Description>
          <p>
            {config.translate('locationModalInstructions', agentLang) ||
              'Please enter the 10 digit phone number you would like to send a text message.'}
          </p>
          <Input
            placeholder={config.translate('Enter Mobile Number', agentLang) || 'Enter Mobile Number'}
            style={{width: '100%'}}
            value={numberToSendLocationText}
            name="numberToSendLocationText"
            onChange={handleChange}
          />
          <p>
            <br />
            <i>
              {' '}
              <small>
                {config.translate('locationModalDetails', agentLang) ||
                  'The text message will include a link to a website that will capture the location of the customer. Please verify that the phone number is a mobile device able to receive SMS text messages. Also verify that the customer has location services enabled on their phone.'}
              </small>{' '}
            </i>
          </p>
          <Button color="grey" onClick={() => sendLocationText()} disabled={numberToSendLocationText.length !== 10}>
            {config.translate('Send Text Message', agentLang) || 'Send Text Message'}
          </Button>
        </Modal.Description>
      );
    } else if (waitingForLocationResponse) {
      return (
        <Fragment>
          <Segment>
            <Dimmer active inverted>
              <Loader size="large">
                {config.translate('Waiting for customer response.', agentLang) || 'Waiting for customer response.'}
              </Loader>
            </Dimmer>
            <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br />'
          </Segment>
        </Fragment>
      );
    } else if (
      !waitingForLocationResponse &&
      locationRequestSubmitted &&
      attributes.locationSuccess &&
      attributes.locationSuccess.value === 'true'
    ) {
      return (
        <Message>
          <p>
            <small>
              {config.translate('Captured at', agentLang) || 'Captured at'}{' '}
              {dayjs(parseInt(attributes.createdTimestamp?.value) * 1000).format('MMM DD - hh:mm A')}{' '}
            </small>
          </p>
          <p>
            <b>{config.translate('Nearest Address', agentLang) || 'NearestAddress'}:</b>
          </p>
          <p>{attributes.addressLabel?.value}</p>
          <hr />
          <p>
            <b>{config.translate('Latitude', agentLang) || 'Latitude'}:</b> {attributes.latitude?.value} <br />
            <b>{config.translate('Longitude', agentLang) || 'Longitude'}:</b> {attributes.longitude?.value}
          </p>
          <p style={{lineHeight: '1.0'}}>
            <small>
              <i>
                {config.translate('locationDisclaimerA', agentLang) ||
                  'We are 95% confident that these coordinates are within'}{' '}
                {Math.round(parseInt(attributes.accuracy?.value))}{' '}
                {config.translate('locationDisclaimerB', agentLang) || "meters of the customer's actual location."}
              </i>
            </small>
          </p>
          <Button
            circular
            color="grey"
            icon="copy outline"
            onClick={() => updateLocationClipboard(attributes.latitude?.value, attributes.longitude?.value)}
          />
        </Message>
      );
    } else if (
      !waitingForLocationResponse &&
      locationRequestSubmitted &&
      attributes.locationSuccess &&
      attributes.locationSuccess.value === 'false'
    ) {
      <Message>
        <p>
          <b>{config.translate('Failed to Capture Location', agentLang) || 'Failed to Capture Location'}</b>
        </p>
        <p>
          {config.translate('Error Message', agentLang) || 'Error Message'}: {attributes.message?.value}
        </p>
        <p>
          <small>
            {config.translate('Attempted at', agentLang) || 'Attempted at'}{' '}
            {dayjs(parseInt(attributes.locationTimestamp?.value) * 1000).format('MMM DD - hh:mm A')}{' '}
          </small>
        </p>
      </Message>;
    }
    return null;
  };

  if (attributes === null) {
    return null;
  }

  return (
    <Modal onOpen={() => setLocationModalVisible(true)} onClose={closeLocationModal} open={locationModalVisible}>
      <Modal.Header>
        {config.translate('Capture Current Location', agentLang) || 'Capture Current Location'}
      </Modal.Header>
      <Modal.Content>{modalContent()}</Modal.Content>
      <Modal.Actions>
        <Button color="black" onClick={closeLocationModal}>
          {config.translate('Cancel', agentLang) || 'Cancel'}
        </Button>
      </Modal.Actions>
    </Modal>
  );
};
