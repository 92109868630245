import React, {useContext, useEffect, useState} from 'react';
import {
  Button,
  Card,
  Dimmer,
  FormInputProps,
  Icon,
  IconProps,
  Input,
  InputProps,
  Label,
  Loader,
  Message,
  Popup,
  PopupProps,
  Segment,
  Table
} from 'semantic-ui-react';
import {AppContext} from '../../contexts/appContext';
import {ContactContext} from '../../contexts/contactContext';
import {ICallLogItem, IValidateGpbrResult, RestApiContext} from '../../contexts/restApiContext';
import {Logger, LoggingService} from '../../services/LoggingService';

const emptyLogItem: ICallLogItem = {
  gpbrId: '',
  reservationId: '',

  _gpbrId_errorMessage: '',
  _gpbrId_isValid: true,
  _gpbrId_hasMatch: false,
  _gpbrId_isValidating: false
};

const gpbrInputProps: InputProps = {
  name: 'gpbrId',
  type: 'text',
  maxLength: 4,
  minLength: 4,
  pattern: `.{4}`, // Must be 4 Chars
  size: 'small',
  fluid: true,
  placeholder: 'XXXX'
};

const reservationInputProps: InputProps = {
  name: 'reservationId',
  type: 'text',
  fluid: true,
  size: 'small',
  placeholder: 'Enter here...'
};

const DEBOUNCE_TIMEOUT = 1000 * 3; // 3 seconds after changes, save them

export const CallLoggerPanel = () => {
  const logger: Logger = new LoggingService().getLogger('CallLoggerPanel');
  const {config} = useContext(AppContext);
  const {selectedContact} = useContext(ContactContext);
  const [contactAttributes, setContactAttributes] = useState<any>();
  const {validateGpbr, updateCallRecord, getCallRecord, updateContactAttributes} = useContext(RestApiContext);
  const [displayPanel, setDisplayPanel] = useState<boolean>(false);
  const [isUnsaved, setIsUnsaved] = useState<boolean>(false);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [isWaiting, setIsWaiting] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<string>('');
  const [timerRef, setTimerRef] = useState<any>();
  const [logItems, setLogItems] = useState<ICallLogItem[]>([]);
  const [newLogItem, setNewLogItem] = useState<ICallLogItem>(emptyLogItem);

  const loadCallLoggerItems = async () => {
    if (!displayPanel) {
      return; // Don't load items if use call logger is not true
    }
    const result = await getCallRecord(selectedContact.contactId);
    if (result.callLoggerEntries) {
      setLogItems(result.callLoggerEntries!.logItems);
      setIsUnsaved(false);
    } else if (contactAttributes?.resNumbers && contactAttributes?.billingBranches) { 
      // If there is no call record entry, but there is already ctr attributes, pre-populate with them
      const {resNumbers, billingBranches} = contactAttributes;
      const resNumberList = resNumbers.value.split(',');
      const existingList: ICallLogItem[] = billingBranches.value.split(',').map((k, i) => {
        return {
          gpbrId: k,
          _gpbrId_isValid: true,
          _gpbrId_hasMatch: true,
          reservationId: resNumberList[i] == 'NA' ? '' : resNumberList[i]
        };
      });
      setLogItems([...existingList]);
      setIsUnsaved(false);
    } else {
      setLogItems([]);
      setIsUnsaved(false);
    }
  };

  useEffect(() => {
    if (!contactAttributes || !contactAttributes?.service || !contactAttributes?.service?.value) {
      logger.debug('no service attribute in contact attributes');
    } else {
      const service = contactAttributes.service.value;
      const useCallLogger: boolean = config.useCallLoggerByService(service);
      setDisplayPanel(useCallLogger);
      loadCallLoggerItems();
    }
  }, [contactAttributes, config]);

  /**
   * Clear out Call Logger Table when the contact is destroyed
   */
  useEffect(() => {
    logger.debug(`selectedContactChanged`);
    if (selectedContact) {
      selectedContact?.onDestroy(() => {
        setLogItems([]);
        setNewLogItem({
          ...emptyLogItem
        });
      });

      setContactAttributes({...selectedContact?.getAttributes()});
    }
  }, [selectedContact]);

  /**
   * Autosaves the logs in the table after a 3 sec debounce
   */
  useEffect(() => {
    logger.debug(`Changes Received! Waiting for additional changes...`);
    setIsWaiting(true);

    /**
     * Clear out timer to restart change detection countdown
     */
    if (timerRef) {
      clearTimeout(timerRef);
      setTimerRef(undefined);
    }

    /**
     * Only debounce changes if there are log items to push AND the user is not adding a new entry
     */
    const allValid = logItems.map((k) => k._gpbrId_isValid && k._gpbrId_hasMatch).every(Boolean);
    if (allValid && !newLogItem.gpbrId && !newLogItem.reservationId && logItems?.length) {
      const ref = setTimeout(() => saveToCtr(), DEBOUNCE_TIMEOUT);
      setTimerRef(ref);
    } else {
      logger.debug(`Pausing debounce to let user finish.`);
      setIsWaiting(false);
      setIsUnsaved(true);
    }

    return () => {
      logger.debug(`Cleaning up resources...`);
      clearTimeout(timerRef);
      setTimerRef(undefined);
    };
  }, [logItems, newLogItem]);

  /**
   * Do not render Call Logger if service does not specifically enable it via the 'useCallLogger' attribute.
   */
  if (!displayPanel) return <div title="HiddenCallLoggerPanel"></div>;

  /**
   * adds the call logger fields to the call record table
   */
  const saveToCtr = async () => {
    logger.debug(`Submitting changes...`);
    setIsWaiting(false);
    setIsSaving(true);
    try {
      // Display error if we cannot find a contact
      if (!selectedContact) {
        setErrorMsg(`No active contact found.`);
        setIsUnsaved(true);
        return;
      }
      let response = 'NOT STARTED';

      // Update call record
      try {
        response = await updateCallRecord(selectedContact.contactId, {logItems}, undefined);
        console.log('call logger save response', response);
      } catch (err) {
        logger.error('Error saving to call record table', {err});
      }

      // Also, update the CTR (We won't drive the page logic off of it, just save it)
      const billingBranches: string = logItems.map((item) => item.gpbrId).join(',');
      const resNumbers: string = logItems.map((item) => item.reservationId || 'NA').join(',');
      const billingBranchesCount: string = logItems.length.toString();

      // Update CTR
      try {
        const ctrResponse = await updateContactAttributes(selectedContact, {
          billingBranches,
          resNumbers,
          billingBranchesCount
        });

        console.log('CTR call logger save response', ctrResponse);
      } catch (err) {
        logger.error('Error saving to CTR', {err});
      }

      if (response !== 'Success') {
        setErrorMsg(response);
        setIsUnsaved(true);
      } else {
        setIsUnsaved(false);
      }
    } catch (error) {
      setIsUnsaved(true);
    } finally {
      setIsSaving(false);
    }
  };

  /**
   * Adds the 'Work in progress' log item to the list
   */
  const addLogItem = () => {
    logger.debug(`Adding new log item to the list in state.`);
    if (!newLogItem.gpbrId) {
      setErrorMsg(`Group Branch is a required field`);
      return;
    }
    if (!newLogItem.reservationId) {
      newLogItem.reservationId = ``;
    }
    let list = [...logItems, newLogItem];
    setLogItems([...list]);
    logger.debug(logItems);
    setNewLogItem({
      ...emptyLogItem
    });
  };

  /**
   * Deletes an existing log item from the list
   */
  const deleteLogItem = (i: number) => {
    logger.debug(`Deleting existing log item from list in state.`);
    let list = logItems;
    list.splice(i, 1);
    setLogItems([...list]);
  };

  /**
   * Updates the fields for an existing log item in the list
   */
  const updateListItem = (e: any, i: number = -1) => {
    const {name, value, validity} = e.target;

    let metadata = {};
    if (name === 'gpbrId') {
      Object.assign(metadata, {
        _gpbrId_isValid: validity?.valid,
        _gpbrId_isValidating: false,
        _gpbrId_hasMatch: false
      });
    }

    if (i === -1) {
      /**
       * Updates the fields for the 'Work in Process' log item form
       */
      logger.debug(`Updating new log item in state.`, e);
      if (!name || value == null) return;
      setNewLogItem({
        ...newLogItem,
        ...metadata,
        [name]: value.toUpperCase()
      });
    } else {
      /**
       * Updates the fields for an existing log item in the list
       */
      logger.debug(`Updating existing log item from list in state.`);
      let list = logItems;
      list[i] = {
        ...list[i],
        ...metadata,
        [name]: value.toUpperCase()
      };
      setLogItems([...logItems]);
    }
  };

  const handleBlur = async (item: ICallLogItem, i: number = -1): Promise<void> => {
    // If the item is the form for a new item, and it is empty, do not validate.
    if (i === -1 && !item.gpbrId) return;
    if (!item._gpbrId_isValid) return;

    const updateState = (entry: ICallLogItem) => {
      if (i < 0) {
        setNewLogItem({...entry});
      } else {
        const list = logItems;
        list[i] = entry;
        setLogItems([...list]);
      }
    };

    updateState({
      ...item,
      _gpbrId_isValidating: true
    });

    logger.debug(`Validating GPBR...`);
    const data: IValidateGpbrResult = await validateGpbr(item.gpbrId);

    updateState({
      ...item,
      _gpbrId_errorMessage: data?.errorMessage,
      _gpbrId_isValid: data?.isValid,
      _gpbrId_hasMatch: data?.isValid,
      _gpbrId_isValidating: false
    });
  };

  const renderSaveStatus = () => {
    if (isWaiting)
      return <Popup content="Waiting for more changes..." trigger={<Icon loading size="large" name="spinner" />} />;
    if (isUnsaved)
      return (
        <Popup
          content="There are unsaved changes."
          trigger={
            <Icon.Group size="large">
              <Icon disabled name="save" />
              <Icon corner="top right" name="warning" color="orange" />
            </Icon.Group>
          }
        />
      );
    return (
      <Popup
        content="No unsaved changes."
        trigger={
          <Icon.Group size="large">
            <Icon disabled name="save" />
            <Icon corner="top right" name="check" color="green" />
          </Icon.Group>
        }
      />
    );
  };

  const renderGpbrInputIcon = (item: ICallLogItem, i?: number) => {
    const renderIcon = () => {
      if (!i && !item?.gpbrId) return <></>;

      let iconConfig: IconProps = {
        corner: 'top right'
      };

      if (item._gpbrId_isValidating) {
        Object.assign(iconConfig, {
          name: 'spinner',
          loading: true
        });
      } else if (item._gpbrId_hasMatch) {
        Object.assign(iconConfig, {
          name: 'check circle',
          color: 'green'
        });
      } else if (item._gpbrId_isValid) {
        Object.assign(iconConfig, {
          name: 'check'
        });
      } else {
        Object.assign(iconConfig, {
          name: 'warning',
          color: 'orange'
        });
      }
      return <Icon {...iconConfig} />;
    };

    const renderInput = () => {
      const inputConfig: FormInputProps = {
        ...gpbrInputProps,
        value: item.gpbrId,
        onBlur: (e) => handleBlur(item, i),
        onChange: (e) => updateListItem(e, i),
        error: !item._gpbrId_isValid
      };

      return (
        <Input icon {...inputConfig}>
          <input title={`call-log-item-gbpr-input-${i ?? 'newLogItem'}`} />
          {renderIcon()}
        </Input>
      );
    };

    let popUpConfig: PopupProps = {
      size: 'tiny',
      trigger: renderInput()
    };

    if (item?._gpbrId_errorMessage) {
      popUpConfig.content = item._gpbrId_errorMessage;
      popUpConfig.defaultOpen = true;
    } else if (item?._gpbrId_hasMatch) {
      popUpConfig.content = (
        <>
          <Icon name="check circle" color="green" />
          <small>Valid Group Branch</small>
        </>
      );
      popUpConfig.header = item?.gpbrId;
    } else {
      // popUpConfig.disabled = true;
      popUpConfig.header = 'Enter a valid Group Branch';
    }

    return <Popup {...popUpConfig} />;
  };

  /**
   * 04.26.23
   * Nick F. requested a scrollbar be used within the table whenever there are >3 items.
   *
   * To set a max height for the tbody table element, you must change the 'display' css property... thus unformatting the entire table.
   *
   * To get around this, we are defining 3 tables (header, body, footer) and wrapping the body table in a div that we can set a max height on.
   */
  let tableContentStyle: any = {
    overflowY: 'auto'
  };

  if (logItems?.length > 3) {
    Object.assign(tableContentStyle, {
      marginRight: -10,
      maxHeight: `${45 * 3}px`
    });
  }

  return (
    <Card fluid={true} title="CallLoggerPanel">
      <Card.Content>
        <Card.Header textAlign="left">
          <Icon name="folder open outline" />
          {'Call Logger'}
        </Card.Header>
      </Card.Content>
      <Card.Content>
        <Segment basic style={{padding: '0'}} className="call-logger-table">
          {isSaving && (
            <Dimmer active>
              <Loader content="Saving" />
            </Dimmer>
          )}
          <Table style={{borderBottom: 0, borderRadius: '5px 5px 0 0'}} celled compact size="small" unstackable={true}>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell width="4">GPBR</Table.HeaderCell>
                <Table.HeaderCell width="10">RA/Res</Table.HeaderCell>
                <Table.HeaderCell width="2" textAlign="center">
                  {renderSaveStatus()}
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
          </Table>
          <div style={tableContentStyle}>
            <Table
              style={{borderBottom: 0, borderTop: 0, borderRadius: '0'}}
              celled
              striped
              compact
              size="small"
              unstackable={true}
            >
              <Table.Body title="callLogger-table-body">
                {logItems?.map((item: ICallLogItem, i: number) => (
                  <Table.Row title={`call-log-item-${i}`} key={`call-log-item-${i}`}>
                    <Table.Cell width="4">{renderGpbrInputIcon(item, i)}</Table.Cell>
                    <Table.Cell width="10">
                      <Input
                        value={item?.reservationId}
                        onChange={(e) => updateListItem(e, i)}
                        {...reservationInputProps}
                      />
                    </Table.Cell>
                    <Table.Cell width="2" textAlign="center">
                      <Popup
                        content="Remove log from list"
                        trigger={
                          <Button
                            circular
                            color="red"
                            size="tiny"
                            icon="trash alternate"
                            title={`call-log-item-delete-button-${i}`}
                            data-content="Delete this item"
                            onClick={() => deleteLogItem(i)}
                          />
                        }
                      />
                    </Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>
            </Table>
          </div>
          <Table style={{borderRadius: '0 0 5px 5px'}} celled striped compact size="small" unstackable={true}>
            <Table.Footer>
              <Table.Row>
                <Table.Cell width="4">{renderGpbrInputIcon(newLogItem)}</Table.Cell>
                <Table.Cell width="10">
                  <Input
                    title="newLogItem-reservationId-input"
                    value={newLogItem?.reservationId}
                    onChange={(e) => updateListItem(e)}
                    {...reservationInputProps}
                  />
                </Table.Cell>
                <Table.Cell width="2" textAlign="center">
                  <Popup
                    content="Add log to list"
                    trigger={
                      <Button
                        circular
                        icon="plus"
                        size="tiny"
                        color="green"
                        title="newLogItem-add-button"
                        disabled={!newLogItem._gpbrId_isValid || !newLogItem?._gpbrId_hasMatch}
                        onClick={addLogItem}
                      />
                    }
                  />
                </Table.Cell>
              </Table.Row>
            </Table.Footer>
          </Table>
          {errorMsg && <Message onDismiss={() => setErrorMsg(``)} error header="Request Failed" content={errorMsg} />}
        </Segment>
      </Card.Content>
    </Card>
  );
};
