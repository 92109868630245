import { Comment } from 'semantic-ui-react';

/**
 * Comment that displays
 * @param props
 * @returns
 */
export const ContactEventMessage = (props: any) => {
  const getEventMessage = (contentType) => {
    switch (contentType) {
      case 'application/vnd.amazonaws.connect.event.participant.joined':
        return `${props.item.DisplayName} has joined the chat`;
      case `application/vnd.amazonaws.connect.event.participant.left`:
        return `${props.item.DisplayName} has left the chat`;
      case `application/vnd.amazonaws.connect.event.transfer.succeed`:
        return `Successfully transferred`;
      case `application/vnd.amazonaws.connect.event.transfer.failed`:
        return `Transfer failed`;
      case `application/vnd.amazonaws.connect.event.chat.ended`:
        return `Chat Ended`;
      default:
        break;
    }
  };

  return (
    <div
      style={{
        // margin: props.item.participantRole === 'CUSTOMER' ? '20px 50% 20px auto' : '20px auto 20px 50%',
        // backgroundColor: props.item.participantRole === 'CUSTOMER' ? '#cbe0ff' : '#0161a9',
        borderRadius: 20,
        padding: 10
      }}
    >
      <Comment>
        <Comment.Content
          style={{
            textAlign:
              props.item.ParticipantRole === 'CUSTOMER'
                ? 'left'
                : props.item.ParticipantRole === undefined
                  ? 'center'
                  : 'right'
          }}
        >
          <Comment.Metadata>
            <div>
              {new Date(props.item.AbsoluteTime).toLocaleTimeString([], {
                timeStyle: 'short'
              })}
            </div>
          </Comment.Metadata>
          <Comment.Text style={{ fontWeight: 'bold', color: 'gray' }}>
            {getEventMessage(props.item.ContentType)}
          </Comment.Text>
        </Comment.Content>
      </Comment>
    </div>
  );
};
