//TODO don't use yet
const toAWSLanguageCode = (languageCode: string) => {
  let language = supportedLanguages[languageCode];
  if (language) {
    return languageCode;
  } else if (languageCode.includes('-')) {
    return toAWSLanguageCode(languageCode.split('-')[0]);
  } else {
    console.error(languageCode);
    throw new Error('Language Not Supported for AWS Translate');
  }
};

/**
 * Receives a language code (RFC-5646) and returns the full name for that language in English.
 * @param languageCode 
 * @returns 
 */
const getAWSLanguageName = (languageCode: string) => {
  let langResp = supportedLanguages[languageCode];
  if (langResp) {
    return langResp.name;
  } else {
    return;
  }
};

/**
 * Returns an object formated for use with the Semantic UI Dropdown component.
 * @returns 
 */
const getDropdownLanguages = () => {
  let arr = [];
  arr = Object.entries(supportedLanguages).map((item) => {
    return {key: item[0], text: item[1].name, value: item[0]};
  });
  return arr;
};

/**
 * Dictionary of RFC-5646 codes to full English name.
 */
const supportedLanguages = {
  af: {
    name: 'Afrikaans'
  },
  sq: {
    name: 'Albanian'
  },
  am: {
    name: 'Amharic'
  },
  ar: {
    name: 'Arabic'
  },
  hy: {
    name: 'Armenian'
  },
  en: {
    name: 'English'
  },
  no: {
    name: 'Norwegian (Bokmål)'
  },
  az: {
    name: 'Azerbaijani'
  },
  fr: {
    name: 'French'
  },
  ca: {
    name: 'Catalan'
  },
  es: {
    name: 'Spanish'
  },
  bn: {
    name: 'Bengali'
  },
  da: {
    name: 'Danish'
  },
  de: {
    name: 'German'
  },
  bs: {
    name: 'Bosnian'
  },
  bg: {
    name: 'Bulgarian'
  },
  zh: {
    name: 'Chinese (Simplified)'
  },
  'zh-TW': {
    name: 'Chinese (Traditional)'
  },
  hr: {
    name: 'Croatian'
  },
  nl: {
    name: 'Dutch'
  },
  sv: {
    name: 'Swedish'
  },
  th: {
    name: 'Thai'
  },
  cs: {
    name: 'Czech'
  },
  'fa-AF': {
    name: 'Dari'
  },
  cy: {
    name: 'Welsh'
  },
  et: {
    name: 'Estonian'
  },
  fa: {
    name: 'Farsi (Persian)'
  },
  tl: {
    name: 'Filipino, Tagalog'
  },
  fi: {
    name: 'Finnish'
  },
  'fr-CA': {
    name: 'French (Canada)'
  },
  ka: {
    name: 'Georgian'
  },
  el: {
    name: 'Greek'
  },
  gu: {
    name: 'Gujarati'
  },
  ht: {
    name: 'Haitian Creole'
  },
  ha: {
    name: 'Hausa'
  },
  ja: {
    name: 'Japanese'
  },
  ru: {
    name: 'Russian'
  },
  'es-MX': {
    name: 'Spanish (Mexico)'
  },
  he: {
    name: 'Hebrew'
  },
  hi: {
    name: 'Hindi'
  },
  ko: {
    name: 'Korean'
  },
  hu: {
    name: 'Hungarian'
  },
  is: {
    name: 'Icelandic'
  },
  id: {
    name: 'Indonesian'
  },
  ga: {
    name: 'Irish'
  },
  it: {
    name: 'Italian'
  },
  kn: {
    name: 'Kannada'
  },
  kk: {
    name: 'Kazakh'
  },
  lv: {
    name: 'Latvian'
  },
  lt: {
    name: 'Lithuanian'
  },
  mk: {
    name: 'Macedonian'
  },
  ms: {
    name: 'Malay'
  },
  ml: {
    name: 'Malayalam'
  },
  mt: {
    name: 'Maltese'
  },
  mr: {
    name: 'Marathi'
  },
  mn: {
    name: 'Mongolian'
  },
  ps: {
    name: 'Pashto'
  },
  pl:{
    name:'Polish'
  },
  pt:{
    name:'Portuguese (Brazil)'
  },
  "pt-PT":{
    name:'Portuguese (Portugal)'
  },
  pa:{
    name:'Punjabi'
  },
  ro:{
    name:'Romanian'
  },
  sr:{
    name:'Serbian'
  },
  si:{
    name:'Sinhala'
  },
  sk:{
    name:'Slovak'
  },
  sl:{
    name:'Slovenian'
  },
  so:{
    name:'Somali'
  },
  sw:{
    name:'Swahili'
  },
  ta:{
    name:'Tamil'
  },
  te:{
    name:'Telugu'
  },
  tr:{
    name:'Turkish'
  },
  uk:{
    name:'Ukrainian'
  },
  ur:{
    name:'Urdu'
  },
  uz:{
    name:'Uzbek'
  },
  vi:{
    name:'Vietnamese'
  }
};

export const TranslationHelper = {
  toAWSLanguageCode: (l) => toAWSLanguageCode(l),
  getAWSLanguageName,
  getDropdownLanguages
};
