import React, {useContext, useEffect, useState} from 'react';
import {Accordion, Button, Card, Form, Icon, Loader, Menu, Message} from 'semantic-ui-react';
import {AppContext} from '../../contexts/appContext';
import {CcpWrapperContext} from '../../contexts/ccpWrapperContext';
import {ContactContext} from '../../contexts/contactContext';
import {RestApiContext} from '../../contexts/restApiContext';
import {IDispositionConfig} from '../../models/dispositionConfig';
import {IServiceConfig} from '../../models/serviceConfigs';
import {BrandHelper} from '../../services/BrandHelperService';
import {Logger, LoggingService} from '../../services/LoggingService';
import {DispositionMenuItem} from './DispositionMenuItem';
import {DispositionMultiSelectMenuItem} from './DispositionMultiSelectMenuItem';
import {DispositionSpecialMenuItem} from './DispositionSpecialMenuItem';
import { IContactSnapshot } from '../../models/contactSnapshot';

export interface ISpecialOptionsStateMap {
  [name: string]: ISpecialOptionsState; 
}

export interface ISpecialOptionsState {
  value: any;
}

export const DispositionPane = () => {
  const {config} = useContext(AppContext);
  const {updateContactAttributes} = useContext(RestApiContext);
  const {updateContactAttributesFromSnapshot } = useContext(RestApiContext);
  const {selectedContact, selectedContactRefresh, agentLang} = useContext(ContactContext);
  const {setDispositionPaneVisible} = useContext(CcpWrapperContext);
  const [contactAttributes, setContactAttributes] = useState(selectedContact.getAttributes());
  const [brandHelper] = useState(new BrandHelper());
  const [activeIndex, setActiveIndex] = useState<number>(-1);
  const [selectedDisposition, setSelectedDisposition] = useState<string>('');
  const [selectedMultiDisposition, setSelectedMultiDisposition] = useState<string[]>([]);
  const [specialOptionsState, setSpecialOptionsState] = useState<ISpecialOptionsStateMap>({} as ISpecialOptionsStateMap)
  const [dispositionRequired] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [serviceConfig, setServiceConfig] = useState<IServiceConfig | null>(null);
  const [dispositionConfig, setDispositionConfig] = useState<IDispositionConfig | null>(null);

  useEffect(() => {
    if (!selectedContact) {
      return null;
    }
    setContactAttributes(selectedContact.getAttributes());
  }, [selectedContact]);
  const logger: Logger = new LoggingService().getLogger('DispositionPane');

  useEffect(() => {
    if (!contactAttributes || !contactAttributes.service || !contactAttributes.service.value) {
      logger.debug('no service attribute in contact attributes');
      setServiceConfig(null);
    }
    const service = contactAttributes.service.value;
    const svcConfig = config.getServiceConfig(service);
    setServiceConfig(svcConfig);

    if (svcConfig && !svcConfig.dispositionSet) {
      logger.debug('no dispositionSet in service config');
      setDispositionConfig(null);
    }
    const dispSet = config.dispositionConfigByService(service);

    if (!dispSet === null) {
      setDispositionConfig(null);
    }

    setDispositionConfig(dispSet);
  }, [config, contactAttributes, logger]);

  useEffect(() => {
    if (serviceConfig === null) {
      return null;
    }

    if (!dispositionConfig.options) {
      logger.error('Disposition config does not have required options property, returning null');
      return null;
    }

    const multiSelect = dispositionConfig.multiSelect;
    const attributeName = dispositionConfig.attributeName;
    const attributeHasValue = contactAttributes[attributeName] && contactAttributes[attributeName].value ? true : false;

    //start test
    if (multiSelect === true && attributeHasValue) {
      if (selectedDisposition.length > 0) {
        return;
      }
      setSelectedMultiDisposition(contactAttributes[attributeName].value.split(','));
    } else if (multiSelect === false && attributeHasValue) {
      if (selectedDisposition) {
        return;
      }
      setSelectedDisposition(contactAttributes[attributeName].value);
    }
    //end test

    // if (multiSelect === true && attributeHasValue) {
    //   setSelectedMultiDisposition(contactAttributes[attributeName].value.split(','));
    // } else if (multiSelect === false && attributeHasValue) {
    //   setSelectedDisposition(contactAttributes[attributeName].value);
    // }
  }, [serviceConfig, dispositionConfig, contactAttributes, selectedDisposition, specialOptionsState]);

  const getMenuItems = () => {
    if (serviceConfig === null) {
      return null;
    }
    if (!dispositionConfig.options) {
      logger.error('Disposition config does not have required options property, returning null');
      return null;
    }
    const brandCode = getBrandCode();
    const options = dispositionConfig.options;
    const optionsOrder = dispositionConfig.optionsOrder;
    const multiSelect = dispositionConfig.multiSelect;
    const specialOptions = dispositionConfig.specialOptions;
    let menuItems = [];

    let sortedOptions = []
    Object.keys(options).forEach(key => {
      sortedOptions.push(key)
    });

    // Sort options by order if provided
    if (optionsOrder) {
      console.log("Sorting Options")
      sortedOptions.sort((a, b) => {
        if (optionsOrder.indexOf(a) < optionsOrder.indexOf(b)) {
          return -1;
        }
        if (optionsOrder.indexOf(a) > optionsOrder.indexOf(b)) {
          return 1;
        }
        return 0;
      })
    }

    if (multiSelect === true) {
      menuItems = sortedOptions.map((key, index) => {
        return DispositionMultiSelectMenuItem(
          index,
          key,
          options[key],
          brandCode,
          selectedMultiDisposition,
          setSelectedMultiDisposition,
          activeIndex,
          setActiveIndex,
          config,
          agentLang
        );
      });
    } else if (multiSelect === false) {
      menuItems = sortedOptions.map((key, index) => {
        return DispositionMenuItem(
          index,
          key,
          options[key],
          brandCode,
          selectedDisposition,
          setSelectedDisposition,
          activeIndex,
          setActiveIndex,
          config,
          agentLang
        );
      });
    }

    
    // If disposition set has special options for unique option types
    // e.g. date picker
    if (specialOptions) {
      let indexOffset = 0;
      if (options) {
        indexOffset = Object.keys(options).length
      }
      menuItems.push(
        ...Object.keys(specialOptions).map((key, index) => {
          return DispositionSpecialMenuItem(
            index + indexOffset,
            key,
            specialOptions[key],
            brandCode,
            selectedDisposition,
            setSelectedDisposition,
            specialOptionsState,
            setSpecialOptionsState,
            activeIndex,
            setActiveIndex,            
            config,
            agentLang
          );
        })
      );
    }
    return menuItems;
  };

  const getRequiredMessage = () => {
    const message = <Message info>Disposition is required.</Message>;
    return dispositionRequired ? message : null;
  };

  const getBrandCode = () => {
    return brandHelper.getBrandCode(contactAttributes);
  };

  const submitDisposition = async () => {
    logger.debug('Saving Disposition');
    const submittingContactId = selectedContact.getContactId();
    const submittingContact: IContactSnapshot = {
      contactData: {
        attributes: selectedContact.getAttributes(),
        connections: selectedContact.getConnections(),
        contactDuration: '',
        contactFeatures: {},
        contactId: submittingContactId,
        description: '',
        initialContactId: selectedContact.getInitialContactId(),
        initialMethod: '',
        name: '',
        queue: undefined,
        queueTimestamp: undefined,
        state: undefined,
        status: undefined,
        type: selectedContact.getType()
      },
      contactId: submittingContactId,
      getContactId: function (): string {
        return submittingContactId;
      }
    }

    logger.debug('submittingContact', {submittingContact});

    setLoading(true);
    if (serviceConfig === null) {
      return null;
    }
    const dispositionConfig: IDispositionConfig = serviceConfig.dispositionSet;
    if (!dispositionConfig.options) {
      logger.error('Disposition config does not have required options property, returning null');
      return null;
    }

    let attributes: {[key: string]: string};

    if (dispositionConfig.multiSelect === true) {
      const dispositions = selectedMultiDisposition
        .sort((a, b) => {
          if (a.toUpperCase() < b.toUpperCase()) {
            return -1;
          }
          if (a.toUpperCase() > b.toUpperCase()) {
            return 1;
          }
          return 0;
        })
        .toString();

      attributes = {[dispositionConfig.attributeName]: dispositions};
    } else if (dispositionConfig.multiSelect === false) {

      // Accommodate reschedule disposition
      let rescheduleTimestamp, adjusterEmail;
      let updatedSelectedDisposition = selectedDisposition;

      if (selectedDisposition.includes("#")) {
        const [specialOptionType, specialOptionDispositionPrefix, specialOptionValue] = selectedDisposition.split("#");
        
        switch (specialOptionType) {
          case "sendEmail":
            adjusterEmail = specialOptionValue;
            updatedSelectedDisposition = specialOptionDispositionPrefix;
            break;
          case "rescheduleDate":
            rescheduleTimestamp = specialOptionValue;
            updatedSelectedDisposition = specialOptionDispositionPrefix;
            break;
          default:
            break;
        }

      }

      attributes = {
        [dispositionConfig.attributeName]: updatedSelectedDisposition,
        rescheduleTimestamp,
        adjusterEmail
      };

      // TODO Research the purpose of lexIssue and 'outbound'.
      // Left this commented because there is no disposition for RSA outbound
      // if (contactAttributes.lexIssue && contactAttributes.lexIssue.value === 'outbound') {
      //   attributes['previousDisposition'] = selectedDisposition;
      // }
    }

    const response = await updateContactAttributesFromSnapshot(submittingContact, attributes);
    if (response === 'Success') {
      setTimeout(() => selectedContactRefresh(submittingContactId), 500);
      setDispositionPaneVisible(false);
      return;
    }

    setLoading(false);
    setDispositionPaneVisible(false);
  };

  const renderButton = () => {
    if (selectedDisposition || (selectedMultiDisposition && selectedMultiDisposition.length)) {
      return (
        <div className="center">
          <Button disabled={loading} color="grey" className="dispositionButton" onClick={submitDisposition}>
            {config.translate('Submit', agentLang) || 'Submit'}
          </Button>
          {loading && <Loader active inline />}
        </div>
      );
    }
  };

  if (!selectedContact || !serviceConfig?.useDispositions) {
    return <div title="MissingDispositionPanel"></div>;
  }

  return (
    <Card title="DispositionPane" className="card-jmb">
      <Card.Content className={`header ${getBrandCode()}`}>
        <Card.Header textAlign="left">
          <Icon name="wpforms" />
          {config.translate('Disposition', agentLang) || 'Disposition'}
        </Card.Header>
      </Card.Content>
      <Card.Content>
        <Form>
          <Accordion id="dispositionAccordion" as={Menu} vertical fluid>
            {getMenuItems()}
          </Accordion>
          {getRequiredMessage()}
          {renderButton()}
        </Form>
      </Card.Content>
    </Card>
  );
};