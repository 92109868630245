import { Logger, LoggingService } from './LoggingService';
import { IDispositionConfig } from '../models/dispositionConfig';
import { IServiceConfig, IServiceConfigs } from '../models/serviceConfigs';
import { ITranslationConfigs } from '../models/translationConfig';
import { IDisclaimerConfig, IDisclaimerSets } from '../models/disclaimerConfig';
import { ITranslationPaneConfig } from '../models/translationPaneConfig';
import { ApplicationError } from './ApplicationErrorService';
import { merge } from 'lodash';

export interface QueryParameter {
  Name: string;
  Value: string;
}

export interface IApiConfig {
  baseURL: string;
  apiKey: string;
  resources: { [key: string]: string };
}
export interface IConfig {
  environment: string;
  buildTime: number;
  ccpUrl: string;
  ccpRegion: string;
  samlSsoUrl: string;
  sideMenuItems: { [key: string]: string }[];
  serviceConfigs: IServiceConfigs;
  disclaimerSets: IDisclaimerSets;
  translationPane: ITranslationPaneConfig;
  translations: ITranslationConfigs;

  // CCP_TOKEN attribute must be set to use
  api: IApiConfig;
}

export class ConfigurationService implements IConfig {
  private cfg: IConfig = null as any;
  private logger: Logger = new LoggingService().getLogger('ConfigurationService');

  public async load(override?: IConfig) {
    if (override) this.cfg = override;
    if (!this.cfg) {
      const response = await fetch('config.json');
      const config: IConfig = await response.json();
      
      if (config.environment === 'local') {
        this.cfg = config;
        this.logger.debug('Using local config')
        return;
      }

      const baseConfig = await this.fetchConfiguration(config.api, 'base');
      const overrideConfig = await this.fetchConfiguration(config.api, config.environment);

      this.logger.debug('Config Loaded');

      const baseAndApiConfig = merge(config, baseConfig);

      this.cfg = merge(baseAndApiConfig, overrideConfig);
      this.logger.debug('Config Loaded');
    }
  }

  private async fetchConfiguration(api: IApiConfig, environment: string): Promise<IConfig> {
    const response = await this.get(api.resources.config, api, [{ Name: 'environment', Value: environment }]);
    if (response.status !== 200) {
      const error = await response.json();
      this.logger.error('get configuration error:', error);
      return;
    } else {
      this.logger.debug(`get configuration success: ${response.status} ${response.statusText}`);
      return await response.json();
    }
  }

  private async get(url: string, api: IApiConfig, queryParams?: QueryParameter[]): Promise<Response> {
    const request: any = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': api.apiKey
      }
    };

    let reqUrl = `${api.baseURL}/${url}`;

    if (queryParams && queryParams.length > 0) {
      reqUrl = `${reqUrl}?${queryParams[0].Name}=${queryParams[0].Value}`;
      for (let i = 1; i < queryParams.length; i++) {
        reqUrl = `${reqUrl}&${queryParams[i].Name}=${queryParams[i].Value}`;
      }
    }

    try {
      return await fetch(reqUrl, request);
    } catch (error) {
      if (error instanceof Error) {
        throw new ApplicationError(0, error.message);
      } else {
        throw new ApplicationError(0, 'error with fetch operation');
      }
    }
  };

  get environment(): string {
    return this.cfg.environment;
  }

  get buildTime(): number {
    return this.cfg.buildTime;
  }

  get ccpUrl(): string {
    return this.cfg.ccpUrl;
  }

  get ccpRegion(): string {
    return this.cfg.ccpRegion;
  }

  get samlSsoUrl(): string {
    return this.cfg.samlSsoUrl;
  }

  get sideMenuItems(): { [key: string]: string }[] {
    return this.cfg.sideMenuItems;
  }

  get serviceConfigs(): IServiceConfigs {
    return this.cfg.serviceConfigs;
  }

  get disclaimerSets(): IDisclaimerSets {
    return this.cfg.disclaimerSets;
  }

  get api(): IApiConfig {
    return this.cfg.api;
  }

  get translationPane(): ITranslationPaneConfig {
    return this.cfg.translationPane;
  }

  get translations(): ITranslationConfigs {
    return this.cfg.translations;
  }

  public getServiceConfig(service: string): IServiceConfig | null {
    // this.logger.debug('getServiceConfig', {service});
    // console.log('ConfigurationService - getServiceConfig', service);
    if (!service) {
      this.logger.debug('getServiceConfig requires a service string value, returning null');
      return null;
    }

    if (!this.serviceConfigs[service]) {
      this.logger.error('Contact info service config does not include the provided service attribute value');
      return null;
    }

    const serviceConfig = this.serviceConfigs[service];

    if (!serviceConfig.contactInfoDisplaySet) {
      this.logger.error(
        'Call info display set property is either missing, or display set does not have a corresponding property'
      );
      return null;
    }

    if (!serviceConfig.dispositionSet && serviceConfig.useDispositions) {
      this.logger.error('Disposition set is missing from service config, returning null');
      return null;
    }

    return serviceConfig;
  }

  public dispositionConfigByService(service: string): IDispositionConfig | null {
    // Service value must be included in config file
    if (!this.cfg.serviceConfigs[service]) {
      this.logger.debug('Contact info service config does not include the provided service attribute value');
      return null;
    }

    const serviceConfig = this.cfg.serviceConfigs[service];
    if (!serviceConfig.dispositionSet) {
      this.logger.warn('There is no disposition set in the service configuration');
      return null;
    }

    return serviceConfig.dispositionSet;
  }

  public disclaimerConfigByService(service: string): IDisclaimerConfig | null {
    // Service value must be included in config file
    if (!this.cfg.serviceConfigs[service]) {
      this.logger.debug('Contact info service config does not include the provided service attribute value');
      return null;
    }

    const serviceConfig = this.cfg.serviceConfigs[service];
    // Service config must contain a valid disclaimerSet name

    if (!serviceConfig.disclaimerSet) {
      this.logger.warn('There is no disclaimer set in the service configuration');
      return null;
    }

    if (!this.cfg.disclaimerSets[serviceConfig.disclaimerSet]) {
      this.logger.warn('The disclaimer set referenced by the service config does not exist');
      return null;
    }

    return this.cfg.disclaimerSets[serviceConfig?.disclaimerSet];
  }

  public useCallLoggerByService(service: string): boolean {
    // Service value must be included in config file
    if (!this.cfg.serviceConfigs[service]) {
      this.logger.debug('Contact info service config does not include the provided service attribute value');
      return null;
    }

    const serviceConfig = this.cfg.serviceConfigs[service];

    return !!serviceConfig?.useCallLogger;
  }

  public enableTranslationByService(service: string | undefined): boolean {
    if (!service) {
      return false;
    }
    const services = this.translationPane.translationServices ?? [];
    const enabled = services.includes(service) || services.includes('*');
    // console.log('enableTranslationByService', {
    //   service,
    //   translationPane: this.translationPane,
    //   enabled,
    // });
    return enabled;
  }

  translate(key: string, lang: string): string {
    if (this.cfg.translations) {
      if (this.cfg.translations[key] && this.cfg.translations[key][lang]) {
        return this.cfg.translations[key][lang];
      }
    }
    return '';
  }

  isLocal(): boolean {
    return this.environment === 'local';
  }

  isV2(): boolean {
    return this.ccpUrl.includes('ccp-v2');
  }
}
