import { useState, useRef, useEffect, useContext, useCallback } from 'react';
import { ContactContext } from '../../contexts/contactContext';
import { RestApiContext } from '../../contexts/restApiContext';
import {
  Input,
  Container,
  Comment,
  Dimmer,
  Header,
  Icon,
  Dropdown,
  Menu,
  Transition
} from 'semantic-ui-react';
import { TranslationPaneComment } from './components/TranslationPaneComment';
import { ContactEventMessage } from './components/ContactEventMessage';
import { TranslationHelper } from './utils/TranslationHelper';
import useContactMessages from './hooks/useContactMessages';
import useLocalAgentSettings from './hooks/useLocalAgentSettings';
import { TranslationPaneSettings } from './components/TranslationPaneSettings';
import { AppContext } from '../../contexts/appContext';

const A_LONG_TIME_AGO = '2020-01-01T00:15:00.000Z';
const HTMLRegex = /(<([^>]+)>)/gm;

export const TranslationPane = (props: { contact: connect.Contact }) => {
  const [hasContact, setHasContact] = useState(false);
  const [currentMessage, setCurrentMessage] = useState('');
  const messageContainer = useRef(null);
  const { selectedContact, connect /*, agentLang */ } = useContext(ContactContext);
  const { translateText } = useContext(RestApiContext);
  const { config } = useContext(AppContext);
  const agentLanguageMessage = useRef('');
  const [contactSessionStore, createSession, pushMessage, setCustomerLanguage, deleteSession, setAgentLanguage] =
    useContactMessages();
  const [width, setWidth] = useState(0);
  const [agentSettingsModal, setAgentSettingsModal] = useState(false);
  const [localAgentSettings /*, setAgentDefaultLanguage, setAgentLanguages */] = useLocalAgentSettings();
  const [visible, setVisible] = useState(true);
  const contactSessionRef = useRef<unknown>();

  contactSessionRef.current = contactSessionStore

  const messages = contactSessionStore[selectedContact?.contactId]?.messages;
  useEffect(() => {
    (messageContainer.current as any).scrollTop = 0; // Scroll message container to bottom when new message comes in.
  }, [messages]);

  const translationPaneRef = useCallback((node) => {
    if (!node) return;
    const resizeObserver = new ResizeObserver(() => {
      setWidth(node.getBoundingClientRect().width);
    });
    resizeObserver.observe(node);
  }, []);

  useEffect(() => {
    const isChat = selectedContact?.getType() === 'chat';
    setHasContact(isChat);
  }, [selectedContact]);

  useEffect(() => {
    connect.contact((contact: connect.Contact) => {
      handleContact(contact);
    });
  }, []);

  const tryUnsetHasContact = (contact: connect.Contact) => {
    if (contact.getType() === 'chat') {
      if (contact.contactId === selectedContact?.contactId) {
        setHasContact(false);
      }
    }
  }

  const handleContact =
    useCallback(
      (contact: connect.Contact) => {
        if (contact?.getType() === 'chat') {
          if (contact.contactId && contactSessionStore[contact.contactId]) {
            subscribeToConection(contact);
            //  rehydrateChatTranscript(contact);
          } else {
            try {
              contact.onAccepted((contact) => {
                createSession(contact.contactId);

                const ctrLang = contact.getAttributes()?.language?.value;
                if (ctrLang) {
                  const awsLang = TranslationHelper.toAWSLanguageCode(ctrLang);
                  console.log({ setCustomerLanguage: { event: 'handleCallback::onAccepted', contactId: contact.contactId, awsLang: awsLang } });
                  setCustomerLanguage(contact.contactId, awsLang);
                }

                subscribeToConection(contact);
                rehydrateChatTranscript(contact);
              });
              contact.onDestroy((contact) => {
                deleteSession(contact.contactId);
                tryUnsetHasContact(contact); 
              });
            } catch (err) {
              console.error(err);
              tryUnsetHasContact(contact); 
            }
          }
        }
      }
      ,
      [localAgentSettings.defaultLanguage, contactSessionRef.current, contactSessionStore]
    );

  /**
   * Creates a message handler function for the provided contact.
   * @param contact
   * @returns
   */
  const makeMessageHandler = (contact: connect.Contact, agentDefaultLanguage: string) => {
    /**
     * Handles the provided message.
     * @param message
     */
    const _handleMessages = async (message, shouldPushMessage: boolean = true) => {
      const customerLanguage = contactSessionRef.current[contact?.contactId]?.customerLanguage;
      let receivedMesssage: any = {
        ...message
      };
      if (message?.ParticipantRole === 'CUSTOMER' && message?.Type === 'MESSAGE') {
        try {
          //If customer language doesn't match one of the agent languages or unknown customer language
          if (
            !customerLanguage ||
            !localAgentSettings.languages.find((agentLanguage) => {
              return agentLanguage === customerLanguage;
            })
          ) {
            console.log(
              `Agent Languages ${localAgentSettings.languages} don't overlap with customer language ${customerLanguage}`
            );
            let processedMessage = processMessage(message.Content);
            if (!config.enableTranslationByService(contact?.getAttributes().service.value)) {
              console.warn('Translation disabled in config');
            } else {
              const translation = await translateText(
                processedMessage,
                contactSessionStore[contact?.contactId]?.agentLanguage || agentDefaultLanguage,
                !config.translationPane?.disableAutoSwitchLanguage ? 'auto' : (customerLanguage || 'auto')
              );
              console.log(translation);
              if (!translation.TranslatedText) {
                throw new Error('Translation Not Received');
              }
              if (
                !localAgentSettings.languages.find((language) => {
                  return language === translation.SourceLanguageCode;
                })
              ) {
                receivedMesssage.TranslatedMessage = translation.TranslatedText.replaceAll(HTMLRegex, '');
                receivedMesssage.SourceLanguage = translation.SourceLanguageCode;
                setAgentLanguage(contact.contactId, agentDefaultLanguage);
              } else {
                setAgentLanguage(contact.contactId, translation.SourceLanguageCode);
              }
              console.log("Translation Config")
              console.log(config.translationPane);
              console.log('Customer Language: ' + customerLanguage)

              if (
                !customerLanguage ||
                (
                  !config.translationPane?.disableAutoSwitchLanguage &&
                  translation.TranslatedText.split(' ').length > 1) //To prevent single word responses from flipping the detected language. 
              ) {
                //jiggle dropdown for attention if language changes. 
                if (customerLanguage !== translation.SourceLanguageCode) {
                  setVisible((prev) => !prev); //animate the dropdown
                }
                console.log('Setting Customer Language')
                setCustomerLanguage(contact?.contactId, translation.SourceLanguageCode);
              }
            }
          }
        } catch (err) {
          console.error(err);
        }
      }
      if (message?.ParticipantRole === 'AGENT' && message?.Type === 'MESSAGE') {
        //Modify the received message with the untranslated message the agent sent, that way the translation pane show what the agent typed (untranslated).
        if (agentLanguageMessage.current) {
          receivedMesssage.Content = agentLanguageMessage.current;
        }
        agentLanguageMessage.current = null;
      }
      if (shouldPushMessage) {
        pushMessage(receivedMesssage, contact.contactId);
      }
      return receivedMesssage;
    };
    return _handleMessages;
  };

  const processMessage = (unprocessedMessage: string) => {
    let resp = unprocessedMessage;
    config.translationPane.noTranslatePatterns.forEach((obj) => {
      const pattern: RegExp = new RegExp(obj.pattern as string, 'gm');
      console.log('pattern');
      console.log(pattern);
      resp = resp.replaceAll(pattern, (match: string) => {
        if (obj.action === 'click-to-copy') {
          return `<a href="/" translate="no">${match}</a>`;
        } else {
          return `<span translate="no">${match}</span>`;
        }
      });
    });
    console.log('Processed Message:');
    console.log(resp);
    return `${resp}`;
  };

  //Subscribes the handleMessages function to onMessage
  const subscribeToConection = async (contact: connect.Contact) => {
    const messageHandler = makeMessageHandler(contact, localAgentSettings.defaultLanguage);
    try {
      const connection: any = await (contact.getAgentConnection() as connect.ChatConnection)?.getMediaController();
      connection.onMessage((message) => messageHandler(message.data));
    } catch (err) {
      console.error(err);
    }
  };

  /**
   * Grabs the chat transcript from Connect, processes and translates it as appropriate, and pushes the messages into the Translation Pane.
   * @param contact
   * @param nextToken
   */
  const rehydrateChatTranscript = async (contact: connect.Contact, nextToken?: string) => {
    console.log('rehydrateChatTranscript()');
    const messageHandler = makeMessageHandler(contact, localAgentSettings.defaultLanguage);
    try {
      const connection: any = await (contact.getAgentConnection() as connect.ChatConnection)?.getMediaController();
      let startPosition = { absoluteTime: A_LONG_TIME_AGO };
      const transcriptResponse = await connection.getTranscript({
        maxResults: 50,
        nextToken,
        scanDirection: 'FORWARD',
        startPosition: startPosition
      });
      console.log('Rehydrate');
      const { /*InitialContactId, */ NextToken, Transcript } = transcriptResponse.data;

      //Async message handling
      let messages = await Promise.all(
        Transcript.map(async (message) => {
          return messageHandler(message, false);
        })
      );

      // console.log('Messages:');
      // console.log(messages);

      messages.sort((a, b) => new Date(a.AbsoluteTime).getTime() - new Date(b.AbsoluteTime).getTime());
      // console.log('Sorted Messages:');
      // console.log(messages);

      messages.forEach((message: any) => {
        pushMessage(message, contact.contactId);
      });

      //Sequentially handle each message to avoid being shown out of order.
      //TODO Might be faster to send concurrently and then sort
      // await Transcript.reduce(async (previousPromise, message) => {
      //   await previousPromise;
      //   return messageHandler(message);
      // }, []);
      if (NextToken) {
        await rehydrateChatTranscript(contact, NextToken);
      }
    } catch (err) {
      console.error(err);
    }
  };

  const sendMessage = async (message: string) => {
    try {
      agentLanguageMessage.current = message;
      setCurrentMessage('');
      if (
        contactSessionStore[selectedContact?.contactId]?.customerLanguage &&
        config.enableTranslationByService(props.contact?.getAttributes().service.value)
      ) {
        const translation = await translateText(
          processMessage(message),
          contactSessionStore[selectedContact?.contactId].customerLanguage,
          contactSessionStore[selectedContact?.contactId].agentLanguage || localAgentSettings.defaultLanguage
        );
        message = translation.TranslatedText.replaceAll(HTMLRegex, '');
      }

      const connection = await (selectedContact.getAgentConnection() as connect.ChatConnection);
      const chatSession = (await connection?.getMediaController()) as any;
      if (connection) {
        await chatSession?.sendMessage({
          contentType: 'text/plain',
          message: message
        });
      }
    } catch (err) {
      alert('Could not send message');
      console.error(err);
    }
  };

  const safeGetContactService = (contact:  connect.Contact) => {
    try {
      if (contact) {
        return contact.getAttributes()?.service?.value;
      }
      return "";
    } catch (err) {
      console.error("Contact was accessed after destroy");
      return "";
    }
  };

  return (
    <Container
      ref={translationPaneRef}
      stretched
      style={{
        backgroundColor: 'white',
        borderRadius: 20,
        padding: 20,
        height: '100%',
        margin: 5,
        minHeight: 100
      }}
    >
      {/* Agent Language Settings Menu */}
      <TranslationPaneSettings
        isOpen={agentSettingsModal}
        onClose={() => setAgentSettingsModal(false)}
        onOpen={() => setAgentSettingsModal(true)}
      />

      <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
        <Menu attached="top" style={{ height: 40 }}>
          <Menu.Item fitted={'horizontally'}>
            <Transition animation={'shake'} duration={500} visible={visible}>
              <Dropdown
                style={{ border: 'none' }}
                value={hasContact ? contactSessionStore[selectedContact?.contactId]?.customerLanguage : null}
                onChange={(e, data) => {
                  setCustomerLanguage(selectedContact?.contactId, data?.value.toString());
                }}
                disabled={!hasContact || !config.enableTranslationByService(safeGetContactService(props.contact))}
                button
                inline
                className="icon"
                options={TranslationHelper.getDropdownLanguages()}
                search
                selection
                placeholder="Select Customer Language"
              />
            </Transition>
          </Menu.Item>

          <Menu.Item position="right" fitted={'horizontally'}>
            <Dropdown item icon="wrench" simple style={{ border: 'none', zIndex: 2002 }} direction="left">
              <Dropdown.Menu>
                <Dropdown.Item
                  icon="setting"
                  onClick={() => {
                    setAgentSettingsModal(true);
                  }}
                >
                  <Icon name="setting" />
                  Agent Settings
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Menu.Item>
        </Menu>
        <Dimmer.Dimmable
          as={Container}
          ref={messageContainer}
          style={{
            overflowY: 'auto',
            overfloxX: 'hidden',
            flexGrow: 1,
            paddingLeft: 40,
            paddingRight: 40,
            margin: 10,
            marginTop: 0,
            scrollBehavior: 'smooth',
            border: '1px solid #dededf',
            backgroundColor: '#fafafa',
            display: 'flex',
            flexDirection: 'column-reverse',
            scrollPaddingBottom: 200
          }}
          fluid
        >
          <Dimmer active={!hasContact}>
            <Header as="h2" icon inverted>
              <Icon name="chat" />
              Awaiting Chat Conversation
            </Header>
          </Dimmer>
          <ul>
            <Comment.Group style={{ width: '100%' }} size={width > 600 ? 'large' : width > 400 ? 'small' : 'tiny'}>
              {contactSessionStore[selectedContact?.contactId]?.messages &&
                contactSessionStore[selectedContact?.contactId]?.messages?.map((item, id) => {
                  if (item.Type === 'EVENT') {
                    return <ContactEventMessage item={item} />;
                  } else {
                    return (
                      <TranslationPaneComment
                        size={width > 600 ? 'large' : width > 400 ? 'small' : 'tiny'}
                        item={item}
                      />
                    );
                  }
                })}
            </Comment.Group>
          </ul>
        </Dimmer.Dimmable>
        <Input
          disabled={!selectedContact}
          fluid
          placeholder="Type a message"
          value={currentMessage}
          onKeyDown={(e) => {
            if (e.key === 'Enter' && currentMessage) {
              sendMessage(currentMessage);
            }
          }}
          onChange={(e) => setCurrentMessage(e.target.value)}
          action={{
            disabled: currentMessage ? false : true,
            color: 'teal',
            labelPosition: 'right',
            icon: 'chevron right',
            content: 'Send',
            onClick: () => {
              sendMessage(currentMessage);
            }
          }}
        />
      </div>
    </Container>
  );
};
