import {Logger, LoggingService} from './LoggingService';

export class BrandHelper {
  private logger: Logger;

  constructor() {
    this.logger = new LoggingService().getLogger('BrandHelperService');
  }

  public getBrandLetter(brandCode: string): string {
    let brandLetter = '?';
    switch (brandCode) {
      case 'alamo':
        brandLetter = 'A';
        break;
      case 'ean':
        brandLetter = 'T';
        break;
      case 'ehi':
        brandLetter = 'E';
        break;
      case 'enterprise':
        brandLetter = 'E';
        break;
      case 'ford':
        brandLetter = 'F';
        break;
      case 'gus':
        brandLetter = 'G';
        break;
      case 'lincoln':
        brandLetter = 'L';
        break;
      case 'national':
        brandLetter = 'N';
        break;
    }
    return brandLetter;
  }

  public getBrandCode(attributes: connect.AttributeDictionary): string {
    if (!attributes.brandCode || !attributes.brandCode.value) {
      this.logger.error('Contact does not contain a brandCode value, using EAN branding');
      return 'ean';
    } else if (!this.validateBrandCode(attributes.brandCode.value)) {
      this.logger.error('Contact contains an invalid brandCode value, using EAN branding');
    }
    return attributes.brandCode.value;
  }

  private validateBrandCode(brandCode: string): boolean {
    switch (brandCode) {
      case 'alamo':
      case 'ean':
      case 'ehi':
      case 'enterprise':
      case 'ford':
      case 'gus':
      case 'lincoln':
      case 'national':
        return true;
      default:
        return false;
    }
  }
}
