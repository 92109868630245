import { ErrorBoundary } from 'react-error-boundary';
import { Logger, LoggingService } from '../../services/LoggingService';
import { SelfAssignTask } from './SelfAssignTask';
import { ErrorSelfAssignTask } from './ErrorSelfAssignTask';

export const SafeSelfAssignTask = () => {

  const logger: Logger = new LoggingService().getLogger('SafeSelfAssignTask');
  const selfAssignTaskErrorHandler = (error: Error, info: { componentStack: string }) => {
    connect.getLog().error('Self Assign Task pane error caught by error boundary').withException(error);
    logger.error('Self Assign Task pane error caught by error boundary', { error });
  };

  return <ErrorBoundary FallbackComponent={ErrorSelfAssignTask} onError={selfAssignTaskErrorHandler}>
    <SelfAssignTask></SelfAssignTask>
  </ErrorBoundary>
}