import { ErrorBoundary } from 'react-error-boundary';
import { Logger, LoggingService } from '../../services/LoggingService';
import { DispositionPane } from './DispositionPane';
import { ErrorDispositionPane } from './ErrorDispositionPane';

export const SafeDispositionPanel = () => {
  
  const logger: Logger = new LoggingService().getLogger('SafeDispositionPanel');
  const dispositionPaneErrorHandler = (error: Error, info: { componentStack: string }) => {
    connect
      .getLog()
      .error('Disposition pane error caught by error boundary')
      .withException(error);
    logger.error('Disposition pane error caught by error boundary', { error });
  };
  return <ErrorBoundary FallbackComponent={ErrorDispositionPane} onError={dispositionPaneErrorHandler}>
    <DispositionPane></DispositionPane>
  </ErrorBoundary>
}