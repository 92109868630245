import 'amazon-connect-streams';
import 'amazon-connect-taskjs';
import 'amazon-connect-chatjs';
import { createContext, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { AppContext } from './appContext';
import { RestApiContext } from './restApiContext';
import { Logger, LoggingService } from '../services/LoggingService';
import { IRecentContact, RecentContacts } from '../services/RecentContactsService';
import { IContactSnapshot } from '../models/contactSnapshot';
import { CcpWrapperContext } from './ccpWrapperContext';
import { IDispositionConfig } from '../models/dispositionConfig';


export interface IContactContext {
  initialize: (container: HTMLElement) => void;
  initialized: boolean;
  terminate: () => void;
  agent: connect.Agent | undefined;
  agentUserName: string;
  agentName: string;
  agentLang: string;
  recentContacts: IRecentContact[];
  selectedContact: connect.Contact | null;
  selectedContactRefresh: (contactId: string) => void;
  connect;
}

export interface IContactContextProps {
  children: any;
  selectedContactOverride?: connect.Contact;
  agentOverride?: connect.Agent;
}

export const ContactContext = createContext<IContactContext>({} as IContactContext);
ContactContext.displayName = 'ContactContextProvider'; // used in dev tools

export function ContactContextProvider(props: IContactContextProps) {
  const [initialized, setInitialized] = useState(false);
  const [agent, setAgent] = useState<connect.Agent | undefined>(props.agentOverride || undefined);
  const [agentUserName, setAgentUserName] = useState<string>('');
  const [agentName, setAgentName] = useState<string>('');
  const [selectedContact, setSelectedContact] = useState<connect.Contact | null>(props.selectedContactOverride || null);
  const [recentContactsService] = useState(new RecentContacts());
  const [recentContacts, setRecentContacts] = useState<IRecentContact[]>([]);
  const [agentLang, setAgentLang] = useState<string>('en-US');
  const { config } = useContext(AppContext);
  const { updateContactAttributes, updateContactAttributesFromSnapshot } = useContext(RestApiContext);
  const {/*dispositionPaneVisible,*/ setDispositionPaneVisible, setLocationModalVisible, setBannerError } =
    useContext(CcpWrapperContext);

  // Using refs, state referenced from Connect Streams callbacks show stale values
  const agentRef = useRef(agent);
  const selectedContactRef = useRef(selectedContact); // Need to update this more consistently when set
  const agentNameRef = useRef(agentName);
  const agentUserNameRef = useRef(agentUserName);

  const logger: Logger = new LoggingService().getLogger('ContactContextProvider');

  const setNoSelectedContact = () => {
    console.log('setNoSelectedContact');
    setSelectedContact(null);
  }

  const tryUnsetSelectedContact = (contactId: string) => {
    if (selectedContact && selectedContact.getContactId() === contactId) {
      setNoSelectedContact();
    }
  }

  const checkAndSetDispositionDisplay = useCallback(
    (contact: connect.Contact): void => {
      logger.debug('checkAndSetDispositionDisplay', { contact });
      const currentAttributes = contact.getAttributes();
      const service = getService(currentAttributes);
      const serviceConfig = config.getServiceConfig(service);
      const dispositionConfig = config.dispositionConfigByService(service);
      const dispositionSet = isDispositionSet(dispositionConfig, currentAttributes);
      const contactState = contact.getState().type;
      const validStates = [connect.ContactStateType.CONNECTED, connect.ContactStateType.ENDED];

      setDispositionPaneVisible(
        !dispositionSet &&
        validStates.includes(contactState) &&
        serviceConfig &&
        serviceConfig.useDispositions === true &&
        contact.contactId === selectedContactRef.current.contactId
      );
    },
    [config, setDispositionPaneVisible]
  );

  const contactViewChange = useCallback(
    (event: { contactId: string }) => {
      logger.debug('contact focus change', { event });
      const currentContacts = agent.getContacts();
      if (currentContacts.length && event.contactId !== '') {
        const currentContact = currentContacts.filter(
          (contact: connect.Contact) => contact.contactId === event.contactId
        )[0];
        // logger.debug('callinfo: Contact toggled to:', {currentContact});
        setSelectedContact(currentContact);
        // Update Ref
        selectedContactRef.current = currentContact;
        checkAndSetDispositionDisplay(currentContact);
      } else if (event.contactId === '') {
        setNoSelectedContact();
      }
    },
    [agent, checkAndSetDispositionDisplay]
  );

  const handleAgentChange = useCallback(
    (agent: connect.Agent | undefined) => {
      // logger.debug('handleAgentChange', {agent});
      if (!agent) return;

      connect.core.onViewContact(contactViewChange);
    },
    [contactViewChange]
  );

  useEffect(() => {
    handleAgentChange(agent);
  }, [agent, handleAgentChange]);

  useEffect(() => {
    const contacts = recentContactsService.getRecentContacts();
    setRecentContacts(contacts);
  }, [recentContactsService]);

  useEffect(() => {
    // logger.debug('Updating refs');
    agentRef.current = agent;
    selectedContactRef.current = selectedContact;
    agentNameRef.current = agentName;
    agentUserNameRef.current = agentUserName;
  }, [agent, selectedContact, agentName, agentUserName]);

  const initialize = (container: HTMLElement): void => {
    if ((connect.core as any).initialized) {
      logger.debug('CCP already initialized');
      return;
    }
    logger.debug('init ccp called');

    const params: connect.InitCCPOptions = {
      ccpUrl: config.ccpUrl,
      loginPopup: true,
      loginPopupAutoClose: true,
      region: config.ccpRegion,
      softphone: {
        allowFramedSoftphone: true
      },

      // Configure SSO
      loginUrl: config.samlSsoUrl || undefined
    };

    (connect.getLog() as any).setEchoLevel('CRITICAL');

    connect.core.initCCP(container, params);

    // @ts-ignore: Property 'getEventBus' does not exist on type 'Core'.
    const eventBus = connect.core.getEventBus();

    // Terminate streams on logout in order to avoid automatic re-login (streams bug): https://github.com/amazon-connect/amazon-connect-streams/issues/468
    eventBus.subscribe(connect.EventType.TERMINATED, () => {
      logger.debug('logged out');
      terminate();
    });

    connect.contact(async (contact: connect.Contact) => {
      logger.debug('contact', { contactId: contact.getContactId() });
      const contactType = contact.getType();

      if (contactType === connect.ContactType.VOICE || contactType === connect.ContactType.QUEUE_CALLBACK) {
        setVoiceContact(contact);
      } else if (contactType === connect.ContactType.TASK) {
        setTaskContact(contact);
      } else if (contactType === connect.ContactType.CHAT) {
        setChatContact(contact);
      } else {
        logger.error('incompatible contact type', { contactType });
      }
    });

    connect.agent((agent: connect.Agent) => {
      const agentConfig = agent.getConfiguration();
      setAgent(() => agent);
      setAgentUserName(agentConfig.username);
      setAgentName(agentConfig.name);
      // @ts-ignore - Connect Streams has poor typing for this attribute
      setAgentLang(agentConfig.agentPreferences.LANGUAGE);

      agent.onError((error: any) => logger.error('onAgentError', { error }));
      agent.onSoftphoneError((error: any) => onSoftphoneError(error));
      agent.onRefresh((agent) => {
        // logger.debug('agent refreshed', agent.getConfiguration());
        // @ts-ignore - Connect Streams has poor typing for this attribute
        setAgentLang(agent.getConfiguration().agentPreferences.LANGUAGE);
        if (agent.getContacts().length === 0) {
          setNoSelectedContact();
        }
      });
      setInitialized(true);
    });
  };


  //
  // Voice Contact
  // Note: The `on*` callbacks are set for each contact type
  //
  // on* Callbacks:
  // - onIncoming
  // - onPending
  // - onConnecting
  // - onAccepted
  // - onMissed
  // - onConnected
  // - onEnded
  // - onDestroy
  // - onACW
  // - onError
  // - onRefresh
  //

  const setVoiceContact = (contact: connect.Contact | undefined): void => {
    if (!contact) return;

    contact.onConnecting((contact: connect.Contact) => {
      logger.debug('onConnecting', { contactId: contact.getContactId() });
      openScreenPop(contact);
    });

    contact.onConnected((contact: connect.Contact) => {
      logger.debug('onConnected', { contactId: contact.getContactId() });
      const currentAttributes = contact.getAttributes();
      const service = getService(currentAttributes);
      const dispositionConfig = config.dispositionConfigByService(service);
      // const dispositionSet = isDispositionSet(dispositionConfig, currentAttributes);
      // setDispositionPaneVisible(!dispositionSet);
      checkAndSetDispositionDisplay(contact);

      // We update contact attributes on connect for all calls except roadside outbound
      // TODO - Is there a way to make this config based? This is specific to roadside
      const lexIssueCheck = () => !currentAttributes.lexIssue || currentAttributes.lexIssue.value !== 'outbound';

      // Call History Table requires initial update to properly track contact
      if (service && lexIssueCheck())
        updateContactAttributes(contact, {
          brandCode: currentAttributes.brandCode?.value,
          agentUsername: agentUserNameRef.current,
          agentName: agentNameRef.current,
          GPBR: currentAttributes.GPBR?.value,
          rentalAgreementNumber: currentAttributes.rentalAgreementNumber?.value,
          otherInfo: currentAttributes.otherInfo ? currentAttributes.otherInfo?.value : '',
          memberId: currentAttributes.memberId ? currentAttributes.memberId?.value : '',
          issue: currentAttributes.lexIssue?.value,
          utterance: currentAttributes.lexUtterance?.value,
          addressFound: currentAttributes.addressFound?.value,
          addressLabel: currentAttributes.addressLabel?.value
        });
      setRecentContacts((prevRecentContacts) =>
        recentContactsService.updateRecentContacts([...prevRecentContacts], contact, dispositionConfig)
      );
    });

    contact.onEnded((contact: connect.Contact) => {
      logger.debug('onEnded', { contactId: contact.getContactId() });
      // onEnded fires twice - once when the call ends, and another time when ACW is done
      // The second firing returns a ContactSnapshot which is not included in streams typing
      // This check makes sure that we only update recent contacts for the first firing
      if (!Object.keys(contact).includes('contactData')) {
        setRecentContacts((prevRecentContacts) =>
          recentContactsService.updateEndedContact([...prevRecentContacts], contact as connect.Contact)
        );

        const currentAttributes = contact.getAttributes();

        if (currentAttributes.latitude) {
          // Redact ctr attributes for roadside (if they exist)
          const newAttr = {
            latitude: 'REDACTED',
            longitude: 'REDACTED',
            addressLabel: 'REDACTED',
            city: 'REDACTED',
            state: 'REDACTED',
            country: 'REDACTED',
            county: 'REDACTED',
            street: 'REDACTED',
            houseNumber: 'REDACTED',
            postalCode: 'REDACTED'
          }
          updateContactAttributes(selectedContact, newAttr);
          logger.debug('redacted attributes', { newAttr });
        }
      }
    });

    contact.onDestroy((contact: connect.Contact | IContactSnapshot) => {
      // logger.debug('onDestroy', {contactId: contact.getContactId()});
      // setDispositionPaneVisible(false);
      // checkAndSetDispositionDisplay(contact);

      logger.debug('ending ACW', { contact });

      tryUnsetSelectedContact(contact.getContactId());

      const currentAttributes = (contact as IContactSnapshot).contactData.attributes;
      if (currentAttributes.latitude) {
        // Redact ctr attributes for roadside (if they exist)
        const newAttr = {
          latitude: 'REDACTED',
          longitude: 'REDACTED',
          addressLabel: 'REDACTED',
          city: 'REDACTED',
          state: 'REDACTED',
          country: 'REDACTED',
          street: 'REDACTED',
          houseNumber: 'REDACTED',
          postalCode: 'REDACTED'
        };
        logger.debug('redacted attributes', { newAttr });
        updateContactAttributesFromSnapshot(contact as IContactSnapshot, newAttr);
      }
    });
  };

  //
  // Task Contact
  // Note: The `on*` callbacks are set for each contact type
  //

  const setTaskContact = (contact: connect.Contact | undefined): void => {
    if (!contact) return;
    contact.onConnecting((contact: connect.Contact) => {
      // logger.debug('onConnecting', {contactId: contact.getContactId()});
      openScreenPop(contact);
    });

    contact.onConnected((contact: connect.Contact) => {
      // logger.debug('onConnected', {contactId: contact.getContactId()});
      checkAndSetDispositionDisplay(contact);
    });

    contact.onDestroy((contact: connect.Contact) => {
      // logger.debug('onDestroy', {contactId: contact.getContactId()});
      tryUnsetSelectedContact(contact.getContactId());
    });

    contact.onError((contact: connect.Contact) => {
      logger.debug('onError', { contactId: contact.getContactId() });
    });
  };

  //
  // Chat Contact
  // Note: The `on*` callbacks are set for each contact type
  //

  const setChatContact = (contact: connect.Contact | undefined): void => {
    if (!contact) return;

    contact.onConnecting((contact: connect.Contact) => {
      logger.debug('onConnecting', { contactId: contact.getContactId() });
      openScreenPop(contact);
    });

    contact.onConnected((contact: connect.Contact) => {
      logger.debug('onConnected', { contactId: contact.getContactId() });
      const currentAttributes = contact.getAttributes();
      const service = getService(currentAttributes);
      const dispositionConfig = config.dispositionConfigByService(service);
      logger.debug('disposition', dispositionConfig);

      checkAndSetDispositionDisplay(contact);

      // TODO on connect, if there are call logger attr on the record
      // add them to the call record so they aren't wiped
      // e.g. they are from a transfer

      setRecentContacts((prevRecentContacts) =>
        recentContactsService.updateRecentContacts([...prevRecentContacts], contact, dispositionConfig)
      );
    });

    contact.onEnded((contact: connect.Contact | IContactSnapshot) => {
      // logger.debug('onEnded', {contactId: contact.getContactId()});
      // onEnded fires twice - once when the call ends, and another time when ACW is done
      // The second firing returns a ContactSnapshot which is not included in streams typing
      // This check makes sure that we only update recent contacts for the first firing
      if (!Object.keys(contact).includes('contactData')) {
        setRecentContacts((prevRecentContacts) =>
          recentContactsService.updateEndedContact([...prevRecentContacts], contact as connect.Contact)
        );
      }
    });

    contact.onDestroy((contact: connect.Contact) => {
      // logger.debug('onDestroy', {contactId: contact.getContactId()});
      // setDispositionPaneVisible(false);
      // checkAndSetDispositionDisplay(contact);
      tryUnsetSelectedContact(contact.getContactId());
      // setLocationModalVisible(false);
    });
  };

  //
  //
  //

  const getService = (attributes: connect.AttributeDictionary): string => {
    let service: string = '';

    if (attributes && attributes.service && attributes.service.value) {
      service = attributes.service.value;
    }

    return service;
  };

  const isDispositionSet = (
    dispositionConfig: IDispositionConfig | null,
    attributes: connect.AttributeDictionary
  ): boolean => {
    if (!dispositionConfig) {
      return false;
    }
    const dispositionAttributeName = dispositionConfig.attributeName;
    if (attributes[dispositionAttributeName] && attributes[dispositionAttributeName].value) {
      return true;
    }
    return false;
  };

  const link = (url: string, label: string) => {
    return <a href={url}>{label}</a>;
  };

  const onSoftphoneError = (error: connect.SoftphoneError): void => {
    logger.error('onSoftphoneError', { error });
    let errorComponent = null;
    if (error.errorType === 'unsupported_browser') {
      // TODO - add better error banner mechanism
      errorComponent = (
        <p>
          Only the latest 3 versions of Chrome or Firefox are supported. Upgrade your browser to resolve this error.
        </p>
      );
    } else if (error.errorType === 'microphone_not_shared') {
      // TODO - add better error banner mechanism
      errorComponent = (
        <div>
          <p>The microphone is not accessible, please reload the page and allow microphone access when prompted.</p>
          <p>
            Lastly, you can try using {link('https://www.mozilla.org/en-US/firefox/new/', 'Firefox')} or{' '}
            {link('https://www.google.com/chrome/', 'Chrome')}.
          </p>
        </div>
      );
    } else if (error.errorType === 'user_busy_error') {
      // Dont display busy error
    } else {
      // TODO - add error banner mechanism
      errorComponent = <p>{error.errorType}</p>;
    }
    setBannerError(errorComponent);
  };

  const openScreenPop = (contact: connect.Contact | undefined) => {
    // Opens a window with a url if attribute is available. Should this live here?
    if (!contact) return;
    try {
      const attributes = contact.getAttributes();
      if (attributes.screenPopUrl) {
        window.open(attributes.screenPopUrl.value, 'ScreenPop');
      } else {
        logger.debug('no screen pop url in attributes');
      }
    } catch (err) {
      logger.error('openScreenPop', { contact, err });
    }
  };

  const selectedContactRefresh = (contactId: string) => {
    logger.debug(`contact has updated:`, { contactId });
    const currentContacts = agent.getContacts();
    if (currentContacts.length && contactId !== '' && contactId === selectedContact.getContactId()) {
      try {
        const currentContact = currentContacts.filter((contact: connect.Contact) => contact.contactId === contactId)[0];
        setSelectedContact(currentContact);
        setRecentContacts((prevRecentContacts) =>
          recentContactsService.updateCurrentContact([...prevRecentContacts], currentContact as connect.Contact)
        );
      } catch (error) {
        logger.error(error);
      }
    }
  };

  const terminate = () => connect.core.terminate();

  const returnAgent = useMemo(
    () => ({
      agent,
      agentUserName,
      agentName,
      agentLang
    }),
    [agent, agentUserName, agentName, agentLang]
  );

  const context = {
    initialize,
    initialized,
    terminate,
    recentContacts,
    ...returnAgent,
    selectedContact,
    selectedContactRefresh,
    connect
  };

  return <ContactContext.Provider value={context}>{props.children}</ContactContext.Provider>;
}
