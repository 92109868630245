import React, { useContext, useEffect, useState } from 'react';
import { Card } from 'semantic-ui-react';
import { AppContext } from '../../contexts/appContext';
import { ContactContext } from '../../contexts/contactContext';
import { Logger, LoggingService } from '../../services/LoggingService';
import { IDisclaimerConfig } from '../../models/disclaimerConfig';
import ReactMarkdown from 'react-markdown';

export const DisclaimerPanel = () => {
  const { config } = useContext(AppContext);
  const { selectedContact } = useContext(ContactContext);
  const [contactAttributes, setContactAttributes] = useState(selectedContact && selectedContact?.getAttributes());
  const [disclaimerConfig, setDisclaimerConfig] = useState<IDisclaimerConfig | null>(null);

  useEffect(() => {
    if (!selectedContact) {
      return null;
    }
    setContactAttributes(selectedContact.getAttributes());
  }, [selectedContact]);
  const logger: Logger = new LoggingService().getLogger('DisclaimerPanel');

  useEffect(() => {
    console.log(contactAttributes)
    if (!contactAttributes || !contactAttributes?.service || !contactAttributes?.service?.value) {
      logger.debug('no service attribute in contact attributes');
    } else {
      const service = contactAttributes.service.value;
      const discSet = config.disclaimerConfigByService(service);
      setDisclaimerConfig(discSet);
    }
  }, [config, contactAttributes]);

  if (!disclaimerConfig?.content) return (<div title="MissingDisclaimerPanel"></div>);

  return (
    <Card title="DisclaimerPanel" fluid={true}>
      <Card.Content>
        <ReactMarkdown>{disclaimerConfig?.content}</ReactMarkdown>
      </Card.Content>
    </Card>
  );
};