import React from 'react';
import dayjs from 'dayjs';
import {ListItem, List} from 'semantic-ui-react';
import {Logger, LoggingService} from '../../services/LoggingService';
import {IDateAttributeConfig} from '../../models/contactInfoDisplaySets';
import {ConfigurationService} from '../../services/ConfigurationService';

export const DateAttribute = (
  dateAttributeConfig: IDateAttributeConfig,
  dateAttributeValue: string,
  config: ConfigurationService,
  agentLang: string
) => {
  const logger: Logger = new LoggingService().getLogger('DateAttribute');

  if (!dateAttributeValue && dateAttributeConfig.hideIfFalsy === true) {
    logger.debug('No date value, hiding falsy value');
    return null;
  }
  if (!dateAttributeConfig.label) {
    logger.error('no attribute label provided in dateAttributeConfig, skipping component', {...dateAttributeConfig});
    return null;
  }

  if (!dateAttributeConfig.attributeKey) {
    logger.error('no attribute key provided in dateAttributeConfig, skipping component', {...dateAttributeConfig});
    return null;
  }

  if (!dateAttributeConfig.dateFormat) {
    logger.error('no dateFormat attribute provided in dateATtributeConfig, skipping component', {
      ...dateAttributeConfig
    });
  }

  const formatDate = () => {
    try {
      const dateString = dayjs(dateAttributeValue).format(dateAttributeConfig.dateFormat);
      return dateString;
    } catch {
      logger.error('Error formatting date string based on dateAttributeValue and dateFormat', {
        ...dateAttributeConfig,
        value: dateAttributeValue
      });
      return '';
    }
  };

  return (
    <ListItem key={dateAttributeConfig.attributeKey}>
      <List.Header>
        <strong>{config.translate(dateAttributeConfig.label, agentLang) || dateAttributeConfig.label}</strong>
      </List.Header>
      <List.Description>{formatDate()}</List.Description>
    </ListItem>
  );
};
