import {useState, useEffect} from 'react';

const LOCAL_STORAGE_KEY = 'localAgentSettings';

interface LocalAgentSettings {
  defaultLanguage: string;
  languages: string[];
}

//default to english only
let globalLocalAgentSettings: LocalAgentSettings = JSON.parse(window.localStorage.getItem(LOCAL_STORAGE_KEY)) || {
  defaultLanguage: 'en',
  languages: ['en']
};
let listeners: any[] = [];

/**
 * Holds browser settings related to the agent's preferences. 
 *  Currently supports: 
 * - Language Settings
 * @returns 
 */
const useLocalAgentSettings: () => [
  LocalAgentSettings,
  (language: string) => void,
  (languages: string[]) => void
] = () => {
  const setGlobalConfig = useState()[1];

  /**
   * Sets the agent's default language.
   * @param language 
   */
  const setAgentDefaultLanguage = (language: string) => {
    globalLocalAgentSettings.defaultLanguage = language;
    console.log(globalLocalAgentSettings);
    window.localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(globalLocalAgentSettings));
    for (const listener of listeners) {
      listener({...globalLocalAgentSettings});
    }
  };

  /**
   * Stores array of the agent's languages.
   * @param languages 
   */
  const setAgentLanguages = (languages: string[]) => {
    globalLocalAgentSettings.languages = languages;
    console.log(globalLocalAgentSettings);
    window.localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(globalLocalAgentSettings));
    for (const listener of listeners) {
      listener({...globalLocalAgentSettings});
    }
  };

  useEffect(() => {
    listeners.push(setGlobalConfig);
    return () => {
      listeners = listeners.filter((li) => li !== setGlobalConfig);
    };
  }, [setGlobalConfig]);

  return [globalLocalAgentSettings, setAgentDefaultLanguage, setAgentLanguages];
};

export default useLocalAgentSettings;
