// import 'amazon-connect-streams';
import React, {Fragment} from 'react';
import dayjs from 'dayjs';
import {Button, Feed, Icon} from 'semantic-ui-react';
import {IRecentContact} from '../../services/RecentContactsService';
import {BrandHelper} from '../../services/BrandHelperService';
import {ConfigurationService} from '../../services/ConfigurationService';

const brandHelperService = new BrandHelper();

export const RecentContact = (contact: IRecentContact, config: ConfigurationService, agentLang: string) => {
  const clickToCall = (phoneNumber: string) => {
    const endpoint = connect.Endpoint.byPhoneNumber(phoneNumber);
    connect.agent((agent: connect.Agent) => {
      agent.connect(endpoint, {
        success: () => {},
        failure: () => {}
      });
    });
  };

  const formatTime = (ms: number): string => {
    let seconds: any = Math.floor((ms / 1000) % 60);
    let minutes: any = Math.floor((ms / 1000 / 60) % 60);
    let hours: any = Math.floor((ms / 1000 / 3600) % 24);

    hours = hours < 10 ? '0' + hours : hours;
    minutes = minutes < 10 ? '0' + minutes : minutes;
    seconds = seconds < 10 ? '0' + seconds : seconds;
    return hours + ':' + minutes + ':' + seconds;
  };

  const formatDate = (ms: number): string => {
    // TODO: Update dayjs locale based on agent language
    return dayjs(ms).format('MMM DD - hh:mm A');
  };

  const formatDetails = (): JSX.Element => {
    const issue = contact.issue ? contact.issue : '';
    const multiSelect = contact.multiSelectDisposition ? contact.multiSelectDisposition : false;
    let disposition: string = '';

    if (multiSelect === true && contact.disposition) {
      const dispositionArray = contact.disposition.split(',');
      for (let i = 0; i < dispositionArray.length; i++) {
        dispositionArray[i] = config.translate(dispositionArray[i], agentLang) || dispositionArray[i];
      }
      disposition = dispositionArray.toString();
    } else if (!multiSelect && contact.disposition) {
      disposition = config.translate(contact.disposition, agentLang) || contact.disposition;
    }

    return (
      <Fragment>
        <b>{`${config.translate('Issue', agentLang) || 'Issue'}: `}</b>
        {issue} | <b>{`${config.translate('Disposition', agentLang) || 'Disposition'}: `}</b> {disposition}
      </Fragment>
    );
  };

  return (
    <Feed key={contact.contactId}>
      <Feed.Event>
        <Feed.Label className="callIconBox">
          <Icon onClick={() => clickToCall(contact.phoneNumber)} name="call" className="call-icon" />
        </Feed.Label>
        <Feed.Content>
          <Feed.Summary>
            <Feed.User>{contact.GPBR}</Feed.User>&nbsp;
            {contact.phoneNumber}
          </Feed.Summary>
          <Feed.Meta>
            <Feed.Like>
              {formatDate(contact.startTimestamp)} &nbsp;
              <Icon name="clock outline" />
              &nbsp;
              {formatTime((contact.endTimestamp || contact.startTimestamp) - contact.startTimestamp)}{' '}
            </Feed.Like>
          </Feed.Meta>
          <Feed.Meta>
            <Feed.Like>{formatDetails()}</Feed.Like>
          </Feed.Meta>
        </Feed.Content>
        <Feed.Label>
          <Button
            circular
            style={{width: '30px', paddingLeft: '5px', paddingRight: '5px'}}
            className={contact.brandCode}
          >
            {brandHelperService.getBrandLetter(contact.brandCode)}
          </Button>
        </Feed.Label>
      </Feed.Event>
    </Feed>
  );
};
