import React from 'react';
import { Icon, Menu, SemanticICONS } from 'semantic-ui-react';
import { ConfigurationService } from '../services/ConfigurationService';
import { SafeSelfAssignTask } from './SelfAssignTask/SafeSelfAssignTask';
import { Logger, LoggingService } from '../services/LoggingService';
import { SafeRecentContacts } from './RecentContacts/SafeRecentContacts';

export const SideMenuPane = (pane: { [key: string]: string }, config: ConfigurationService, agentLang: string) => {
  const logger: Logger = new LoggingService().getLogger('SideMenuPane');

  const iconNames: { [key: string]: SemanticICONS } = {
    recentContacts: 'history',
    selfAssignTask: 'address card'
  };

  const menuItem = (
    <Menu.Item key={pane.item}>
      <div className="cornerLeft"></div>
      <Icon name={iconNames[pane.item]} />
    </Menu.Item>
  );


  const panes = {
    recentContacts: (SafeRecentContacts({ agentLang, config })),
    selfAssignTask: (SafeSelfAssignTask())
  };

  return {
    menuItem,
    render: () => panes[pane.item]
  };
};
