import {createContext, useEffect, useState} from 'react';
import {ConfigurationService, IConfig} from '../services/ConfigurationService';
import {Logger, LoggingService} from '../services/LoggingService';

export interface IAppContext {
  config: ConfigurationService;
}
export const AppContext = createContext<IAppContext>({} as IAppContext);

export function AppContextProvider(props: {children: any, configOverride?: IConfig}) {
  const [status, setStatus] = useState('Loading');
  const [config] = useState(new ConfigurationService());

  const logger: Logger = new LoggingService().getLogger('AppContextProvider');

  useEffect(() => {
    (async () => {
      try {
        await config.load(props?.configOverride);
        setStatus('Ready');
      } catch (error) {
        if (error instanceof Error) {
          logger.error(`Configuration Failed: ${error.message}`, error);
          setStatus(error.message);
        } else {
          logger.error('Error loading config', error);
          setStatus('Unknown error loading config');
        }
      }
    })();
  }, [config, logger]);

  if (status !== 'Ready') {
    return (
      <div>
        <p>{status}</p>
      </div>
    );
  }

  const context: IAppContext = {
    config
  };

  return <AppContext.Provider value={context}>{props.children}</AppContext.Provider>;
}
