import React from 'react';
import {Header} from 'semantic-ui-react';

export const LogoOnlyHeader = (brandCode: string) => {
  const getBrandMaxHeight = () => {
    switch (brandCode) {
      case 'ean':
      case 'ford':
      case 'gus':
      case 'lincoln':
        return '60px';
      default:
        // all other brandCodes
        return '30px';
    }
  };
  return (
    <Header className={brandCode} as="h2">
      <Header.Content style={{width: '100%'}}>
        <img
          src={`${brandCode}.png`}
          style={{
            maxHeight: getBrandMaxHeight(),
            display: 'block',
            margin: 'auto'
          }}
          alt={`${brandCode} logo`}
        />
        {brandCode !== 'ean' || brandCode as unknown !== 'gus' && <Header.Subheader className={brandCode}>EHI</Header.Subheader>}
      </Header.Content>
    </Header>
  );
};
