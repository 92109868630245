import React, { useContext } from 'react';
import { Tab } from 'semantic-ui-react';
import { RecentContact } from './RecentContact';
import { ContactContext } from '../../contexts/contactContext';
import { CcpWrapperContext } from '../../contexts/ccpWrapperContext';
import { ConfigurationService } from '../../services/ConfigurationService';

export interface IRecentContactsProps {
  config: ConfigurationService;
  agentLang: string;
}

export const RecentContacts = (props: IRecentContactsProps) => {
  const { recentContacts } = useContext(ContactContext);
  const { ccpVisible } = useContext(CcpWrapperContext);

  let recentContactList: JSX.Element[] | null = null;
  if (recentContacts) {
    recentContactList = recentContacts.map((contact) => {
      return RecentContact(contact, props.config, props.agentLang);
    });
  }

  return <Tab.Pane attached={false}> {ccpVisible && recentContactList}</Tab.Pane>;
};
