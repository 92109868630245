import { List, ListItem } from 'semantic-ui-react';
import { IHtmlAttributeConfig } from '../../models/contactInfoDisplaySets';
import { ConfigurationService } from '../../services/ConfigurationService';
import { escape } from 'html-escaper';

export const HtmlAttribute = (
  attributeConfig: IHtmlAttributeConfig,
  contactAttributes: connect.AttributeDictionary,
  _config: ConfigurationService,
  _agentLang: string
) => {
  const replaceVars = (html: string) => {
    return (html ?? '').replace(/\${((.*?).*?)}/g, (match, p1) => {
      // return nested json value of vars using p1 as path (e.g. something.foo)
      const value = p1.split('.').reduce(function (obj: any, key: any) {
        return obj && escape(obj[key]?.value ?? '');
      }, contactAttributes)
      return value;
    });
  }

  if (attributeConfig.label?.trim().length > 0) {
    const label = replaceVars(attributeConfig.label);
    const output = replaceVars(attributeConfig.html);
    return (
      <ListItem key={`${attributeConfig.attributeKey}`}>
        <List.Header>
          <div dangerouslySetInnerHTML={{ __html: label }}></div>
        </List.Header>
        <List.Description>
          <div dangerouslySetInnerHTML={{ __html: output }}></div>
        </List.Description>
      </ListItem>
    );
  } else {
    const output = replaceVars(attributeConfig.html);
    return (
      <div dangerouslySetInnerHTML={{ __html: output }}></div>
    );
  };
};
