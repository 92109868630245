import {createContext, Dispatch, SetStateAction, useState} from 'react';

export interface ICcpContext {
  ccpVisible: boolean;
  setCcpVisible: (next: boolean) => void;
  sideMenuActiveIndex: number;
  setSideMenuActiveIndex: (index: number) => void;
  tabTitle: string;
  setTabTitle: (title: string) => void;
  dispositionPaneVisible: boolean;
  setDispositionPaneVisible: Dispatch<SetStateAction<boolean>>;
  locationModalVisible: boolean;
  setLocationModalVisible: Dispatch<SetStateAction<boolean>>;
  bannerError: JSX.Element;
  setBannerError: React.Dispatch<JSX.Element | null>;
}

export const CcpWrapperContext = createContext<ICcpContext>({} as ICcpContext);

export function CcpWrapperContextProvider(props: {children: any}) {
  const [ccpVisible, setCcpVisible] = useState(true);
  const [sideMenuActiveIndex, setSideMenuActiveIndex] = useState(0);
  const [tabTitle, setTabTitle] = useState('Call History');
  const [dispositionPaneVisible, setDispositionPaneVisible] = useState(false);
  const [locationModalVisible, setLocationModalVisible] = useState(false);
  const [bannerError, setBannerError] = useState(null);

  const context: ICcpContext = {
    ccpVisible,
    setCcpVisible,
    sideMenuActiveIndex,
    setSideMenuActiveIndex,
    tabTitle,
    setTabTitle,
    dispositionPaneVisible,
    setDispositionPaneVisible,
    locationModalVisible,
    setLocationModalVisible,
    bannerError,
    setBannerError
  };

  return <CcpWrapperContext.Provider value={context}>{props.children}</CcpWrapperContext.Provider>;
}
