import React, { useContext } from 'react';
import dayjs from 'dayjs';

import { AppContext } from '../contexts/appContext';

export const BuildLabel = () => {
  let {
    config: { buildTime, environment }
  } = useContext(AppContext);
  // const envClass = ['production', 'prod'].includes(environment) ? 'buildLabelInvisible' : 'buildLabel';
  const envClass = 'buildLabel';

  // Format date as seconds if milliseconds are provided
  if (buildTime > 10000000000) {
    buildTime = buildTime / 1000;
  }

  const buildTimeStringUTC = dayjs.unix(buildTime).toISOString();

  return (
    <div className={envClass}>
      {environment}
      <br />
      Build Time: {buildTimeStringUTC}
    </div>
  );
};
