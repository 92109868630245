import React, {Fragment, useCallback, useContext, useEffect, useRef, useState} from 'react';
import {Button, Card, Icon, List} from 'semantic-ui-react';
import {AppContext} from '../../contexts/appContext';
import {ContactContext} from '../../contexts/contactContext';
import {CcpWrapperContext} from '../../contexts/ccpWrapperContext';
import {RestApiContext} from '../../contexts/restApiContext';
import {Logger, LoggingService} from '../../services/LoggingService';
import {BrandHelper} from '../../services/BrandHelperService';
import {IServiceConfig} from '../../models/serviceConfigs';
import {LogoOnlyHeader} from './LogoOnlyHeader';
import {LogoWithVehicleIconHeader} from './LogoWithVehicleIconHeader';
import {RoadsideHeader} from './RoadsideHeader';
import {
  IDateAttributeConfig,
  IStandardAttributeConfig,
  ICustomAttributeInputConfig,
  ICustomAttributeBooleanConfig,
  ICustomAttributeSelectConfig,
  ITableAttributeConfig,
  VisibleAttributes,
  IContactInfoDisplaySet,
  ILinkAttributeConfig,
  IPrefixedAttributes,
  IHtmlAttributeConfig
} from '../../models/contactInfoDisplaySets';
import {StandardAttribute} from './StandardAttribute';
import {DispositionAttribute} from '../DispositionPanel/DispositionAttribute';
import {CustomAttributeInput} from './CustomAttributeInput';
import {CustomAttributeBoolean} from './CustomAttributeBoolean';
import {DateAttribute} from './DateAttribute';
import {TableAttribute} from './TableAttribute';
import {GetLocationModal} from './GetLocationModal';
import {FordLincolnHeader} from './FordLincolnHeader';
import {LinkAttribute} from './LinkAttribute';
import {CustomAttributeSelect} from './CustomAttributeSelect';
import {PrefixedAttributes} from './PrefixedAttributes';
import {HtmlAttribute} from './HtmlAttribute';
import {IAttributes} from '../../models/attributes';

type AttributeConfigTypes =
  | IStandardAttributeConfig
  | ICustomAttributeInputConfig
  | ICustomAttributeBooleanConfig
  | ICustomAttributeSelectConfig
  | IPrefixedAttributes
  | IDateAttributeConfig
  | ITableAttributeConfig
  | ILinkAttributeConfig
  | IHtmlAttributeConfig;

export interface ICustomAttributesState {
  [contactId: string]: IContactCustomAttributes;
}

export interface IContactCustomAttributes {
  [key: string]: IContactCustomAttribute;
}

export interface IContactCustomAttribute {
  value: string;
  inputValue?: string;
  filteredOptions?: string[];
}

export const convertToIAttributes = (customAttributes: IContactCustomAttributes): IAttributes => {
  const attributes: IAttributes = {};

  for (const [key, attribute] of Object.entries(customAttributes)) {
    attributes[key] = attribute.value;
  }

  return attributes;
};

export const ContactInfo = () => {
  const {config} = useContext(AppContext);
  const {updateContactAttributes} = useContext(RestApiContext);
  const {selectedContact, selectedContactRefresh, agentLang} = useContext(ContactContext);
  const {dispositionPaneVisible, setDispositionPaneVisible} = useContext(CcpWrapperContext);
  const [contactAttributes, setContactAttributes] = useState(selectedContact.getAttributes());
  const [customAttributes, setCustomAttributes] = useState<ICustomAttributesState>({});
  const [serviceConfig, setServiceConfig] = useState<IServiceConfig | null>(null);
  const [contactInfoDisplaySet, setContactInfoDisplaySet] = useState<IContactInfoDisplaySet | null>(null);
  const [brandHelper] = useState(new BrandHelper());
  const [loading, setLoading] = useState<boolean>(false);

  const customAttributesRef = useRef(customAttributes);

  const logger: Logger = new LoggingService().getLogger('ContactInfo');

  useEffect(() => {
    customAttributesRef.current = customAttributes;
  }, [customAttributes]);

  const syncCustomAttributes = useCallback(
    (attributes: connect.AttributeDictionary) => {
      console.log('SyncCustomAttr:', {selectedContact}, {attributes});
      if (serviceConfig === null) {
        return null;
      }

      if (!contactInfoDisplaySet) {
        return null;
      }

      const visibleAttributes = contactInfoDisplaySet.visibleAttributes;
      if (!visibleAttributes) {
        return null;
      }
      const customAttributeObj = {};
      customAttributeObj[selectedContact.contactId] = {};

      for (const item of visibleAttributes) {
        const attributeKey = item.attributeKey;
        if (item.displayType === 'customAttributeInput' && customAttributesRef.current[selectedContact.contactId]) {
          if (customAttributesRef.current[selectedContact.contactId][attributeKey] && attributes[attributeKey]) {
            if (
              attributes[attributeKey].value !==
              customAttributesRef.current[selectedContact.contactId][attributeKey].value
            ) {
              return;
            } else {
              return;
            }
          } else {
            if (attributes[attributeKey]) {
              customAttributeObj[selectedContact.contactId][attributeKey] = {value: attributes[attributeKey].value};
            }
          }
        }
      }

      setCustomAttributes((prev) => ({...prev, ...customAttributeObj}));
    },
    [selectedContact, serviceConfig, contactInfoDisplaySet]
  );

  useEffect(() => {
    setContactAttributes(selectedContact.getAttributes());
    syncCustomAttributes(selectedContact.getAttributes());
  }, [selectedContact, dispositionPaneVisible, syncCustomAttributes]);

  useEffect(() => {
    if (!contactAttributes.service || !contactAttributes.service.value) {
      logger.debug('no service attribute in contact attributes');
      setServiceConfig(null);
    }
    const service = contactAttributes.service.value;
    const svcConfig = config.getServiceConfig(service);
    setServiceConfig(svcConfig);

    if (svcConfig && !svcConfig.contactInfoDisplaySet) {
      logger.debug('no contactInfoDisplaySet in service config');
      setContactInfoDisplaySet(null);
    }
    const displaySet = svcConfig.contactInfoDisplaySet;
    setContactInfoDisplaySet(displaySet);
  }, [config, contactAttributes, logger]);

  const contactInfoHeader = () => {
    if (serviceConfig === null) {
      return null;
    }

    if (!contactInfoDisplaySet || !contactInfoDisplaySet.header) {
      logger.error('Selected call info display set does not contain a header property, returning null');
      return null;
    }

    const headerType = contactInfoDisplaySet.header;
    const brand = brandHelper.getBrandCode(contactAttributes);
    let content;
    switch (headerType) {
      case 'noBrand':
        return <></>;
      case 'logoOnly':
        content = LogoOnlyHeader(brand);
        break;
      case 'logoWithVehicleIcon':
        content = LogoWithVehicleIconHeader(brand);
        break;
      case 'roadside':
        // return RoadsideHeader(brand, contactAttributesRef.current, config, agentLang, setLocationModalVisible);
        content = <RoadsideHeader brandCode={brand} />;
        break;
      case 'fordLincoln':
        // return FordLincolnHeader(brand, contactAttributesRef.current, config, agentLang);
        content = <FordLincolnHeader brand={brand} />;
        break;
      default:
        logger.error('Unsupported contact info header type in config, using default header');
        content = LogoOnlyHeader(brand);
        break;
    }
    return (
      <Card className="title" style={{marginBottom: '0px'}}>
        <Card.Content>{content}</Card.Content>
      </Card>
    );
  };

  const getLocationModal = () => {
    if (serviceConfig === null) {
      return null;
    }

    // Service config must contain a locationService property
    if (contactInfoDisplaySet.locationService === true) {
      return <GetLocationModal />;
    } else {
      return null;
    }
  };

  const checkAttributeConditions = (attributeConfig: AttributeConfigTypes) => {
    if (!attributeConfig.attributeConditions) {
      return true;
    }

    for (let condition of attributeConfig.attributeConditions) {
      if (condition.conditionType === 'or') {
        for (let value of condition.valuesToCheck) {
          if (contactAttributes[value.attribute] && contactAttributes[value.attribute].value === value.value) {
            return true;
          }
        }
        return false;
      } else if (condition.conditionType === 'and') {
        for (let value of condition.valuesToCheck) {
          if (!contactAttributes[value.attribute]) {
            return false;
          } else if (contactAttributes[value.attribute] && contactAttributes[value.attribute].value !== value.value) {
            return false;
          }
        }
        return true;
      }
    }
  };

  const contactInfoComponents = (): JSX.Element[] => {
    if (serviceConfig === null) {
      return null;
    }

    if (contactInfoDisplaySet === null) {
      return null;
    }

    const allVisibleAttributes: VisibleAttributes = contactInfoDisplaySet.visibleAttributes;
    const visibleAttributes: VisibleAttributes = allVisibleAttributes.filter(
      (visibleAttribute) => visibleAttribute.displayType === 'disposition' || checkAttributeConditions(visibleAttribute)
    );
    const componentsList = visibleAttributes.map((attributeConfig) => {
      if (!attributeConfig.displayType) {
        logger.error('displayType listed on visible attribute, returning null value', {...attributeConfig});
        return null;
      }
      switch (attributeConfig.displayType) {
        case 'standard':
        case 'standardWithLookup':
          return StandardAttribute(
            attributeConfig,
            getAttributeValue(attributeConfig.attributeKey),
            config,
            agentLang,
            contactAttributes
          );
        case 'standardContactId':
          return StandardAttribute(
            attributeConfig,
            selectedContact.getContactId(),
            config,
            agentLang,
            contactAttributes,
            true
          );
        case 'disposition':
          return DispositionAttribute(
            attributeConfig,
            getDispositionValue(attributeConfig.attributeKey),
            dispositionPaneVisible,
            setDispositionPaneVisible,
            config,
            agentLang
          );
        case 'customAttributeInput':
          return CustomAttributeInput(
            attributeConfig,
            customAttributes,
            setCustomAttributes,
            config,
            agentLang,
            selectedContact.contactId
          );
        case 'customAttributeBoolean':
          return CustomAttributeBoolean(
            attributeConfig,
            customAttributes,
            setCustomAttributes,
            config,
            agentLang,
            selectedContact.contactId
          );
        case 'customAttributeSelect':
          return CustomAttributeSelect(
            attributeConfig,
            customAttributes,
            setCustomAttributes,
            config,
            agentLang,
            selectedContact.contactId
          );
        case 'prefixedAttributes':
          return PrefixedAttributes(attributeConfig, contactAttributes, config, agentLang);
        case 'date':
          return DateAttribute(attributeConfig, getAttributeValue(attributeConfig.attributeKey), config, agentLang);
        case 'table':
          return TableAttribute(attributeConfig, getAttributeValue(attributeConfig.attributeKey));
        case 'phoneNumber':
        case 'email':
        case 'web':
          return LinkAttribute(attributeConfig, getAttributeValue(attributeConfig.attributeKey), config, agentLang);
        case 'html':
          return HtmlAttribute(attributeConfig, contactAttributes, config, agentLang);
        default:
          logger.error('unsupported visibleAttribute displayType property', attributeConfig);
          return null;
      }
    });
    return componentsList;
  };

  const submitCustomAttributes = async () => {
    logger.debug('Saving Custom Attributes');
    setLoading(true);

    const attributes = convertToIAttributes(customAttributesRef.current[selectedContact.contactId]);
    const response = await updateContactAttributes(selectedContact, attributes);
    if (response === 'Success') {
      const currentContactId = selectedContact.getContactId();
      setTimeout(() => {
        selectedContactRefresh(currentContactId);
        setLoading(false);
      }, 500);
    }
    setLoading(false);
  };

  const showUpdateButton = () => {
    let showButton = false;
    for (const key in customAttributes[selectedContact.contactId]) {
      if (
        contactAttributes[key] &&
        contactAttributes[key].value !== customAttributes[selectedContact.contactId][key].value
      ) {
        showButton = true;
        break;
      } else if (!contactAttributes[key] && customAttributes[selectedContact.contactId][key].value !== '') {
        showButton = true;
        break;
      }
    }
    return showButton;
  };

  const getAttributeValue = (propertyName: string) => {
    if (contactAttributes[propertyName]) {
      return contactAttributes[propertyName].value;
    } else {
      return '';
    }
  };

  const getDispositionValue = (propertyName: string) => {
    if (contactAttributes[propertyName]) {
      return contactAttributes[propertyName].value;
    } else {
      return '';
    }
  };

  const getContactType = () => {
    // throw new Error('oopsie');
    const contactType = selectedContact.getType();
    switch (contactType) {
      case connect.ContactType.VOICE:
        return 'Call Info';
      case connect.ContactType.QUEUE_CALLBACK:
        return 'Call Info';
      case connect.ContactType.TASK:
        return 'Task Info';
      case connect.ContactType.CHAT:
        return 'Chat Info';
      default:
        return 'Contact Info';
    }
  };

  if (contactAttributes === null) {
    return null;
  }

  return (
    contactAttributes && (
      <Fragment>
        {contactInfoHeader()}
        <Card className="card-jmb" style={{marginTop: '0px'}}>
          <Card.Content className={`header ${brandHelper.getBrandCode(contactAttributes)}`}>
            <Card.Header textAlign="left">
              <Icon name="info" />
              {config.translate(getContactType(), agentLang) || getContactType()}
            </Card.Header>
          </Card.Content>
          <Card.Content>
            <List divided relaxed>
              {contactInfoComponents()}
            </List>
            {showUpdateButton() && (
              <Button color="grey" onClick={submitCustomAttributes} disabled={loading} style={{float: 'right'}}>
                {config.translate('Update', agentLang) || 'Update'}
              </Button>
            )}
          </Card.Content>
        </Card>
        {getLocationModal()}
      </Fragment>
    )
  );
};
