import React from 'react';
import {Checkbox, CheckboxProps, /*InputOnChangeData,*/ List, ListItem} from 'semantic-ui-react';
import {Logger, LoggingService} from '../../services/LoggingService';
import {ICustomAttributeBooleanConfig} from '../../models/contactInfoDisplaySets';
import {ConfigurationService} from '../../services/ConfigurationService';
import { ICustomAttributesState } from './ContactInfo';

export const CustomAttributeBoolean = (
  inputConfig: ICustomAttributeBooleanConfig,
  customAttributes: ICustomAttributesState,
  setCustomAttributes: React.Dispatch<
    React.SetStateAction<ICustomAttributesState>
  >,
  config: ConfigurationService,
  agentLang: string,
  contactId: string
) => {
  // useEffect(() => {
  //   setValue(inputValue);
  // }, [inputValue]);

  const logger: Logger = new LoggingService().getLogger('CustomAttributeBoolean');

  if (!inputConfig.label) {
    logger.error('no input label provided in inputConfig, skipping component', {...inputConfig});
    return null;
  }

  if (!inputConfig.attributeKey) {
    logger.error('no attributeKey available in inputConfig, skipping component', {...inputConfig});
    return null;
  }

  const getValue = () => {
    if (!customAttributes[contactId][inputConfig.attributeKey]) {
      return '';
    } else {
      return customAttributes[contactId][inputConfig.attributeKey].value;
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>, data: CheckboxProps) => {
    const key = inputConfig.attributeKey;
    const updates = {};
    updates[contactId] = {};
    if (customAttributes[contactId]) {
      updates[contactId] = customAttributes[contactId];
    }
    updates[contactId][key].value = data.checked ? 'true' : 'false';
    setCustomAttributes((prev) => ({...prev, ...updates}));
  };

  return (
    <ListItem key={inputConfig.attributeKey}>
      <List.Header>
        <strong>{config.translate(inputConfig.label, agentLang) || inputConfig.label}</strong>
      </List.Header>
      <List.Description>
        <Checkbox
          value={getValue()}
          name={inputConfig.attributeKey}
          onChange={handleChange}
          toggle={inputConfig.toggle === true}
        ></Checkbox>
      </List.Description>
    </ListItem>
  );
};
