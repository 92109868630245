import {Icon, List, ListItem, Popup, Table} from 'semantic-ui-react';
import {ITableAttributeConfig} from '../../models/contactInfoDisplaySets';
import {Logger, LoggingService} from '../../services/LoggingService';

interface IComplexDisplay {
  type: 'link' | 'text';
  value: string;
  display: string;
}
interface IContentItem {
  [key: string]: string | boolean | IComplexDisplay;
}

type contentArray = IContentItem[];

export const TableAttribute = (attributeConfig: ITableAttributeConfig, tableContent: string): JSX.Element => {
  const logger: Logger = new LoggingService().getLogger('TableAttribute');

  if (!tableContent) {
    logger.error('No table content available, returning null value');
    return null;
  }
  const contentArray = JSON.parse(tableContent) as contentArray;

  // Safety checks to make sure configs and content line up
  if ((!Array.isArray(contentArray) || !contentArray.length) && attributeConfig.hideIfFalsy) {
    logger.debug('Table content array is invalid or empty, and hideIfFalsy is set to true. Returning null value');
    return null;
  }

  if (!attributeConfig.columnHeaders || !attributeConfig.columnHeaders.length) {
    logger.error('No column headers in attribute config, returning null value');
    return null;
  }

  if (!attributeConfig.columnValues || !attributeConfig.columnValues.length) {
    logger.error('No column values in attribute config, returning null value');
    return null;
  }

  if (attributeConfig.columnValues.length !== attributeConfig.columnHeaders.length) {
    logger.error('Column header length must match column value length, returning null value');
    return null;
  }

  for (const contentRow of contentArray) {
    const length = Object.keys(contentRow).length;
    if (length !== attributeConfig.columnHeaders.length) {
      logger.error('Length of all rows must be equal to column header length, returning null value');
      return null;
    }
  }

  const headerRow = attributeConfig.columnHeaders.map((header: string): JSX.Element => {
    return <Table.HeaderCell key={header}>{header}</Table.HeaderCell>;
  });

  const columnValues = attributeConfig.columnValues;

  const copyToClipboard = (content: string): void => {
    navigator.clipboard.writeText(content).then(
      function () {
        /* clipboard successfully set */
      },
      function () {
        /* clipboard write failed */
      }
    );
  };

  const getLinkElement = (item: IComplexDisplay): JSX.Element => {
    const {value, display} = item;

    // Value is the url/href for the link. If missing, return empty element
    if (!value) {
      logger.error(
        `Value property is required for link display type. Unable to display value for this table cell. Returning empty cell. Value:`,
        item
      );
      return <Table.Cell />;
    }

    return (
      <Table.Cell>
        <a href={value} target="_blank" rel="noopener noreferrer">
          {display ? display : value}
        </a>
      </Table.Cell>
    );
  };

  const getTextElement = (item: IComplexDisplay): JSX.Element => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const {value, display} = item;

    // Value is the url/href for the link. If missing, return empty element
    if (!value) {
      logger.error(
        `Value property is required for link display type. Unable to display value for this table cell. Returning empty cell. Value:`,
        item
      );
      return <Table.Cell />;
    }

    const copyIconButton = <Icon link name="copy outline" onClick={() => copyToClipboard(value)}></Icon>;
    return (
      <Table.Cell>
        {value}
        <Popup size="tiny" content={'Copy'} trigger={copyIconButton} />
      </Table.Cell>
    );
  };

  const rows = contentArray.map((row: IContentItem, index: number): JSX.Element => {
    const rowArray = [];
    for (const value of columnValues) {
      if (typeof row[value] === 'boolean') {
        rowArray.push(<Table.Cell>{row[value].toString()}</Table.Cell>);
      } else if (typeof row[value] === 'string') {
        rowArray.push(<Table.Cell>{row[value]}</Table.Cell>);
      } else {
        if (typeof row[value] === 'object') {
          switch (row[value]['type']) {
            case 'link':
              rowArray.push(getLinkElement(row[value] as IComplexDisplay));
              break;
            case 'text':
              rowArray.push(getTextElement(row[value] as IComplexDisplay));
          }
        }
      }
    }
    return <Table.Row key={index}>{rowArray}</Table.Row>;
  });

  return (
    <ListItem>
      <List.Description>
        <div className="tableContainer">
          <Table celled compact size="small">
            <Table.Header>
              <Table.Row>{headerRow}</Table.Row>
            </Table.Header>
            {rows && <Table.Body>{rows}</Table.Body>}
          </Table>
        </div>
      </List.Description>
    </ListItem>
  );
};
