import dayjs from 'dayjs';
import React, {Fragment, useContext, useEffect, useState} from 'react';
import {Button, Header, Icon, Label, List, Message, Popup} from 'semantic-ui-react';
import {AppContext} from '../../contexts/appContext';
import {ContactContext} from '../../contexts/contactContext';
import {CcpWrapperContext} from '../../contexts/ccpWrapperContext';

import {Logger, LoggingService} from '../../services/LoggingService';

interface IProps {
  brandCode: string;
}

export const RoadsideHeader = (props: IProps) => {
  const {config} = useContext(AppContext);
  const {selectedContact, agentLang} = useContext(ContactContext);
  const {setLocationModalVisible} = useContext(CcpWrapperContext);
  const [attributes, setAttributes] = useState(selectedContact.getAttributes());

  const logger: Logger = new LoggingService().getLogger('RoadsideHeader');

  useEffect(() => {
    setAttributes(selectedContact.getAttributes());
  }, [selectedContact]);

  const getBrandMaxHeight = () => {
    switch (props.brandCode) {
      case 'ean':
      case 'ford':
      case 'lincoln':
        return '60px';
      default:
        // all other brandCodes
        return '30px';
    }
  };

  const parseRecentContacts = () => {
    let contactHistory: {[key: string]: string | number}[] = [];

    if (attributes.recentCalls && attributes.recentCalls.value && attributes.recentCalls.value !== '') {
      contactHistory = JSON.parse(attributes.recentCalls.value);
      return contactHistory;
    }

    // if (attributes.recentCalls && attributes.recentCalls.value && attributes.recentCalls.value !== '') {
    //   contactHistory = JSON.parse(attributes.recentCalls.value);
    //   return contactHistory;
    // }

    return null;
  };

  const updateLocationClipboard = (latitude: string, longitude: string) => {
    navigator.clipboard.writeText(latitude + ':' + longitude).then(
      function () {
        /* clipboard successfully set */
      },
      function () {
        /* clipboard write failed */
      }
    );
  };

  const currentContactLocation = () => {
    const createdTimestamp =
      typeof attributes.createdTimestamp?.value === 'string'
        ? parseInt(attributes.createdTimestamp.value)
        : attributes.createdTimestamp.value;
    // typeof attributes.createdTimestamp?.value === 'string'
    //   ? parseInt(attributes.createdTimestamp.value)
    //   : attributes.createdTimestamp.value;
    try {
      return (
        <Message key="current">
          <p>
            <small>
              {config.translate('Captured at', agentLang) || 'Captured at'}{' '}
              {dayjs(createdTimestamp * 1000).format('MMM DD - hh:mm A')}{' '}
            </small>
          </p>
          <p>
            <b>{config.translate('Nearest Address', agentLang) || 'NearestAddress'}:</b>
          </p>
          <p>{attributes.addressLabel?.value}</p>
          {/* <p>{attributes.addressLabel?.value}</p> */}

          <hr />
          <p>
            <b>{config.translate('Latitude', agentLang) || 'Latitude'}:</b> {attributes.latitude?.value}{' '}
            {/* <b>{config.translate('Latitude', agentLang) || 'Latitude'}:</b> {attributes.latitude?.value} <br /> */}
            <b>{config.translate('Longitude', agentLang) || 'Longitude'}:</b> {attributes.longitude?.value}
            {/* <b>{config.translate('Longitude', agentLang) || 'Longitude'}:</b> {attributes.longitude?.value} */}
          </p>
          <p style={{lineHeight: '1.0'}}>
            <small>
              <i>
                {config.translate('locationDisclaimerA', agentLang) ||
                  'We are 95% confident that these coordinates are within'}{' '}
                {Math.round(parseInt(attributes.accuracy?.value))}{' '}
                {/* {Math.round(parseInt(attributes.accuracy?.value))}{' '} */}
                {config.translate('locationDisclaimerB', agentLang) || "meters of the customer's actual location."}
              </i>
            </small>
          </p>
          <Button
            circular
            color="grey"
            icon="copy outline"
            onClick={
              () => updateLocationClipboard(attributes.latitude?.value, attributes.longitude?.value)
              // updateLocationClipboard(attributes.latitude?.value, attributes.longitude?.value)
            }
          />
        </Message>
      );
    } catch (err) {
      logger.error('Error rendering current location info in popup component', err);
      return null;
    }
  };

  const previousContactLocations = () => {
    const contactHistory = parseRecentContacts();

    if (!contactHistory.length) {
      logger.debug('no additional location history, returning null', null);
    }

    try {
      return contactHistory
        .filter((contact) => {
          return contact.addressFound && contact.addressFound === 'true';
        })
        .map((contact, index) => {
          const createdTimestamp =
            typeof contact.createdTimestamp === 'string'
              ? parseInt(contact.createdTimestamp)
              : contact.createdTimestamp;

          const accuracy = typeof contact.accuracy === 'string' ? parseInt(contact.accuracy) : contact.accuracy;

          const latitude = typeof contact.latitude === 'number' ? contact.latitude.toString() : contact.latitude;

          const longitude = typeof contact.longitude === 'number' ? contact.longitude.toString() : contact.longitude;

          return (
            <Message key={index}>
              <p>
                <small>
                  {config.translate('Captured at', agentLang) || 'Captured at'}{' '}
                  {dayjs(createdTimestamp * 1000).format('MMM DD - hh:mm A')}{' '}
                </small>
              </p>
              <p>
                <b>{config.translate('Nearest Address', agentLang) || 'NearestAddress'}:</b>
              </p>
              <p>{contact.addressLabel}</p>
              <hr />
              <p>
                <b>{config.translate('Latitude', agentLang) || 'Latitude'}:</b> {contact.latitude} <br />
                <b>{config.translate('Longitude', agentLang) || 'Longitude'}:</b> {contact.longitude}
              </p>
              <p style={{lineHeight: '1.0'}}>
                <small>
                  <i>
                    {config.translate('locationDisclaimerA', agentLang) ||
                      'We are 95% confident that these coordinates are within'}{' '}
                    {Math.round(accuracy)}{' '}
                    {config.translate('locationDisclaimerB', agentLang) || "meters of the customer's actual location."}
                  </i>
                </small>
              </p>
              <Button
                circular
                color="grey"
                icon="copy outline"
                onClick={() => updateLocationClipboard(latitude, longitude)}
              />
            </Message>
          );
        });
    } catch (err) {
      logger.error('Error rendering previous locations info in popup component', err);
      return null;
    }
  };

  const getLocationPopupTrigger = () => {
    return (
      <div>
        <Label as="a" circular color="grey" size="large" style={{width: '20px'}}>
          <Icon name="location arrow" />
        </Label>
      </div>
    );
  };

  const getLocationPopupContent = () => {
    return (
      <Fragment>
        <Button color="grey" style={{width: '100%'}} onClick={() => setLocationModalVisible(true)}>
          {config.translate('Capture Current Location', agentLang) || 'Capture Current Location'}
        </Button>
        {attributes && attributes.addressFound && attributes.addressFound.value === 'true' && currentContactLocation()}
        {attributes && attributes.recentCalls && attributes.recentCalls.value && previousContactLocations()}
        {/* {attributes && attributes.addressFound && attributes.addressFound.value === 'true' && currentContactLocation()}
        {attributes && attributes.recentCalls && attributes.recentCalls.value && previousContactLocations()} */}
      </Fragment>
    );
  };

  const getRecentContactsPopupTrigger = (contactsCount: number) => {
    return (
      <div>
        <Label as="a" circular color="grey" size="large">
          <Icon name="repeat" /> {contactsCount}
        </Label>
      </div>
    );
  };

  const getRecentContactsPopupContent = (recentContacts: {[key: string]: string | number}[]): JSX.Element => {
    return (
      <List divided relaxed>
        {recentContacts.map((contact: {[key: string]: string | number}, index: number) => {
          const createdTimestamp =
            typeof contact.createdTimestamp === 'string'
              ? parseInt(contact.createdTimestamp)
              : contact.createdTimestamp;

          return (
            <List.Item key={index}>
              <List.Header>{dayjs(createdTimestamp * 1000).format('MMM DD - hh:mm A')} &nbsp;</List.Header>
              <List.Description>
                {contact.utterance && (
                  <Fragment>
                    {' '}
                    <b>{config.translate('Utterance', agentLang) || 'Utterance'}:</b> {contact.utterance} <br />
                  </Fragment>
                )}
                {contact.issue && (
                  <Fragment>
                    {' '}
                    <b>{config.translate('Issue', agentLang) || 'Issue'}:</b> {contact.issue ? contact.issue : ''}{' '}
                    <br />
                  </Fragment>
                )}
                <b>{config.translate('Disposition', agentLang) || 'Disposition'}:</b>{' '}
                {contact.disposition
                  ? config.translate(contact.disposition as string, agentLang) || contact.dispostion
                  : config.translate('Not set', agentLang) || 'Not set'}
              </List.Description>
            </List.Item>
          );
        })}
      </List>
    );
  };

  const getRecentContactsPopup = () => {
    if (
      attributes &&
      attributes.recentCaller &&
      attributes.recentCaller.value === 'true'
      // attributes &&
      // attributes.recentCaller &&
      // attributes.recentCaller.value === 'true'
    ) {
      const recentContacts = parseRecentContacts();

      return (
        <div style={{float: 'right'}}>
          <Popup
            trigger={getRecentContactsPopupTrigger(recentContacts.length)}
            content={getRecentContactsPopupContent(recentContacts)}
            on="hover"
            position="top left"
            hoverable
          />
        </div>
      );
    }

    return null;
  };

  return (
    <Header className={props.brandCode || 'ean'} as="h2">
      <Icon name="car" />
      <Header.Content style={{width: '100%'}}>
        <img src={`${props.brandCode}.png`} style={{maxHeight: getBrandMaxHeight()}} alt={`${props.brandCode} logo`} />{' '}
        <div style={{float: 'right'}}>
          <Popup
            trigger={getLocationPopupTrigger()}
            content={getLocationPopupContent()}
            on="hover"
            position="top left"
            hoverable
          ></Popup>
        </div>
        {getRecentContactsPopup()}
      </Header.Content>
    </Header>
  );
};

// export const RoadsideHeader = (
//   brandCode: string,
//   attributes: connect.AttributeDictionary,
//   config: ConfigurationService,
//   agentLang: string,
//   setLocationModalVisible: Dispatch<SetStateAction<boolean>>
// ) => {
//   const attributesRef = useRef(attributes);

//   useEffect(() => {
//     attributesRef.current = attributes;
//   }, [attributes]);

//   const getBrandMaxHeight = () => {
//     switch (brandCode) {
//       case 'ean':
//       case 'ford':
//       case 'lincoln':
//         return '60px';
//       default:
//         // all other brandCodes
//         return '30px';
//     }
//   };

//   const parseRecentContacts = () => {
//     let contactHistory: {[key: string]: string | number}[] = [];

//     if (
//       attributesRef.current.recentCalls &&
//       attributesRef.current.recentCalls.value &&
//       attributesRef.current.recentCalls.value !== ''
//     ) {
//       contactHistory = JSON.parse(attributesRef.current.recentCalls.value);
//       return contactHistory;
//     }

//     // if (attributes.recentCalls && attributes.recentCalls.value && attributes.recentCalls.value !== '') {
//     //   contactHistory = JSON.parse(attributes.recentCalls.value);
//     //   return contactHistory;
//     // }

//     return null;
//   };

//   const updateLocationClipboard = (latitude: string, longitude: string) => {
//     navigator.clipboard.writeText(latitude + ':' + longitude).then(
//       function () {
//         /* clipboard successfully set */
//       },
//       function () {
//         /* clipboard write failed */
//       }
//     );
//   };

//   const currentContactLocation = () => {
//     const createdTimestamp =
//       typeof attributesRef.current.createdTimestamp?.value === 'string'
//         ? parseInt(attributesRef.current.createdTimestamp.value)
//         : attributesRef.current.createdTimestamp.value;
//     // typeof attributes.createdTimestamp?.value === 'string'
//     //   ? parseInt(attributes.createdTimestamp.value)
//     //   : attributes.createdTimestamp.value;
//     try {
//       return (
//         <Message key="current">
//           <p>
//             <small>
//               {config.translate('Captured at', agentLang) || 'Captured at'}{' '}
//               {dayjs(createdTimestamp * 1000).format('MMM DD - hh:mm A')}{' '}
//             </small>
//           </p>
//           <p>
//             <b>{config.translate('Nearest Address', agentLang) || 'NearestAddress'}:</b>
//           </p>
//           <p>{attributesRef.current.addressLabel?.value}</p>
//           {/* <p>{attributes.addressLabel?.value}</p> */}

//           <hr />
//           <p>
//             <b>{config.translate('Latitude', agentLang) || 'Latitude'}:</b> {attributesRef.current.latitude?.value}{' '}
//             {/* <b>{config.translate('Latitude', agentLang) || 'Latitude'}:</b> {attributes.latitude?.value} <br /> */}
//             <b>{config.translate('Longitude', agentLang) || 'Longitude'}:</b> {attributesRef.current.longitude?.value}
//             {/* <b>{config.translate('Longitude', agentLang) || 'Longitude'}:</b> {attributes.longitude?.value} */}
//           </p>
//           <p style={{lineHeight: '1.0'}}>
//             <small>
//               <i>
//                 {config.translate('locationDisclaimerA', agentLang) ||
//                   'We are 95% confident that these coordinates are within'}{' '}
//                 {Math.round(parseInt(attributesRef.current.accuracy?.value))}{' '}
//                 {/* {Math.round(parseInt(attributes.accuracy?.value))}{' '} */}
//                 {config.translate('locationDisclaimerB', agentLang) || "meters of the customer's actual location."}
//               </i>
//             </small>
//           </p>
//           <Button
//             circular
//             color="grey"
//             icon="copy outline"
//             onClick={
//               () =>
//                 updateLocationClipboard(attributesRef.current.latitude?.value, attributesRef.current.longitude?.value)
//               // updateLocationClipboard(attributes.latitude?.value, attributes.longitude?.value)
//             }
//           />
//         </Message>
//       );
//     } catch (err) {
//       logger.error('Error rendering current location info in popup component', err);
//       return null;
//     }
//   };

//   const previousContactLocations = () => {
//     const contactHistory = parseRecentContacts();

//     if (!contactHistory.length) {
//       logger.debug('no additional location history, returning null', null);
//     }

//     try {
//       return contactHistory
//         .filter((contact) => {
//           return contact.addressFound && contact.addressFound === 'true';
//         })
//         .map((contact, index) => {
//           const createdTimestamp =
//             typeof contact.createdTimestamp === 'string'
//               ? parseInt(contact.createdTimestamp)
//               : contact.createdTimestamp;

//           const accuracy = typeof contact.accuracy === 'string' ? parseInt(contact.accuracy) : contact.accuracy;

//           const latitude = typeof contact.latitude === 'number' ? contact.latitude.toString() : contact.latitude;

//           const longitude = typeof contact.longitude === 'number' ? contact.longitude.toString() : contact.longitude;

//           return (
//             <Message key={index}>
//               <p>
//                 <small>
//                   {config.translate('Captured at', agentLang) || 'Captured at'}{' '}
//                   {dayjs(createdTimestamp * 1000).format('MMM DD - hh:mm A')}{' '}
//                 </small>
//               </p>
//               <p>
//                 <b>{config.translate('Nearest Address', agentLang) || 'NearestAddress'}:</b>
//               </p>
//               <p>{contact.addressLabel}</p>
//               <hr />
//               <p>
//                 <b>{config.translate('Latitude', agentLang) || 'Latitude'}:</b> {contact.latitude} <br />
//                 <b>{config.translate('Longitude', agentLang) || 'Longitude'}:</b> {contact.longitude}
//               </p>
//               <p style={{lineHeight: '1.0'}}>
//                 <small>
//                   <i>
//                     {config.translate('locationDisclaimerA', agentLang) ||
//                       'We are 95% confident that these coordinates are within'}{' '}
//                     {Math.round(accuracy)}{' '}
//                     {config.translate('locationDisclaimerB', agentLang) || "meters of the customer's actual location."}
//                   </i>
//                 </small>
//               </p>
//               <Button
//                 circular
//                 color="grey"
//                 icon="copy outline"
//                 onClick={() => updateLocationClipboard(latitude, longitude)}
//               />
//             </Message>
//           );
//         });
//     } catch (err) {
//       logger.error('Error rendering previous locations info in popup component', err);
//       return null;
//     }
//   };

//   const getLocationPopupTrigger = () => {
//     return (
//       <div>
//         <Label as="a" circular color="grey" size="large" style={{width: '20px'}}>
//           <Icon name="location arrow" />
//         </Label>
//       </div>
//     );
//   };

//   const getLocationPopupContent = () => {
//     return (
//       <Fragment>
//         <Button color="grey" style={{width: '100%'}} onClick={() => setLocationModalVisible(true)}>
//           {config.translate('Capture Current Location', agentLang) || 'Capture Current Location'}
//         </Button>
//         {attributesRef.current &&
//           attributesRef.current.addressFound &&
//           attributesRef.current.addressFound.value === 'true' &&
//           currentContactLocation()}
//         {attributesRef.current &&
//           attributesRef.current.recentCalls &&
//           attributesRef.current.recentCalls.value &&
//           previousContactLocations()}
//         {/* {attributes && attributes.addressFound && attributes.addressFound.value === 'true' && currentContactLocation()}
//         {attributes && attributes.recentCalls && attributes.recentCalls.value && previousContactLocations()} */}
//       </Fragment>
//     );
//   };

//   const getRecentContactsPopupTrigger = (contactsCount: number) => {
//     return (
//       <div>
//         <Label as="a" circular color="grey" size="large">
//           <Icon name="repeat" /> {contactsCount}
//         </Label>
//       </div>
//     );
//   };

//   const getRecentContactsPopupContent = (recentContacts: {[key: string]: string | number}[]): JSX.Element => {
//     return (
//       <List divided relaxed>
//         {recentContacts.map((contact: {[key: string]: string | number}, index: number) => {
//           const createdTimestamp =
//             typeof contact.createdTimestamp === 'string'
//               ? parseInt(contact.createdTimestamp)
//               : contact.createdTimestamp;

//           return (
//             <List.Item key={index}>
//               <List.Header>{dayjs(createdTimestamp * 1000).format('MMM DD - hh:mm A')} &nbsp;</List.Header>
//               <List.Description>
//                 {contact.utterance && (
//                   <Fragment>
//                     {' '}
//                     <b>{config.translate('Utterance', agentLang) || 'Utterance'}:</b> {contact.utterance} <br />
//                   </Fragment>
//                 )}
//                 {contact.issue && (
//                   <Fragment>
//                     {' '}
//                     <b>{config.translate('Issue', agentLang) || 'Issue'}:</b> {contact.issue ? contact.issue : ''}{' '}
//                     <br />
//                   </Fragment>
//                 )}
//                 <b>{config.translate('Disposition', agentLang) || 'Disposition'}:</b>{' '}
//                 {contact.disposition
//                   ? config.translate(contact.disposition as string, agentLang) || contact.dispostion
//                   : config.translate('Not set', agentLang) || 'Not set'}
//               </List.Description>
//             </List.Item>
//           );
//         })}
//       </List>
//     );
//   };

//   const getRecentContactsPopup = () => {
//     if (
//       attributesRef.current &&
//       attributesRef.current.recentCaller &&
//       attributesRef.current.recentCaller.value === 'true'
//       // attributes &&
//       // attributes.recentCaller &&
//       // attributes.recentCaller.value === 'true'
//     ) {
//       const recentContacts = parseRecentContacts();

//       return (
//         <div style={{float: 'right'}}>
//           <Popup
//             trigger={getRecentContactsPopupTrigger(recentContacts.length)}
//             content={getRecentContactsPopupContent(recentContacts)}
//             on="hover"
//             position="top left"
//             hoverable
//           />
//         </div>
//       );
//     }

//     return null;
//   };

//   return (
//     <Header className={brandCode || 'ean'} as="h2">
//       <Icon name="car" />
//       <Header.Content style={{width: '100%'}}>
//         <img src={`${brandCode}.png`} style={{maxHeight: getBrandMaxHeight()}} alt={`${brandCode} logo`} />{' '}
//         <div style={{float: 'right'}}>
//           <Popup
//             trigger={getLocationPopupTrigger()}
//             content={getLocationPopupContent()}
//             on="hover"
//             position="top left"
//             hoverable
//           ></Popup>
//         </div>
//         {getRecentContactsPopup()}
//       </Header.Content>
//     </Header>
//   );
// };
