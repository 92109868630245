import React from 'react';
import {Accordion, AccordionTitleProps, Form, Menu} from 'semantic-ui-react';
import { ConfigurationService } from '../../services/ConfigurationService';

export const DispositionMenuItem = (
  index: number,
  label: string,
  option: string[],
  brandCode: string,
  selectedDisposition: string,
  setSelectedDisposition: (value: string) => void,
  activeIndex: number,
  setActiveIndex: (value: number) => void,
  config: ConfigurationService,
  agentLang: string
) => {
  const singleSelectMenuItem = () => {
    const content = option.map((item) => {
      const key = `${label}.${item}`;
      return (
        <Form.Radio
          key={key}
          label={config.translate(item, agentLang) || item}
          type="radio"
          checked={selectedDisposition === item}
          onChange={() => setSelectedDisposition(item)}
        ></Form.Radio>
      );
    });

    const updateIndex = (event: React.MouseEvent, data: AccordionTitleProps): void => {
      const newIndex = activeIndex === data.index ? -1 : (data.index as number);
      setActiveIndex(newIndex);
    };

    return (
      <Menu.Item key={label}>
        <Accordion.Title
          active={activeIndex === index}
          content={config.translate(label, agentLang) || label}
          index={index}
          onClick={updateIndex}
          className={brandCode}
        />
        <Accordion.Content active={activeIndex === index} content={content} />
      </Menu.Item>
    );
  };

  return singleSelectMenuItem();
};
