import React, {useState} from 'react';
import {IDispositionSpecialOption} from '../../models/dispositionConfig';
import {ConfigurationService} from '../../services/ConfigurationService';
import DatePicker from 'react-datepicker';

import 'react-datepicker/dist/react-datepicker.css';
import {Accordion, AccordionTitleProps, Icon, Input, InputOnChangeData, Menu} from 'semantic-ui-react';
import {ISpecialOptionsStateMap} from './DispositionPane';

export const DispositionSpecialMenuItem = (
  index: number,
  label: string,
  specialOption: IDispositionSpecialOption,
  brandCode: string,
  selectedDisposition: string,
  setSelectedDisposition: (value: string) => void,
  specialOptionsState: ISpecialOptionsStateMap,
  setSpecialOptionsState: (value: ISpecialOptionsStateMap) => void,
  activeIndex: number,
  setActiveIndex: (value: number) => void,
  config: ConfigurationService,
  agentLang: string
) => {
  const updateIndex = (event: React.MouseEvent, data: AccordionTitleProps): void => {
    const newIndex = activeIndex === data.index ? -1 : (data.index as number);
    setActiveIndex(newIndex);
  };

  if (specialOption.specialOptionType === 'sendEmail') {
    const onChange = (event: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => {
      console.log(data);
      // Update the specialOptionsState for the given key
      let tempSpecialOptionsState = specialOptionsState;
      tempSpecialOptionsState[label] = {
        value: data.value
      };
      setSpecialOptionsState(tempSpecialOptionsState);
      setSelectedDisposition(`${specialOption.specialOptionType}#${specialOption.specialOptionDispositionPrefix}#${data.value}`);
    };

    const content = (
      <Input
        placeholder={specialOption.specialOptionLabel}
        style={{width: '100%'}}
        name={label}
        onChange={onChange}
        iconPosition="left"
      >
        <Icon name="at" />
        <input />
      </Input>
    );
    return (
      <Menu.Item key={label}>
        <Accordion.Title
          active={activeIndex === index}
          content={config.translate(label, agentLang) || label}
          index={index}
          onClick={updateIndex}
          className={brandCode}
        />
        <Accordion.Content id="sendEmailField" active={activeIndex === index} content={content} />
      </Menu.Item>
    );
  }

  if (specialOption.specialOptionType === 'rescheduleDate') {
    const getSelectedDate = () => {
      if (specialOptionsState[label]?.value) {
        return specialOptionsState[label]?.value;
      }
    };

    const onChange = (data) => {
      // Update the specialOptionsState for the given key
      let tempSpecialOptionsState = specialOptionsState;
      tempSpecialOptionsState[label] = {
        value: data
      };
      setSpecialOptionsState(tempSpecialOptionsState);
      setSelectedDisposition(`${specialOption.specialOptionType}#${specialOption.specialOptionDispositionPrefix}#${data.toISOString()}`);
    };

    const minDate = new Date();
    const maxDate = new Date();
    maxDate.setDate(minDate.getDate() + 2);

    const content = (
      <DatePicker
        showIcon
        selected={getSelectedDate()}
        onChange={(date) => onChange(date)}
        showTimeSelect
        timeFormat="HH:mm"
        timeCaption="time"
        dateFormat="MMMM d, yyyy h:mm aa"
        placeholderText="Please choose a time"
        minDate={minDate}
        maxDate={maxDate}
      />
    );
    return (
      <Menu.Item key={label}>
        <Accordion.Title
          active={activeIndex === index}
          content={config.translate(label, agentLang) || label}
          index={index}
          onClick={updateIndex}
          className={brandCode}
        />
        <Accordion.Content id="rescheduleDatePicker" active={activeIndex === index} content={content} />
      </Menu.Item>
    );
  }
};
