import React from 'react';
import { InputOnChangeData, List, ListItem, Select } from 'semantic-ui-react';
import { Logger, LoggingService } from '../../services/LoggingService';
import { ICustomAttributeSelectConfig } from '../../models/contactInfoDisplaySets';
import { ConfigurationService } from '../../services/ConfigurationService';
import { ICustomAttributesState } from './ContactInfo';

export const CustomAttributeSelect = (
  inputConfig: ICustomAttributeSelectConfig,
  customAttributes: ICustomAttributesState,
  setCustomAttributes: React.Dispatch<
    React.SetStateAction<ICustomAttributesState>
  >,
  config: ConfigurationService,
  agentLang: string,
  contactId: string
) => {
  const logger: Logger = new LoggingService().getLogger('CustomAttributeSelect');

  if (!inputConfig.label) {
    logger.error('no input label provided in inputConfig, skipping component', { ...inputConfig });
    return null;
  }

  if (!inputConfig.attributeKey) {
    logger.error('no attributeKey available in inputConfig, skipping component', { ...inputConfig });
    return null;
  }

  const getPlaceholder = () => {
    if (!inputConfig.placeholder) {
      logger.error('no placeholder text provided in inputConfig, using default placeholder', { ...inputConfig });
      return 'Please select an option';
    } else {
      return inputConfig.placeholder;
    }
  };

  const getValue = () => {
    if (!customAttributes[contactId][inputConfig.attributeKey]) {
      return '';
    } else {
      return customAttributes[contactId][inputConfig.attributeKey].value;
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => {
    const key = inputConfig.attributeKey;
    const updates = {};
    updates[contactId] = {};
    if (customAttributes[contactId]) {
      updates[contactId] = customAttributes[contactId];
    }
    updates[contactId][key].value = data.value;
    setCustomAttributes((prev) => ({...prev, ...updates}));
  };

  return (
    <ListItem key={inputConfig.attributeKey}>
      <List.Header>
        <strong>{config.translate(inputConfig.label, agentLang) || inputConfig.label}</strong>
      </List.Header>
      <List.Description>
        <Select
          placeholder={config.translate(getPlaceholder(), agentLang) || getPlaceholder()}
          style={{ width: '100%' }}
          value={getValue()}
          options={inputConfig.values?.map((value) => ({ key: value, text: value, value }))}
          name={inputConfig.attributeKey}
          onChange={handleChange}
        ></Select>
      </List.Description>
    </ListItem>
  );
};
