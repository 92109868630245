import React from 'react';
import './App.css';
import {AppContextProvider} from './contexts/appContext';
import {ContactContextProvider} from './contexts/contactContext';
import {RestApiContextProvider} from './contexts/restApiContext';
import {CcpWrapperContextProvider} from './contexts/ccpWrapperContext';
import {CcpWrapper} from './components/CcpWrapper';

export function App() {
  return (
    <div className="App">
      <AppContextProvider>
        <RestApiContextProvider>
          <CcpWrapperContextProvider>
            <ContactContextProvider>
              <CcpWrapper />
            </ContactContextProvider>
          </CcpWrapperContextProvider>
        </RestApiContextProvider>
      </AppContextProvider>
    </div>
  );
}
