import {IDispositionConfig} from '../models/dispositionConfig';
import {Logger, LoggingService} from './LoggingService';

export interface IRecentContact {
  contactId: string;
  phoneNumber: string;
  multiSelectDisposition: boolean;
  disposition: string;
  GPBR: string;
  brandCode: string;
  issue: string;
  lexIssue?: string;
  startTimestamp?: number;
  endTimestamp?: number;
}

export class RecentContacts {
  private logger: Logger;

  constructor() {
    this.logger = new LoggingService().getLogger('RecentContacts');
  }
  public getRecentContacts() {
    // Patch to convert calls array into contacts array
    const calls = JSON.parse(localStorage.getItem('calls') || '[]');
    if (calls && calls.length) {
      const contacts = JSON.parse(localStorage.getItem('contacts') || '[]');
      if (!contacts || !contacts.length) {
        for (const call of calls) {
          call.multiSelectDisposition = false;
        }
        this.setRecentContacts(calls);
      }
    }
    return JSON.parse(localStorage.getItem('contacts') || '[]');
  }

  public setRecentContacts(recentContacts: IRecentContact[]): void {
    return localStorage.setItem('contacts', JSON.stringify(recentContacts));
  }

  public updateRecentContacts(
    recentContacts: IRecentContact[],
    contact: connect.Contact,
    dispositionConfig?: IDispositionConfig
  ): IRecentContact[] {
    const currentContactId = contact.getContactId();

    let multiSelectDisposition: boolean = false;

    if (dispositionConfig && dispositionConfig.multiSelect === true) {
      multiSelectDisposition = true;
    }

    if (recentContacts.length) {
      // At least one contact exists in recentContacts
      const lastContact = recentContacts[0];

      if (lastContact.contactId !== currentContactId) {
        // Contact just connected and is not saved to recentContacts yet
        const recentContact = this.formatNewContact(contact, multiSelectDisposition);
        recentContacts.unshift(recentContact);
        if (recentContacts.length > 5) {
          recentContacts.pop();
        }
        this.setRecentContacts(recentContacts);
      } else {
        // Contact has already been saved
      }
    } else {
      // Contact just connected but recentContacts array is empty
      const recentContact = this.formatNewContact(contact, multiSelectDisposition);
      recentContacts.unshift(recentContact);
      this.setRecentContacts(recentContacts);
    }
    return recentContacts;
  }

  public updateEndedContact(recentContacts: IRecentContact[], contact: connect.Contact): IRecentContact[] {
    const currentContactId = contact.getContactId();

    // TODO - find the correct recent contact, not just the last one
    let lastRelatedContact;
    for (const recentContact of recentContacts) {
      if (recentContact.contactId === currentContactId) {
        lastRelatedContact = recentContact
        break;
      }
    }

    if (lastRelatedContact) {
      if (lastRelatedContact.contactId === currentContactId && !lastRelatedContact.endTimestamp) {
        this.formatEndedContactUpdate(lastRelatedContact, contact);
        this.setRecentContacts(recentContacts);
      }
    }
    return recentContacts;
  }

  public updateCurrentContactDisposition(recentContacts: IRecentContact[], contact: connect.Contact): IRecentContact[] {
    const attributes = contact.getAttributes();
    const currentContactId = contact.getContactId();

    if (recentContacts.length > 0) {
      if (recentContacts[0].contactId === currentContactId) {
        recentContacts[0].disposition = attributes.disposition?.value || attributes.fordDisposition?.value || 'Not set';
        this.setRecentContacts(recentContacts);
      }
    }
    return recentContacts;
  }

  public updateCurrentContactGPBR(recentContacts: IRecentContact[], contact: connect.Contact): IRecentContact[] {
    const attributes = contact.getAttributes();
    const currentContactId = contact.getContactId();

    if (recentContacts.length > 0) {
      if (recentContacts[0].contactId === currentContactId) {
        recentContacts[0].disposition = attributes.GPBR?.value || '';
        this.setRecentContacts(recentContacts);
      }
    }
    return recentContacts;
  }

  public updateCurrentContact(recentContacts: IRecentContact[], contact: connect.Contact): IRecentContact[] {
    const attributes = contact.getAttributes();
    const currentContactId = contact.getContactId();

    if (recentContacts.length > 0) {
      if (recentContacts[0].contactId === currentContactId) {
        recentContacts[0].disposition = attributes.GPBR?.value || '';
        recentContacts[0].disposition = attributes.disposition?.value || attributes.fordDisposition?.value || 'Not set';
        this.setRecentContacts(recentContacts);
      }
    }
    return recentContacts;
  }

  private formatNewContact(contact: connect.Contact, multiSelectDisposition: boolean): IRecentContact {
    const attributes = contact.getAttributes();

    return {
      contactId: contact.getContactId(),
      phoneNumber: contact.getInitialConnection()?.getEndpoint().phoneNumber,
      disposition: attributes.disposition?.value || attributes.fordDisposition?.value || 'Not set',
      multiSelectDisposition: multiSelectDisposition,
      GPBR: attributes.GPBR?.value || '',
      brandCode: attributes.brandCode?.value || '',
      issue: attributes.lexIssue?.value || attributes.service?.value || '',
      startTimestamp: new Date().getTime()
    };
  }

  private formatEndedContactUpdate(lastContact: IRecentContact, contact: connect.Contact): void {
    const attributes = contact.getAttributes();

    lastContact.disposition = attributes.disposition?.value || attributes.fordDisposition?.value || 'Not set';
    lastContact.GPBR = attributes.GPBR?.value || '';
    lastContact.lexIssue = attributes.lexIssue?.value || '';
    lastContact.endTimestamp = new Date().getTime();

    return;
  }
}
