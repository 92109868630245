import React from 'react';
import {List, ListDescription, ListItem} from 'semantic-ui-react';
import {Logger, LoggingService} from '../../services/LoggingService';
import {IDispositionAttributeConfig} from '../../models/contactInfoDisplaySets';
import {ConfigurationService} from '../../services/ConfigurationService';

export const DispositionAttribute = (
  dispositionConfig: IDispositionAttributeConfig,
  dispositionValue: string,
  dispositionPaneVisible: boolean,
  setDispositionPaneVisible: React.Dispatch<React.SetStateAction<boolean>>,
  config: ConfigurationService,
  agentLang: string
) => {
  const logger: Logger = new LoggingService().getLogger('DispositionAttribute');

  const getLabel = () => {
    if (!dispositionConfig.label) {
      logger.error('No label in dispositionConfig, using default', {...dispositionConfig});
      return 'Disposition';
    } else {
      return dispositionConfig.label;
    }
  };

  const getDispositionValue = () => {
    if (dispositionValue) {
      if (dispositionConfig.multiSelect === true) {
        const dispositionArray = dispositionValue.split(',');
        for (let i = 0; i < dispositionArray.length; i++) {
          dispositionArray[i] = config.translate(dispositionArray[i], agentLang) || dispositionArray[i];
        }
        return dispositionArray.toString();
      } else {
        return config.translate(dispositionValue, agentLang) || dispositionValue;
      }
    } else {
      return config.translate('Not yet set', agentLang) || 'Not yet set';
    }
  };

  return (
    <ListItem key={dispositionConfig.attributeKey}>
      <List.Header>
        <strong>{config.translate(getLabel(), agentLang) || getLabel()}</strong>
      </List.Header>
      <ListDescription>
        {getDispositionValue()}{' '}
        {!dispositionPaneVisible && (
          <small>
            <a href="#" onClick={() => setDispositionPaneVisible(!dispositionPaneVisible)}>
              {config.translate('Update', agentLang) || 'Update'}
            </a>
          </small>
        )}
      </ListDescription>
    </ListItem>
  );
};
